import enums, { EnumValues, getEnumLabel } from "common/enums";
import ConfirmButton from "components/Common/ConfirmButton";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import ImagesModal from "components/Common/ImagesModal";
import MoneyOutput from "components/Common/MoneyOutput";
import PercentageOutput from "components/Common/PercentageOutput";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Spinner,
} from "reactstrap";
import { editOrderedProduct, exportPackagePdf, getProductsInStock } from "store/order/ordered-product-services";
import { getSimilarVariants, getVariantOrProductImages } from "store/product/services";
import {
  addInStockProductToOrder,
  addProductToOrder,
  deleteOrderProduct,
  getOrder,
  updateDiscountRates,
} from "../../store/order/services";
import OrderProductForm from "./OrderProductForm";
import orderUtils from "./order_utils";
import { downloadService } from "services/DownloadService";
import Privileges from "models/Privileges";
import { useActiveUser } from "hooks";

const initialProductState = { open: false, error: null, loading: false, product: undefined };
const initialUpdateDiscountRateState = {
  open: false,
  error: null,
  loading: false,
  discountRate: null,
  discountTotal: null,
};
const OrderProducts = ({ orderId, type, onChange }) => {
  const activeUser = useActiveUser();
  const [productState, setProductState] = useState(initialProductState);
  const [productCloneState, setProductCloneState] = useState({
    open: false,
    variants: [],
    sourceProduct: null,
    loading: false,
  });
  const [order, setOrder] = useState({});
  const [products, setProducts] = useState([]);
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const [loading, setLoading] = useState(false);
  const openProductAddPopup = () => {
    setProductState((prev) => ({ ...prev, product: undefined, open: true }));
  };
  const openProductClonePopup = async (product) => {
    const variants = await getSimilarVariants(product.sku);
    setProductCloneState({
      open: true,
      variants: variants,
      sourceProduct: product,
    });
  };
  const openProductUpdatePopup = async (product) => {
    // const p = await getOrderedProduct(product.id);
    const m = orderUtils.mapToProductFormModel({ ...product });
    setProductState((prev) => ({ ...prev, product: m, open: true }));
  };
  const handleCloneProduct = async (variants) => {
    setProductCloneState((prev) => ({ ...prev, loading: true }));
    const product = productCloneState.sourceProduct;
    let hasSuccess = false;
    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i];
      const productResource = {
        orderType: product.orderType,
        variantId: variant.id,
        quantity: product.quantity,
        notes: product.notes,
        discountRate: product.discountRate,
        netPrice: Number((+variant.finalPrice * (100 - (+product.discountRate || 0))) / 100).toFixed(2),
      };
      try {
        if (product.orderType == "NEW") {
          console.log("adding products NEW");
          await addProductToOrder(orderId, productResource);
        } else if (product.orderType == "STOCK") {
          const inStockProducts = await getProductsInStock(variant.sku);
          const inStockProduct = inStockProducts.find(
            (p) => p.branchId == product.branch.id && p.stockCount >= product.quantity
          );
          if (inStockProduct) {
            console.log("adding products from STOCK");
            await addInStockProductToOrder(orderId, inStockProduct.value, productResource);
          } else {
            productResource.orderType = "NEW";
            console.log("adding products NEW");
            toast.info("Product out of stock as requested quantity so added to new order");
            await addProductToOrder(orderId, productResource);
          }
        }
        hasSuccess = true;
      } catch (error) {
        console.error("product could not be added", error);
      }
    }
    setProductCloneState({
      open: false,
      variants: [],
      sourceProduct: null,
      loading: false,
    });
    if (hasSuccess) {
      await loadProducts(orderId);
      onChange && onChange();
    }
  };
  const handleAddOrUpdateProduct = async (product) => {
    const currProduct = productState.product;
    let update = false;
    if (product.id) {
      // console.log(product, currProduct);
      if (
        product.quantity != currProduct.quantity ||
        product.orderType != currProduct.orderType.value ||
        product.variantId != currProduct.variant.id
      ) {
        console.log("deleting products");
        await deleteOrderProduct(orderId, product.id);
      } else {
        update = true;
      }
    }
    if (update) {
      console.log("updating products");
      await editOrderedProduct(product.id, product);
    } else if (product.orderType == "NEW") {
      console.log("adding products NEW");
      await addProductToOrder(orderId, product);
    } else if (product.orderType == "STOCK") {
      console.log("adding products from STOCK");
      await addInStockProductToOrder(orderId, product.inStockProduct.value, product);
    }
    setProductState((prev) => ({ ...prev, product: undefined, open: false }));
    await loadProducts(orderId);
    onChange && onChange();
  };
  const handleCloseProduct = () => {
    setProductState((prev) => ({ ...prev, open: false }));
  };
  const handleCloseCloneProduct = () => {
    setProductCloneState((prev) => ({ ...prev, open: false }));
  };
  const loadProducts = async (orderId) => {
    setLoading(true);
    try {
      const order = await getOrder(orderId);
      setOrder(order);
      setProducts(order.orderedProducts);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteProduct = async ({ id }) => {
    await deleteOrderProduct(orderId, id);
    loadProducts(orderId);
    onChange && onChange();
  };
  useEffect(() => {
    loadProducts(orderId);
  }, [orderId]);

  const handleUpdateDiscountRates = async (discountRate, discountTotal) => {
    await updateDiscountRates(orderId, discountRate, discountTotal);
    toast.info("Discounts updated successfully");
    loadProducts(orderId);
  };

  const handleExportPackagePdf = async (orderedProduct) => {
    const data = await exportPackagePdf(orderedProduct.id);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        align: "center",
        headerAlign: "center",
        formatter: (productVariant) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: productVariant.productId,
                variantId: productVariant.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
        footer: "",
      },
      {
        text: "Name",
        dataField: "productVariant.productName",
        footer: "",
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
        footer: "",
      },
      {
        text: "Status",
        dataField: "productStatus",
        formatter: (cell, row) => (
          <>
            {getEnumLabel(enums.orderedProductStatus, cell)}
            {[EnumValues.ORDERED_PRODUCT_STATUS.DELIVERY_PLANNED, EnumValues.ORDERED_PRODUCT_STATUS.DELIVERED].includes(
              cell
            ) &&
              row.delivery != null &&
              row.delivery.plannedDate && (
                <div className="text-danger">
                  (<DateOutput date={row.delivery.plannedDate} />)
                </div>
              )}
          </>
        ),
        footer: "",
      },
      {
        text: "Type",
        dataField: "orderType",
        formatter: (cell) => getEnumLabel(enums.orderTypes, cell),
        footer: "",
      },
      {
        text: "Quantity",
        dataField: "quantity",
        footer: "",
      },
      {
        text: "Branch",
        dataField: "branch.name",
        footer: "",
      },
      {
        text: "Item Price",
        dataField: "orjPrice",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => (
          <>
            {row.basePrice && row.basePrice > cell && (
              <span style={{ textDecoration: "line-through", marginRight: 5 }}>
                <MoneyOutput value={row.basePrice} currency={row.priceCurrency} />
              </span>
            )}
            <MoneyOutput value={cell} currency={row.priceCurrency} />
          </>
        ),
        footer: "Totals",
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        text: "Item Total",
        dataField: "price",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency={row.priceCurrency} />,
        footer: (cell, row) => <MoneyOutput value={Number(order.price).toFixed(2)} />,
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        text: "Discount Rate",
        dataField: "discountRate",
        align: "right",
        headerAlign: "right",
        formatter: (cell) => <PercentageOutput value={cell} />,
        footer: (cell) => {
          return <PercentageOutput value={Number(order.discountRate).toFixed(2)} />;
        },
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        text: "Discount",
        dataField: "discountAmount",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency={row.priceCurrency} />,
        footer: (cell, row) => <MoneyOutput value={Number(order.discountAmount).toFixed(2)} currency="GBP" />,
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        text: "Discounted Total",
        dataField: "totalNetPrice",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} currency={row.priceCurrency} />,
        footer: (cell, row) => <MoneyOutput value={Number(order.netPrice).toFixed(2)} currency="GBP" />,
        footerStyle: {
          textAlign: "right",
        },
      },
      {
        dataField: "edit",
        text: "Edit",
        hidden: type == "view",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <div className="d-flex gap-2">
              <Button color="warning" size="sm" onClick={() => openProductUpdatePopup(row)}>
                <i className="fa fa-edit"></i>
              </Button>
              <Button color="info" size="sm" onClick={() => openProductClonePopup(row)} title="Clone product">
                <i className="fa fa-clone"></i>
              </Button>
            </div>
          );
        },
        footer: "",
      },
      {
        text: "Pck",
        dataField: "package",
        align: "center",
        headerAlign: "center",
        hidden: !activeUser.hasPrivilege(Privileges.PACKAGE.EXPORT_PACKAGE_PDF),
        formatter: (cell, row) => {
          return (
            <Button color="secondary" title="Export" onClick={() => handleExportPackagePdf(row)}>
              <i className="fas fa-download"></i>
            </Button>
          );
        },
      },
      {
        text: "Delete",
        dataField: "delete",
        hidden: type == "view",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteProduct(row)}
              title="Are you sure you want to delete the product?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
        footer: "",
      },
    ],
    [products]
  );

  const expandRow = useMemo(
    () => ({
      showExpandColumn: false,
      expandByColumnOnly: true,
      expanded: products.filter((i) => i.notes).map((item, i) => item.id),
      renderer: (row) => (
        <>
          <div className="w-100 row">
            <div className="col-2 col-xs-1 d-flex justify-content-end">
              <b>Product Note:</b>
            </div>
            <span className="col-10 col-xs-11 d-flex justify-content-start text-nowrap">{row.notes}</span>
          </div>
        </>
      ),
    }),
    [products]
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            {products.length > 0 && (
              <span>
                <UpdateDiscountRatesButton onSubmit={handleUpdateDiscountRates} />
                &nbsp;
              </span>
            )}
            <Button color="primary" size="sm" onClick={openProductAddPopup}>
              <i className="fa fa-plus"></i>Add Product
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && products.length > 0 && (
            <BootstrapTable
              keyField="id"
              responsive
              data={products}
              columns={productColumns}
              wrapperClasses="table-responsive"
              classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
              headerWrapperClasses={"thead-light"}
              expandRow={expandRow}
            />
          )}
        </Col>
      </Row>
      {productState.open && (
        <OrderProductModal
          onClose={handleCloseProduct}
          onSubmit={handleAddOrUpdateProduct}
          product={productState.product}
        />
      )}
      {productCloneState.open && (
        <OrderProductCloneModal
          onClose={handleCloseCloneProduct}
          onSubmit={handleCloneProduct}
          variants={productCloneState.variants}
          loading={productCloneState.loading}
        />
      )}
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

const OrderProductCloneModal = ({ variants, onClose, onSubmit, loading }) => {
  const [selectedVariants, setSelectedVariants] = useState([]);
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Clone Product</ModalHeader>
      <ModalBody>
        <div className="row mb-4">
          <Label htmlFor="orderType" className="col-sm-3 col-form-label">
            Products
          </Label>
          <Col sm={9}>
            <MultiselectWithService
              labelField="label"
              service={() =>
                Promise.resolve(
                  variants.map((v) => ({ ...v, label: `${v.productName}${v.name ? ` (${v.name})` : ""}` }))
                )
              }
              isMulti={true}
              autoFocus
              defaultMenuIsOpen
              value={selectedVariants.map((item) => item.value)}
              // isDisabled={product.id != null}
              setValue={(name, ids, values) => {
                console.log(values);
                setSelectedVariants(values);
              }}
            />
          </Col>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          disabled={selectedVariants.length == 0 || loading}
          onClick={() => onSubmit(selectedVariants.map((v) => v.option))}
        >
          {loading ? <Spinner color="white" size="sm" /> : "Clone"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const OrderProductModal = ({ product, onClose, onSubmit }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
      <ModalBody>
        <OrderProductForm initialProduct={product} onSubmit={onSubmit} />
      </ModalBody>
    </Modal>
  );
};
const UpdateDiscountRatesButton = ({ onSubmit }) => {
  const [updateDiscountRateState, setUpdateDiscountRateState] = useState(initialUpdateDiscountRateState);
  const updateDiscountRateBtn = document.getElementById("updatePopoverBtn");
  return (
    <>
      <Button
        color="primary"
        id="updatePopoverBtn"
        outline
        size="sm"
        type="button"
        onClick={() =>
          setUpdateDiscountRateState({
            ...initialUpdateDiscountRateState,
            open: true,
          })
        }
      >
        <i className="fa fa-edit"></i>Update Discounts
      </Button>
      {updateDiscountRateBtn && updateDiscountRateState.open && (
        <Popover
          isOpen
          // placement="bottom"
          target={updateDiscountRateBtn}
          trigger="legacy"
          toggle={() => setUpdateDiscountRateState({ ...initialUpdateDiscountRateState })}
        >
          <PopoverHeader>Update All Discounts</PopoverHeader>
          <PopoverBody>
            <div className="row mb-2">
              <Col sm={12}>
                <label>Discount Rate</label>
              </Col>
              <Col sm={12}>
                <NumberFormat
                  value={updateDiscountRateState.discountRate}
                  className="form-control"
                  placeholder="Discount rate.."
                  allowNegative={false}
                  onValueChange={({ value }) => {
                    setUpdateDiscountRateState((prev) => ({
                      ...prev,
                      discountRate: value,
                    }));
                  }}
                />
              </Col>
            </div>
            <div className="row mb-2">
              <Col sm={12}>
                <label>Discount Amount</label>
              </Col>
              <Col sm={12}>
                <NumberFormat
                  value={updateDiscountRateState.discountTotal}
                  className="form-control"
                  placeholder="Discount amount.."
                  allowNegative={false}
                  onValueChange={({ value }) => {
                    setUpdateDiscountRateState((prev) => ({
                      ...prev,
                      discountTotal: value,
                    }));
                  }}
                />
              </Col>
            </div>
            <p className="text-info">* You can enter one of the rate or amount</p>
            <Button
              color="primary"
              outline
              size="sm"
              type="button"
              disabled={
                (updateDiscountRateState.discountRate == null || updateDiscountRateState.discountRate == "") &&
                (updateDiscountRateState.discountTotal == null || updateDiscountRateState.discountTotal == "")
              }
              onClick={() => {
                onSubmit(updateDiscountRateState.discountRate, updateDiscountRateState.discountTotal);
                setUpdateDiscountRateState({
                  ...initialUpdateDiscountRateState,
                  open: false,
                });
              }}
            >
              <i className="fa fa-edit"></i>Update
            </Button>
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default OrderProducts;
