import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createMenu, deleteMenu, editMenu, getMenu } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

export const productCategoryFormValues = [
  { name: "code", label: "Short Code", size: 6, type: "text" },
  { name: "name", label: "Name", size: 12, type: "text" },
  { name: "description", label: "Description", size: 12, type: "textarea" },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];
const Menu = () => {
  const columns = [
    {
      dataField: "code",
      text: "Short Code",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Menu</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getMenu,
            deleteapi: deleteMenu,
            createapi: createMenu,
            updateapi: editMenu,
          }}
          tableColumns={columns}
          formValues={{
            values: productCategoryFormValues,
            validationSchema: {
              code: Yup.string().required("Code is required"),
              name: Yup.string().required("Name is required"),
              description: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "Menu",
            title: "Menu List",
          }}
          privilege={{
            create: Privileges.MENU.CREATE,
            update: Privileges.MENU.UPDATE,
            delete: Privileges.MENU.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Menu);
