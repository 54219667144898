import enums, { getEnumLabel } from "common/enums";
import CustomPaginate from "components/Common/CustomPaginate";
import CustomSpinner from "components/Common/CustomSpinner";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import { useEffect, useState } from "react";
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";
import { getTickets } from "store/ass/services";
import operationCenterUtils from "./operationCenter_utils";

function TicketsCard({ serviceDate, onSelect, selected }) {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [appliedSort] = useState({ sortField: "id", sortOrder: "desc" });
  const [appliedFilter, setAppliedFilter] = useState(operationCenterUtils.initialTicketFilterValues(serviceDate));
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging(null, 10);
  const [activeTicket, setActiveTicket] = useState();
  const handleSelect = (order) => {
    setActiveTicket(order);
    onSelect(order);
  };
  const handlePageChange = (type, details) => {
    const { page, sizePerPage } = details;
    loadTickets(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
  };
  const loadTickets = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getTickets(page, operationCenterUtils.mapToTicketSearch(filter), sort);
      setTotalSize(totalElements);
      setTickets(content);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filter = { serviceDate };
    setAppliedFilter(filter);
    loadTickets(pageOptions, filter, appliedSort);
  }, [serviceDate]);
  return (
    <>
      {loading && <CustomSpinner />}
      {!loading && tickets.length == 0 && <div>No ticket found</div>}
      {!loading && tickets.length > 0 && (
        <>
          <ListGroup flush>
            {tickets.map((item) => {
              const active = activeTicket?.id == item.id && selected;
              const ListGroupItemWrapper = active ? ListGroupItemText : ListGroupItemText;
              return (
                <div className="row p-1" key={item.id}>
                  <div className="col-12">
                    <ListGroupItem
                      action
                      tag="button"
                      style={{ backgroundColor: active ? "var(--bs-gray-400)" : "" }}
                      onClick={() => handleSelect(item)}
                    >
                      <ListGroupItemWrapper>
                        {item.customer.fullName}, <b>{getEnumLabel(enums.sshStatus, item.sshStatus)}</b>
                        {item.order?.orderId && (
                          <>
                            , Order Number: <b>{item.order?.orderId}</b>
                          </>
                        )}
                      </ListGroupItemWrapper>
                    </ListGroupItem>
                  </div>
                </div>
              );
            })}
          </ListGroup>
          <CustomPaginate
            containerClassName="pt-3"
            sizePerPage={pageOptions.sizePerPage}
            totalSize={pageOptions.totalSize}
            onPageChange={handlePageChange}
            page={pageOptions.page}
          />
        </>
      )}
    </>
  );
}

export default TicketsCard;
