import moment from "moment";

class SalesTargetUtils {
  initialValues = () => ({
    store: {},
    startDate: null,
    endDate: null,
  });
  mapToSaveModel = ({ store = {}, startDate, endDate }) => ({
    storeId: store?.value,
    startDate: startDate && moment(startDate).utcOffset(0, true).format(),
    endDate: endDate && moment(endDate).utcOffset(0, true).format(),
  });

  mapToFormDataModel = ({ store = {}, startDate = "", endDate = "" }) => ({
    store: {
      label: store?.name,
      value: store?.id,
    },
    startDate: startDate && moment(startDate, "YYYY-MM-DD HH:mm:ss").toDate(),
    endDate: endDate && moment(endDate, "YYYY-MM-DD HH:mm:ss").toDate(),
  });
  initialFilterValues = () => ({
    startDate: null,
    endDate: null,
    storeId: null,
  });

  mapToSalesTargetSearch = (
    filter = {
      storeId: null,
      startDateStart: null,
      startDateEnd: null,
      endDateStart: null,
      endDateEnd: null,
    }
  ) => {
    return {
      storeId: filter.storeId,
      startDateStart: filter.startDateStart == null ? null : moment(filter.startDateStart).format("YYYY-MM-DD"),
      startDateEnd: filter.startDateEnd == null ? null : moment(filter.startDateEnd).format("YYYY-MM-DD"),
      endDateStart: filter.endDateStart == null ? null : moment(filter.endDateStart).format("YYYY-MM-DD"),
      endDateEnd: filter.endDateEnd == null ? null : moment(filter.endDateEnd).format("YYYY-MM-DD"),
    };
  };
}
const salesTargetUtils = new SalesTargetUtils();
export default salesTargetUtils;
