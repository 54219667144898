import { atom } from "recoil";
import taskUtils from "./task_utils";

export const taskiesAppliedFilterState = atom({
  key: "Taskies.appliedFilter",
  default: taskUtils.initialFilterValues(),
});
export const taskiesAppliedSortState = atom({
  key: "Taskies.appliedSort",
  default: { sortField: "no", sortOrder: "desc" },
});
