import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getProductImages, getProductWithStock } from "store/product/services";
import productUtils from "./product_utils";

import { Link, useParams } from "react-router-dom";

import CustomSpinner from "components/Common/CustomSpinner";
import ImagesModal from "components/Common/ImagesModal";
import LongArrayOutput from "components/Common/LongArrayOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import ProductPackages from "./ProductPackages";
import ProductPriceHistories from "./ProductPriceHistories";
import VariantsView from "./VariantsView";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";
import { EnumValues } from "common/enums";

const ProductDetail = ({ history }) => {
  let { id: productId } = useParams();
  const activeUser = useActiveUser();
  const [productState, setProductState] = useState({
    error: null,
    loading: false,
  });
  const [showProductsImages, setShowProductsImages] = useState(false);
  const [showPriceHistories, setShowPriceHistories] = useState(false);

  const [product, setProduct] = useState(productUtils.initialValues(productId));

  useEffect(() => {
    if (productId) {
      loadProduct(productId);
    }
  }, [productId]);

  const loadProduct = async (productId) => {
    setProductState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const mdl = await getProductWithStock(productId);
      setProduct(mdl);
      setProductState((state) => ({
        error: null,
        loading: false,
      }));
    } catch (error) {
      setProductState((state) => ({
        error,
        loading: false,
      }));
    }
  };

  const hasAttribute = product.colors?.length > 0 || product.attributeValueOptions?.length > 0;

  return (
    <React.Fragment>
      <MetaTags>
        <title>Novia - Product Detail</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h4 className="text-primary">
          <Breadcrumb listClassName="p-0">
            <BreadcrumbItem>
              <Link to="/products">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <b>Product Detail</b>
            </BreadcrumbItem>
          </Breadcrumb>
        </h4>
        {productState.loading && <CustomSpinner />}
        {!productState.loading && productState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Product could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {!productState.loading && product && (
          <>
            <Row>
              <Col sm={12}>
                <Card className="shadow">
                  <CardBody>
                    <CardTitle tag="h5">Product Details</CardTitle>
                    <Row className="py-1 pt-2">
                      <h6 className="col-sm-2">Product Name</h6>
                      <Col sm={4}>{product.name}</Col>
                      <h6 className="col-sm-2">Product Type</h6>
                      <Col sm={4}>{product.type?.name || "-"}</Col>
                      <h6 className="col-sm-2">Status</h6>
                      <Col sm={4}>{product.status === "y" ? "Active" : "Passive"}</Col>
                      <h6 className="col-sm-2">Category</h6>
                      <Col sm={4}>{product.category?.nameWithPath || "-"}</Col>
                      <h6 className="col-sm-2">Description</h6>
                      <Col sm={4}>{product.description || "-"}</Col>
                      <h6 className="col-sm-2">Tax Group</h6>
                      <Col sm={4}>{product.taxGroup?.name || "-"}</Col>
                      <h6 className="col-sm-2">Manufacturer</h6>
                      <Col sm={4}>{product.manufacturer?.name || "-"}</Col>
                      <h6 className="col-sm-2">Product Code</h6>
                      <Col sm={4}>{product.manufacturerProductCode || "-"}</Col>
                      <h6 className="col-sm-2">Barcode</h6>
                      <Col sm={4}>{product.barcode || "-"}</Col>
                      <h6 className="col-sm-2">SKU</h6>
                      <Col sm={4}>{product.sku || "-"}</Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={hasAttribute ? 6 : 12}>
                <Card className="shadow">
                  <CardBody>
                    <CardTitle tag="h5">Cost and Price</CardTitle>
                    <Row className="py-1 pt-2">
                      {activeUser.hasPrivilege(Privileges.PRODUCT.VIEW_COST) && (
                        <React.Fragment>
                          <h6 className="col-sm-2">Cost</h6>
                          <Col sm={4}>
                            {product.cost != null && (
                              <MoneyOutput value={product.cost} currency={product.costCurrency} />
                            )}
                            {product.cost == null && "-"}
                          </Col>
                        </React.Fragment>
                      )}
                      <h6 className="col-sm-2">Price</h6>
                      <Col sm={4}>
                        {product.price != null && (
                          <MoneyOutput value={product.price} currency={product.priceCurrency} />
                        )}
                        {product.price == null && "-"}
                      </Col>
                      <h6 className="col-sm-2">Sell Price</h6>
                      <Col sm={4}>
                        {product.salesPrice != null && (
                          <MoneyOutput value={product.salesPrice} currency={product.salesPriceCurrency} />
                        )}
                        {product.salesPrice == null && "-"}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {hasAttribute && (
                <Col sm={6}>
                  <Card className="shadow">
                    <CardBody>
                      <CardTitle tag="h5">Product Attributes</CardTitle>
                      {product.kartelas?.length > 0 && (
                        <Row className="py-1 pt-2">
                          <h6 className="col-sm-2">Color Charts</h6>
                          <Col sm={10}>
                            <LongArrayOutput values={product.kartelas.map((a) => a.name)} title="Color Charts" />
                          </Col>
                        </Row>
                      )}
                      {product.colors?.length > 0 && (
                        <Row className="py-1 pt-2">
                          <h6 className="col-sm-2">Colors</h6>
                          <Col sm={10}>
                            <LongArrayOutput values={product.colors.map((a) => a.nameFirstMaterial)} title="Colors" />
                          </Col>
                        </Row>
                      )}
                      {product.attributeValueOptions?.length > 0 && (
                        <Row className="py-1 pt-2">
                          {product.attributeValueOptions?.map((attr) => (
                            <React.Fragment key={attr.attribute.id}>
                              <h6 className="col-sm-2">{attr.attribute.name}</h6>
                              <Col sm={10}>
                                <LongArrayOutput values={attr.options.map((a) => a.name)} title={attr.attribute.name} />
                              </Col>
                            </React.Fragment>
                          ))}
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>

            <Row>
              <Col sm={12}>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <ProductPackages productId={productId} type="view" />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Card className="shadow">
                  <CardTitle tag="h5" className="pt-4 px-4">
                    Product Variants
                  </CardTitle>
                  <CardBody>
                    <VariantsView product={product} />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <ActionLogsCard
                  title="Product History"
                  recordId={productId}
                  actionModels={[EnumValues.ACTION_MODEL.PRODUCT]}
                  parentActions={[EnumValues.PARENT_ACTION_TYPE.PRODUCT]}
                />
              </Col>
            </Row>
            <br />
            <br />

            <Row>
              <Col sm={12}>
                <Card className="shadow-lg nv-sticky-card">
                  <CardBody className="">
                    <Row>
                      <Col>
                        <Button color="primary" size="md" onClick={() => setShowProductsImages(true)}>
                          <i className="fa fa-image"></i> Images
                        </Button>
                        {activeUser.hasPrivilege(Privileges.PRODUCT.PRICE_HISTORY) && (
                          <>
                            &nbsp;&nbsp;
                            <Button color="warning" size="md" onClick={() => setShowPriceHistories(true)}>
                              <i className="fa fa-history"></i> Price History
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {showProductsImages && (
              <ImagesModal
                title="Product Images"
                editable={false}
                onClose={() => setShowProductsImages(false)}
                onList={() => getProductImages(productId)}
              />
            )}
            {showPriceHistories && (
              <PriceHistoryModal onClose={() => setShowPriceHistories(false)} productId={productId} />
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

function PriceHistoryModal({ productId, onClose }) {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Price History</ModalHeader>
      <ModalBody>
        <ProductPriceHistories productId={productId} />
      </ModalBody>
    </Modal>
  );
}

export default ProductDetail;
