import ActionMenu from "components/Common/ActionMenu";
import ConfirmButton from "components/Common/ConfirmButton";
import ImagesModal from "components/Common/ImagesModal";
import MoneyOutput from "components/Common/MoneyOutput";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Search } from "react-bootstrap-table2-toolkit";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { copyVariants, deleteProduct, getProductImages, getProducts, searchProducts } from "store/product/services";
import { appliedFilterState, appliedSortState } from "./atoms";
import ProductFilterForm from "./ProductFilterForm";
import productUtils from "./product_utils";
import { useRecoilState } from "recoil";

const Products = ({ history }) => {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [appliedFilter, setAppliedFilter] = useRecoilState(appliedFilterState);
  const [appliedSort, setAppliedSort] = useRecoilState(appliedSortState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Products");
  const [products, setProducts] = useState([]);
  const [productImageState, setProductImageState] = useState({
    open: false,
    productId: null,
  });
  const [copyVariantState, setCopyVariantState] = useState({
    open: false,
    productId: null,
  });
  const loadProducts = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await searchProducts(page, productUtils.mapToProductSearch(filter), sort);
      setTotalSize(totalElements);
      setProducts(content);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDrawingImage = async (img) => {
    window.open(img.awsUrl, "_blank");
  };

  useEffect(() => {
    // loadProducts(pageOptions, appliedFilter, appliedSort);
  }, []);

  const handleDelete = async (id) => {
    await deleteProduct(id);
    toast.info("Product deleted successfully");
    loadProducts(pageOptions, appliedFilter, appliedSort);
  };

  const handleCopyVariants = async (products) => {
    await copyVariants({
      referenceProductId: copyVariantState.productId,
      targetProductIds: products,
    });
    toast.info("Variants copied successfully");
    setCopyVariantState({ open: false });
  };

  const handlePageChange = (type, details) => {
    // console.log("handlePageChange", details, type);
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadProducts(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField, sortOrder };
      setAppliedSort(newSort);
      loadProducts(pageOptions, appliedFilter, newSort);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadProducts(newPage, appliedFilter, appliedSort);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const columns = [
    {
      text: "Images",
      loading,
      dataField: "images",
      formatter: (images, row) => {
        if (!row.id) {
          return null;
        }
        const openState = { open: true, productId: row.id };
        const defImage = productUtils.findDefaultImage(images);
        if (defImage) {
          return (
            <div onClick={() => setProductImageState(openState)} style={{ cursor: "pointer" }}>
              <img src={defImage.awsUrl} width="75" id={`product_image_${row.id}`} />
              <UncontrolledTooltip target={`product_image_${row.id}`}>
                <img src={defImage.awsUrl} width="300" />
              </UncontrolledTooltip>
            </div>
          );
        }
        return (
          <Button color="primary" size="sm" onClick={() => setProductImageState(openState)}>
            <i className="fa fa-image"></i>
          </Button>
        );
      },
    },
    {
      dataField: "sku",
      text: "SKU",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "priceSalesPrice",
      text: "Price/Sell Price",
      formatter: (cell, row) => (
        <>
          <MoneyOutput value={row.price} currency="GBP" />
          &nbsp;/&nbsp;
          <MoneyOutput value={row.salesPrice} currency="GBP" />
        </>
      ),
    },

    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: (cell) => {
        return cell === "y" ? (
          <i className="fas fa-check-circle text-success"></i>
        ) : (
          <i className="fas fa-times-circle text-danger"></i>
        );
      },
    },
    {
      dataField: "view",
      text: "View",
      hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.DETAIL),
      formatter: (cell, row) => {
        return (
          // <Link to={{pathname:`/product-detail/${row.id}`, state:`${row}`}} className="btn btn-secondary" >View</Link>
          <Button color="info" size="sm" onClick={() => history.push(`/product-detail/${row.id}`)}>
            <i className="fa fa-eye"></i>
          </Button>
        );
      },
    },
    {
      dataField: "edit",
      text: "Edit",
      hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.UPDATE),
      formatter: (cell, row) => {
        return (
          <Button color="warning" size="sm" onClick={() => history.push(`/product/${row.id}`)}>
            <i className="fa fa-edit"></i>
          </Button>
        );
      },
    },
    {
      dataField: "id",
      text: "Delete",
      hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.DELETE),
      formatter: (cell, row) => {
        return (
          <ConfirmButton
            color="danger"
            size="sm"
            onConfirm={() => handleDelete(cell)}
            title="Are you sure you want to delete the product?"
          >
            <i className="fa fa-trash"></i>
          </ConfirmButton>
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        const drawingImage = productUtils.findImageByDocumentType(row.images, "PRODUCT_DRAWING");
        return (
          <React.Fragment>
            {(activeUser.hasPrivilege(Privileges.PRODUCT.COPY_VARIANT) || drawingImage) && (
              <ActionMenu>
                <DropdownMenu>
                  {activeUser.hasPrivilege(Privileges.PRODUCT.COPY_VARIANT) && (
                    <DropdownItem onClick={() => setCopyVariantState({ open: true, productId: row.id })}>
                      Copy Variants
                    </DropdownItem>
                  )}
                  {drawingImage && (
                    <DropdownItem onClick={() => handleOpenDrawingImage(drawingImage)}>Drawing Image</DropdownItem>
                  )}
                </DropdownMenu>
              </ActionMenu>
            )}
          </React.Fragment>
        );
      },
    },
  ];
  const { SearchBar } = Search;
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Products</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <ProductFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                      <h4>Products</h4>
                      <div style={{ float: "right" }}>
                        {activeUser.hasPrivilege(Privileges.PRODUCT.CREATE) && (
                          <>
                            <Button color="primary" size="sm" onClick={() => history.push(`/new-product`)}>
                              <i className="fa fa-plus"></i> New Product
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
                        keyField="id"
                        responsive
                        bordered={true}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={products}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {productImageState.open && (
          <ImagesModal
            title="Product Images"
            onClose={() => setProductImageState({ open: false })}
            onList={() => getProductImages(productImageState.productId)}
            editable={false}
          />
        )}
        {copyVariantState.open && (
          <CopyVariantModal
            onClose={() => setCopyVariantState({ open: false })}
            onSubmit={handleCopyVariants}
            sourceProductId={copyVariantState.productId}
          />
        )}
      </div>
    </React.Fragment>
  );
};

Products.propTypes = {
  history: PropTypes.object,
};

function CopyVariantModal({ onClose, onSubmit, sourceProductId }) {
  const [products, setProducts] = useState([]);
  const handleSetProducts = (name, value) => {
    setProducts(value);
  };
  const handleSubmit = () => {
    onSubmit(products);
  };
  return (
    <>
      <Modal isOpen centered={true} size="lg">
        <ModalHeader toggle={onClose}>Select Target Products</ModalHeader>
        <ModalBody>
          <div className="row mb-4">
            <Label htmlFor="product" className="col-sm-3 col-form-label">
              Copy Variants To
            </Label>
            <Col sm={9}>
              <MultiselectWithService
                service={() => getProducts().then((products) => products.filter((p) => p.id != sourceProductId))}
                name="products"
                value={products}
                isMulti
                setValue={handleSetProducts}
              />
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit} disabled={products.length == 0}>
            Copy
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default withRouter(Products);
