// import NumberFormat from 'react-number-format';

import NumberFormat from "react-number-format";

function NumberOutput({ value = 0, minScale = 2 }) {
  const numVal = Number(value);
  let decimalScale = minScale;
  if (!Number.isNaN(numVal)) {
    const s = `${numVal}`.split(".");
    if (s.length > 1) {
      decimalScale = s[1].length;
    }
  }
  if (decimalScale < minScale) {
    decimalScale = minScale;
  }
  return (
    <NumberFormat
      value={value != null ? value : ""}
      displayType="text"
      thousandSeparator={true}
      fixedDecimalScale
      decimalScale={decimalScale}
    />
  );
}

export default NumberOutput;
