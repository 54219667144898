import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Row, Card, CardBody, Input, Label, Col, CardTitle, Button, Spinner } from "reactstrap";

import { getPaidTypes, getPaymentGateways, getPaymentTypes, getUserListContent } from "store/definitions/services";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo } from "helpers/utils";
import { createPayment, editPayment, getPayment } from "store/payment/services";
import paymentUtils from "./payment_utils";
import PaymentNotes from "./PaymentNotes";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";
import { EnumValues } from "common/enums";

const initialLoadState = { loading: false, error: null, loaded: false };

const Payment = ({ paymentId = null, onSave, onUpdate, orderId }) => {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [payment, setPayment] = useState(paymentUtils.initialValues(orderId));

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setPayment((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setPayment((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setPayment((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const loadPayment = async (paymentId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getPayment(paymentId);
      const prd = paymentUtils.mapToFormDataModel(mdl);
      setPayment(prd);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  const validatePayment = () => {
    console.log(payment);
    if (!payment.paidType?.value) {
      toast.error("Please enter paid with");
      return false;
    }
    if (payment.paidType?.value == "CARD" && !payment.gateway) {
      toast.error("Please enter gateway");
      return false;
    }
    if (!payment.amount) {
      toast.error("Please enter amount");
      return false;
    }
    if (+payment.amount <= 0) {
      toast.error("Amount must be bigger than 0");
      return false;
    }
    if (!payment.authBy?.value) {
      toast.error("Please enter authorized by");
      return false;
    }
    if (!payment.paymentType?.value) {
      toast.error("Please enter payment type");
      return false;
    }
    return true;
  };

  //handle payment
  const handleSavePayment = async () => {
    if (!validatePayment()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = paymentUtils.mapToSaveModel(payment);
      const mdl = await createPayment(saveModel);
      showInfo("Payment created successfully");
      onSave && onSave(mdl);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdatePayment = async () => {
    if (!validatePayment()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = paymentUtils.mapToSaveModel(payment);
      const mdl = await editPayment(paymentId, saveModel);
      setPayment(paymentUtils.mapToFormDataModel(mdl));
      showInfo("Payment updated successfully");
      onUpdate && onUpdate(mdl);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (paymentId) {
      loadPayment(paymentId);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <Row>
      <Col>
        {loadState.loading && <Spinner size="md">Loading...</Spinner>}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Payment could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="date" className="col-sm-3 col-form-label">
                      Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd HH:mm"
                        isClearable={false}
                        selected={payment.date}
                        showTimeInput
                        placeholderText="Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("date", date);
                        }}
                        disabled
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="paidType" className="col-sm-3 col-form-label">
                      Paid With
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getPaidTypes}
                        name="paidType"
                        value={payment.paidType.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="orderStatus" className="col-sm-3 col-form-label">
                      Gateway
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        isClearable
                        service={getPaymentGateways}
                        name="gateway"
                        value={payment.gateway}
                        isMulti={false}
                        id="gateway"
                        setValue={handleSetValue}
                        // validations={["required"]}
                        validationeventname="setValue"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="amount" className="col-sm-3 col-form-label">
                      Amount
                    </Label>
                    <Col sm={9}>
                      <NumberFormat
                        value={payment.amount || ""}
                        className="form-control"
                        placeholder="only numbers.."
                        allowNegative={false}
                        name="amount"
                        onValueChange={({ value }) => {
                          handleSetValue("amount", value);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="authBy" className="col-sm-3 col-form-label">
                      Auth By
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getUserListContent}
                        name="authBy"
                        value={payment.authBy.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        labelField="fullName"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="authCode" className="col-sm-3 col-form-label">
                      Auth Code
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        value={payment.authCode}
                        name="authCode"
                        placeholder="Auth Code.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="paymentType" className="col-sm-3 col-form-label">
                      Payment Type
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getPaymentTypes}
                        name="paymentType"
                        value={payment.paymentType.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <Row>
                    {paymentId && (
                      <Col>
                        <Button color="success" size="md" onClick={handleUpdatePayment} disabled={saving}>
                          <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                        </Button>
                      </Col>
                    )}
                    {!paymentId && (
                      <Col>
                        <Button color="success" size="md" onClick={handleSavePayment} disabled={saving}>
                          <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              </CardBody>
            </Card>

            {paymentId && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <PaymentNotes paymentId={paymentId} />
                  </CardBody>
                </Card>
                <ActionLogsCard
                  title="Payment History"
                  recordId={paymentId}
                  actionModels={[EnumValues.ACTION_MODEL.PAYMENT]}
                  parentActions={[EnumValues.PARENT_ACTION_TYPE.PAYMENT]}
                />
              </>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default Payment;
