import CustomSpinner from "components/Common/CustomSpinner";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CustomerCardView from "./CustomerCardView";

const { getCustomer } = require("store/customer/services");

const CustomerCard = ({ id, reloadKey }) => {
  const [customerState, setCustomerState] = useState({
    error: null,
    loading: false,
    customer: {},
  });
  const { customer } = customerState;
  const loadCustomer = async () => {
    setCustomerState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const customer = await getCustomer(id);
      setCustomerState((state) => ({
        ...state,
        customer,
        loading: false,
      }));
    } catch (error) {
      setCustomerState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadCustomer();
  }, [id, reloadKey]);

  return (
    <Row>
      <Col md={12}>
        <Card className="shadow-lg">
          <CardBody>
            {customerState.loading && <CustomSpinner />}
            {!customerState.loading && <CustomerCardView customer={customer} />}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomerCard;
