import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { Link, withRouter } from "react-router-dom";

import CustomSpinner from "components/Common/CustomSpinner";
import ImagesModal from "components/Common/ImagesModal";
import CreatableSelectBoxWithAction from "components/Selectbox/CreatableSelectBoxWithAction";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo } from "helpers/utils";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import { currencyFormValues } from "pages/Definitions/Currency/Currencies";
import { manufacturerFormValues } from "pages/Definitions/Manufacturers.js/Manufacturers";
import { productCategoryFormValues } from "pages/Definitions/ProductDefinitions/ProductCategories";
import { productTypeFormValues } from "pages/Definitions/ProductDefinitions/ProductType";
import { taxGroupsFormValues } from "pages/Definitions/TaxGroup/TaxGroup";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getCurrencyAction,
  getManufacturersAction,
  getProductCategoriesAction,
  getProductTypesAction,
  getTaxGroupAction,
} from "store/definitions/actions";
import {
  createCurrency,
  createManufacturers,
  createProductCategory,
  createProductTypes,
  createTaxGroups,
  getColorCharts,
  getColors,
  getColorsByColorChart,
} from "store/definitions/services";
import {
  addProductImage,
  createProduct,
  deleteProductImage,
  deleteProductVariant,
  editProduct,
  getProduct,
  getProductImages,
} from "store/product/services";
import Attributes, { AddAttributePopup } from "./Attributes";
import CreateVariantModal from "./CreateVariantModal";
import ProductPackages from "./ProductPackages";
import Variants from "./Variants";
import productUtils from "./product_utils";
import variantUtils from "./variant_utils";

const initialLoadState = { loading: false, error: null };

const Product = ({ id, history }) => {
  const dispatch = useDispatch();
  const activeUser = useActiveUser();

  const [productId, setProductId] = useState(id);
  const [product, setProduct] = useState(productUtils.initialValues(productId));
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [showProductsImages, setShowProductsImages] = useState(false);
  const [addAttrPopup, setAddAttrPopup] = useState(false);
  const [createVariantPopup, setCreateVariantPopup] = useState(false);
  const [loadingColorChange, setLoadingColorChange] = useState(false);

  useEffect(() => {
    if (productId) {
      loadProduct(productId);
    }
  }, []);

  const handleColorChartChange = async (name, value, colorCharts) => {
    setLoadingColorChange(true);
    try {
      const removedColorCharts = product.kartelas.filter((c) => colorCharts.find((k) => k.value == c.value) == null);
      const addedColorCharts = colorCharts.filter((c) => product.kartelas.find((k) => k.value == c.value) == null);
      // console.log("colorCharts", colorCharts);
      // console.log("product.kartelas", product.kartelas);
      // console.log("addedColorCharts", addedColorCharts);
      // console.log("removedColorCharts", removedColorCharts);
      let productColors = product.colors;
      if (removedColorCharts.length > 0) {
        let toBeRemovedColors = await getColorsByColorChart(removedColorCharts.map((item) => item.value));
        if (colorCharts.length > 0) {
          const colors = await getColorsByColorChart(colorCharts.map((item) => item.value));
          toBeRemovedColors = toBeRemovedColors.filter((r) => colors.find((c) => c.id == r.id) == null);
        }
        productColors = productColors.filter((item) => toBeRemovedColors.find((c) => c.id == item.value) == null);
      }
      if (addedColorCharts.length > 0) {
        const toBeAddedColors = await getColorsByColorChart(addedColorCharts.map((item) => item.value));
        productColors = [
          ...productColors,
          ...toBeAddedColors
            .map((c) => ({ value: c.id, label: c.nameFirstMaterial }))
            .filter((item) => productColors.find((c) => c.id == item.value) == null),
        ];
      }
      setProduct((prevValues) => ({
        ...prevValues,
        colors: productColors,
        [name]: colorCharts,
      }));
    } finally {
      setLoadingColorChange(false);
    }
  };

  const handleCreateVariant = (colors, materials, attributes) => {
    const newVariants = variantUtils.createVariants(colors, materials, attributes);
    setProduct((prevValues) => {
      const { variants, newVariantCount } = variantUtils.concatVariants(prevValues.variants, newVariants);
      if (newVariantCount > 0) {
        toast.info(`${newVariantCount} new variants added`);
      } else {
        toast.warn(`No new variant added`);
      }
      return {
        ...prevValues,
        variants,
      };
    });
    setCreateVariantPopup(false);
  };

  //handle images
  const handleAddImage = ({ file, isDefault, documentType }) => {
    const imageData = new FormData();
    imageData.append("imageFile", file, file.path);
    imageData.append("isDefault", isDefault);
    imageData.append("documentType", documentType ?? "");
    return addProductImage(productId, imageData);
  };

  //handle product
  const handleSaveProduct = async () => {
    setSaving(true);
    try {
      const saveModel = productUtils.mapToSaveModel(product);
      const mdl = await createProduct(saveModel);
      setProduct(productUtils.mapToFormDataModel(mdl));
      setProductId(mdl.id);
      showInfo("Product saved successfully");
      history.push(`/product/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateProduct = async () => {
    setSaving(true);
    try {
      const saveModel = productUtils.mapToSaveModel(product);
      const mdl = await editProduct(productId, saveModel);
      setProduct(productUtils.mapToFormDataModel(mdl));
      showInfo("Product updated successfully");
    } finally {
      setSaving(false);
    }
  };

  const loadProduct = async (productId) => {
    setLoadState({ loading: true, error: null });
    try {
      const mdl = await getProduct(productId);
      const prd = productUtils.mapToFormDataModel(mdl);
      setProduct(prd);
      setLoadState(initialLoadState);
    } catch (error) {
      setLoadState({ loading: false, error });
    }
  };

  const cloneProduct = async (productId) => {
    const mdl = await getProduct(productId);
    const prd = productUtils.cloneToFormDataModel(mdl);
    setProduct(prd);
    setProductId(null);
  };

  //handle variants
  const handleVariantValueChange = useCallback((index, name, value) => {
    setProduct((prevValues) => {
      const newVariants = [...prevValues.variants];
      const variant = { ...newVariants[index] };
      newVariants[index] = variant;
      variant[name] = value;
      return {
        ...prevValues,
        variants: newVariants,
      };
    });
  }, []);
  //handle variants
  const handleVariantValueBatchChange = useCallback((indexList, name, value) => {
    setProduct((prevValues) => {
      const newVariants = [...prevValues.variants];
      indexList.forEach((index) => {
        const variant = { ...newVariants[index] };
        newVariants[index] = variant;
        variant[name] = value;
      });
      return {
        ...prevValues,
        variants: newVariants,
      };
    });
  }, []);

  //handle attributes
  const handleAttributeAdd = useCallback((attr) => {
    setProduct((prevValues) => {
      if (prevValues.attributes.filter((item) => item.attribute.inputType == "SELECT").length >= 8) {
        toast.error("You can add at most 8 attributes that have multi options!");
        return prevValues;
      }
      const formAttr = prevValues.attributes.find((item) => item.attribute.id == attr.id);
      if (!formAttr) {
        prevValues.attributes.push({ attribute: attr, value: "", values: [] });
        return {
          ...prevValues,
          attributes: [...prevValues.attributes],
        };
      } else {
        toast.error("Selected attribute already exists!");
        return prevValues;
      }
    });
  }, []);

  const handleAttributeDelete = useCallback((index) => {
    setProduct((prevValues) => {
      prevValues.attributes.splice(index, 1);
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);

  const handleDeleteVariant = async (variant) => {
    if (variant.id) {
      await deleteProductVariant(variant.id);
      loadProduct(id);
    } else {
      setProduct((prev) => {
        prev.variants.splice(
          prev.variants.findIndex((v) => v === variant),
          1
        );
        return {
          ...prev,
        };
      });
    }
    toast.info("Product variant deleted successfully");
  };

  const handleAttributeValueChange = useCallback((id, value) => {
    setProduct((prevValues) => {
      const attr = prevValues.attributes.find((item) => item.attribute.id == id);
      attr.value = value;
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);

  const handleAttributeValuesChange = useCallback((id, values, objValues) => {
    setProduct((prevValues) => {
      const attr = prevValues.attributes.find((item) => item.attribute.id == id);
      attr.values = objValues;
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);

  const [formServiceState, setFormServiceState] = useState({
    loading: false,
    errors: {},
    success: null,
  });

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleNumberChange = useCallback(({ name, value }) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleChangeFormData = useCallback((name, value) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValue = useCallback((name, value, valueObject) => {
    setProduct((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Novia - Product</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h4 className="text-primary">
          <Breadcrumb listClassName="p-0">
            <BreadcrumbItem>
              <Link to="/products">Products</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {!productId && <b>New Product</b>}
              {productId && <b>Edit Product</b>}
            </BreadcrumbItem>
          </Breadcrumb>
        </h4>
        {loadState.loading && <CustomSpinner />}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Product could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {!loadState.loading && !loadState.error && (
          <>
            <Row>
              <Col md={12}>
                <Card className="shadow-lg">
                  <CardBody className="pt-0">
                    <div className="px-2 py-4">
                      <div className="row mb-4">
                        <Label className="col-sm-3 col-form-label" htmlFor="productStatus">
                          Status
                        </Label>
                        <Col sm={9} className="form-check form-switch form-switch-md mb-9" style={{ paddingLeft: 50 }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="productStatus"
                            onChange={(e) => handleChangeFormData("status", e.target.checked ? "y" : "n")}
                            defaultChecked={product.status == "y"}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label htmlFor="productformName" className="col-sm-3 col-form-label">
                          Product Name
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="text"
                            value={product.name || ""}
                            name="name"
                            id="productformName"
                            placeholder="product name.."
                            invalid={formServiceState.errors["name"] ? true : false}
                            className="form-control"
                            onChange={handleChange}
                          />
                          {formServiceState.errors["name"] ? (
                            <FormFeedback type="invalid">{validation.errors["name"]}</FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label className="col-sm-3 col-form-label" htmlFor="productType">
                          Product Type
                        </Label>
                        <div className="col-sm-9 col-form-label">
                          <CreatableSelectBoxWithAction
                            action={() => dispatch(getProductTypesAction({ page: 0 }))}
                            reduxStateName="productTypes"
                            name="productTypeId"
                            value={product.productTypeId}
                            handleChange={handleChange}
                            newItemDetails={{
                              formValues: {
                                values: productTypeFormValues,
                                itemName: "Product Type",
                              },
                              createapi: createProductTypes,
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <Label className="col-sm-3 col-form-label" htmlFor="productCategory">
                          Categories
                        </Label>
                        <div className="col-sm-9 col-form-label">
                          <CreatableSelectBoxWithAction
                            optionLabelField="nameWithPath"
                            action={() => dispatch(getProductCategoriesAction({ page: 0 }))}
                            reduxStateName="productCategories"
                            name="productCategoryId"
                            value={product.productCategoryId}
                            handleChange={handleChange}
                            newItemDetails={{
                              formValues: {
                                values: productCategoryFormValues,
                                itemName: "Product Category",
                              },
                              createapi: createProductCategory,
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <Label htmlFor="productformdescription" className="col-sm-3 col-form-label">
                          Product Description
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="textarea"
                            value={product.description || ""}
                            name="description"
                            id="productformdescription"
                            placeholder="description.."
                            invalid={formServiceState.errors["description"] ? true : false}
                            className="form-control"
                            onChange={handleChange}
                          />
                          {formServiceState.errors["description"] ? (
                            <FormFeedback type="invalid">{validation.errors["description"]}</FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card className="shadow-lg">
                  <CardBody className="">
                    <h4 className="text-secondary">
                      <b>Product</b>
                    </h4>
                    <div className="p-2">
                      <div className="row mb-4">
                        <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                          Tax Group
                        </Label>
                        <div className="col-sm-9 col-form-label">
                          <CreatableSelectBoxWithAction
                            action={() => dispatch(getTaxGroupAction({ page: 0 }))}
                            reduxStateName="taxGroup"
                            name="taxGroupId"
                            value={product.taxGroupId}
                            handleChange={handleChange}
                            newItemDetails={{
                              formValues: {
                                values: taxGroupsFormValues,
                                itemName: "Tax Group",
                              },
                              createapi: createTaxGroups,
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                          Manufacturer
                        </Label>
                        <div className="col-sm-9 col-form-label">
                          <CreatableSelectBoxWithAction
                            action={() => dispatch(getManufacturersAction({ page: 0 }))}
                            reduxStateName="manufacturers"
                            name="manufacturerId"
                            value={product.manufacturerId}
                            handleChange={handleChange}
                            newItemDetails={{
                              formValues: {
                                values: manufacturerFormValues,
                                itemName: "Manufacturer",
                              },
                              createapi: createManufacturers,
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-4 align-items-center">
                        <Label htmlFor="productformmanufacturerProductCode" className="col-sm-3 col-form-label">
                          Manufacturer Product Code
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="text"
                            value={product.manufacturerProductCode || ""}
                            name="manufacturerProductCode"
                            id="productformmanufacturerProductCode"
                            placeholder="product code.."
                            invalid={formServiceState.errors["manufacturerProductCode"] ? true : false}
                            className="form-control"
                            onChange={handleChange}
                          />
                          {formServiceState.errors["manufacturerProductCode"] ? (
                            <FormFeedback type="invalid">{validation.errors["manufacturerProductCode"]}</FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label htmlFor="productformbarcode" className="col-sm-3 col-form-label">
                          Barcode
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="text"
                            value={product.barcode || ""}
                            name="barcode"
                            id="productformbarcode"
                            placeholder="barcode.."
                            invalid={formServiceState.errors["barcode"] ? true : false}
                            className="form-control"
                            onChange={handleChange}
                          />
                          {formServiceState.errors["barcode"] ? (
                            <FormFeedback type="invalid">{validation.errors["barcode"]}</FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                      <div className="row">
                        <Label htmlFor="productformsku" className="col-sm-3 col-form-label">
                          SKU
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="text"
                            value={product.sku || ""}
                            name="sku"
                            id="productformsku"
                            placeholder="sku.."
                            invalid={formServiceState.errors["sku"] ? true : false}
                            className="form-control"
                            onChange={(e) => {
                              e.target.value = e.target.value.toUpperCase();
                              handleChange(e);
                            }}
                          />
                          {formServiceState.errors["sku"] ? (
                            <FormFeedback type="invalid">{validation.errors["sku"]}</FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card className="shadow-lg">
                  <CardBody className="">
                    <h4 className="text-secondary">
                      <b>Cost and Price</b>
                    </h4>
                    <div className="p-2">
                      {activeUser.hasPrivilege(Privileges.PRODUCT.EDIT_COST) && (
                        <div className="row mb-4">
                          <Label className="col-sm-3 col-form-label" htmlFor="productformcost">
                            Cost
                          </Label>
                          <Col sm={5}>
                            <NumberFormat
                              value={product.cost}
                              className="form-control"
                              placeholder="only numbers.."
                              allowNegative={false}
                              name="cost"
                              onValueChange={({ value }) => handleNumberChange({ value, name: "cost" })}
                              decimalScale={2}
                            />
                          </Col>
                          <Col sm={4}>
                            <CreatableSelectBoxWithAction
                              action={() => dispatch(getCurrencyAction({ page: 0 }))}
                              reduxStateName="currencies"
                              name="costCurrency"
                              value={product.costCurrency}
                              handleChange={handleChange}
                              optionValueField="code"
                              newItemDetails={{
                                formValues: {
                                  values: currencyFormValues,
                                  itemName: "Currency",
                                },
                                createapi: createCurrency,
                              }}
                            />
                          </Col>
                          {formServiceState.errors["cost"] ? (
                            <Col sm={12}>
                              <FormFeedback type="invalid">{validation.errors["cost"]}</FormFeedback>
                            </Col>
                          ) : null}
                        </div>
                      )}
                      <div className="row mb-4">
                        <Label className="col-sm-3 col-form-label" htmlFor="productformprice">
                          Price
                        </Label>
                        <Col sm={5}>
                          <NumberFormat
                            value={product.price}
                            id="productformprice"
                            className="form-control"
                            placeholder="only numbers.."
                            allowNegative={false}
                            name="price"
                            onValueChange={({ value }) => handleNumberChange({ value, name: "price" })}
                            decimalScale={2}
                          />
                        </Col>
                        <Col sm={4}>
                          <CreatableSelectBoxWithAction
                            action={() => dispatch(getCurrencyAction({ page: 0 }))}
                            reduxStateName="currencies"
                            name="priceCurrency"
                            value={product.priceCurrency}
                            handleChange={handleChange}
                            optionValueField="code"
                            newItemDetails={{
                              formValues: {
                                values: currencyFormValues,
                                itemName: "Currency",
                              },
                              createapi: createCurrency,
                            }}
                          />
                        </Col>
                        {formServiceState.errors["price"] ? (
                          <Col sm={12}>
                            <FormFeedback type="invalid">{validation.errors["price"]}</FormFeedback>
                          </Col>
                        ) : null}
                      </div>
                      <div className="row mb-4">
                        <Label className="col-sm-3 col-form-label" htmlFor="productformsellprice">
                          Sell Price
                        </Label>
                        <Col sm={5}>
                          <NumberFormat
                            value={product.salesPrice}
                            id="productformsellprice"
                            className="form-control"
                            placeholder="only numbers.."
                            allowNegative={false}
                            name="salesPrice"
                            onValueChange={({ value }) => handleNumberChange({ value, name: "salesPrice" })}
                            decimalScale={2}
                          />
                        </Col>
                        <Col sm={4}>
                          <CreatableSelectBoxWithAction
                            action={() => dispatch(getCurrencyAction({ page: 0 }))}
                            reduxStateName="currencies"
                            name="salesPriceCurrency"
                            value={product.salesPriceCurrency}
                            handleChange={handleChange}
                            optionValueField="code"
                            newItemDetails={{
                              formValues: {
                                values: currencyFormValues,
                                itemName: "Currency",
                              },
                              createapi: createCurrency,
                            }}
                          />
                        </Col>
                        {formServiceState.errors["salesPrice"] ? (
                          <Col sm={12}>
                            <FormFeedback type="invalid">{validation.errors["salesPrice"]}</FormFeedback>
                          </Col>
                        ) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card className="shadow-lg">
                  <CardBody className="">
                    <h4 className="text-secondary">
                      <b>Product Attributes</b>
                      <span style={{ float: "right" }}>
                        <Button color="primary" size="sm" onClick={() => setAddAttrPopup(true)}>
                          <i className="fa fa-plus"></i>Add Attribute
                        </Button>
                      </span>
                    </h4>
                    <div className="p-2">
                      <div className="row mb-2">
                        <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                          Color Charts
                        </Label>
                        <div className="col-9">
                          <MultiselectWithService
                            service={getColorCharts}
                            name="kartelas"
                            value={product.kartelas?.map((item) => item.value)}
                            isMulti={true}
                            setValue={handleColorChartChange}
                            isClearable
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="row mb-2">
                        <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                          Color
                        </Label>
                        <div className="col-9">
                          <MultiselectWithService
                            service={getColors}
                            name="colors"
                            value={product.colors.map((item) => item.value)}
                            isMulti={true}
                            setValue={handleSetValue}
                            labelField="nameFirstMaterial"
                            isClearable
                            isLoading={loadingColorChange}
                            isDisabled={loadingColorChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <Attributes
                        items={product.attributes}
                        setValue={handleAttributeValueChange}
                        setValues={handleAttributeValuesChange}
                        deleteItem={handleAttributeDelete}
                      />
                    </div>
                  </CardBody>
                </Card>

                {id && (
                  <Card className="shadow-lg">
                    <CardBody className="">
                      <ProductPackages productId={id} />
                    </CardBody>
                  </Card>
                )}

                <Card className="shadow-lg">
                  <CardBody className="">
                    <h4 className="text-secondary">
                      <span style={{ float: "right" }}>
                        <Button color="primary" size="sm" onClick={() => setCreateVariantPopup(true)}>
                          <i className="fa fa-plus"></i>Create Variant
                        </Button>
                      </span>
                    </h4>
                    <Variants
                      items={product.variants}
                      setValue={handleVariantValueChange}
                      setValues={handleVariantValueBatchChange}
                      productId={productId}
                      onDelete={handleDeleteVariant}
                    />
                  </CardBody>
                </Card>
                <Card className="shadow-lg nv-sticky-card">
                  <CardBody className="">
                    <Row>
                      {productId && (
                        <Col>
                          <Button color="success" size="md" onClick={handleUpdateProduct} disabled={saving}>
                            <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                          </Button>
                          &nbsp;&nbsp;
                          <Button color="primary" size="md" onClick={() => setShowProductsImages(true)}>
                            <i className="fa fa-image"></i> Images
                          </Button>
                          &nbsp;&nbsp;
                          <Button color="primary" size="md" onClick={() => cloneProduct(productId)}>
                            <i className="fa fa-copy"></i> Copy Product
                          </Button>
                        </Col>
                      )}
                      {!productId && (
                        <Col>
                          <Button color="success" size="md" onClick={handleSaveProduct} disabled={saving}>
                            <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {addAttrPopup && (
              <AddAttributePopup
                onAdd={handleAttributeAdd}
                onClose={() => setAddAttrPopup(false)}
                productTypeId={product.productTypeId}
                attributes={product.attributes}
              />
            )}
            {showProductsImages && (
              <ImagesModal
                title="Product Images"
                onClose={() => setShowProductsImages(false)}
                onList={() => getProductImages(productId)}
                onDelete={(imageId) => deleteProductImage(productId, imageId)}
                onUpload={handleAddImage}
              />
            )}
            {createVariantPopup && (
              <CreateVariantModal
                product={product}
                onClose={() => setCreateVariantPopup(false)}
                onCreate={handleCreateVariant}
              />
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

Product.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Product);
