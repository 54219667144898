import { Col, Row } from "reactstrap";

const CustomerCardView = ({ customer }) => {
  return (
    <>
      <Row className="py-1">
        {customer?.customerType == "COMPANY" && <h6 className="col-sm-2">Company Name</h6>}
        {customer?.customerType != "COMPANY" && <h6 className="col-sm-2">Customer Name</h6>}
        <Col sm={4}>
          {customer?.customerType == "PERSON" && <>{customer?.title}</>} {customer.fullName}
        </Col>
        <h6 className="col-sm-2">Email</h6>
        <Col sm={4}>{customer.email}</Col>
      </Row>
      <Row className="py-1">
        <h6 className="col-sm-2">Mobile Phone</h6>
        <Col sm={4}>{customer.mobilePhone}</Col>
        <h6 className="col-sm-2">Alternative Phone</h6>
        <Col sm={4}>{customer.phone}</Col>
      </Row>
    </>
  );
};

export default CustomerCardView;
