import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Col, Label, Row, Spinner } from "reactstrap";

import { Link, withRouter } from "react-router-dom";

import DateTimeOutput from "components/Common/DateTimeOutput";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo } from "helpers/utils";
import { useActiveUser } from "hooks";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { createSalesTarget, editSalesTarget, getSalesTarget } from "store/salesTarget/services";
import { getStores } from "store/store/services";
import SalesTargetPersons from "./SalesTargetPersons";
import SalesTargetRates from "./SalesTargetRates";
import salesTargetUtils from "./salesTarget_utils";
import Privileges from "models/Privileges";
import DateOutput from "components/Common/DateOutput";

const initialLoadState = { loading: false, error: null, loaded: false };

const SalesTarget = ({ id, history }) => {
  const activeUser = useActiveUser();
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [salesTarget, setSalesTarget] = useState(salesTargetUtils.initialValues());
  const [salesTargetView, setSalesTargetView] = useState();

  const [confirmState, setConfirmState] = useState({
    open: false,
    onConfirm: null,
    title: "",
  });

  const [showPersonPopup, setShowPersonPopup] = useState(false);

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setSalesTarget((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setSalesTarget((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setSalesTarget((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const loadSalesTarget = async (salesTargetId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getSalesTarget(salesTargetId);
      const prd = salesTargetUtils.mapToFormDataModel(mdl);
      setSalesTarget(prd);
      setSalesTargetView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  const validateForm = () => {
    if (!salesTarget.store?.value) {
      toast.error("Please select store");
      return false;
    }
    if (!salesTarget.startDate) {
      toast.error("Please select start date");
      return false;
    }
    if (!salesTarget.endDate) {
      toast.error("Please select end date");
      return false;
    }
    return true;
  };

  //handle shipment
  const handleSaveSalesTarget = async () => {
    if (!validateForm()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = salesTargetUtils.mapToSaveModel(salesTarget);
      const mdl = await createSalesTarget(saveModel);
      showInfo("Sales target created successfully");
      history.push(`/sales-target/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateSalesTarget = async () => {
    if (!validateForm()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = salesTargetUtils.mapToSaveModel(salesTarget);
      const mdl = await editSalesTarget(id, saveModel);
      setSalesTarget(salesTargetUtils.mapToFormDataModel(mdl));
      setSalesTargetView(mdl);
      showInfo("Sales target updated successfully");
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadSalesTarget(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Sales target could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <h4 className="text-primary">
            <Breadcrumb listClassName="p-0">
              <BreadcrumbItem>
                <Link to="/sales-targets">Sales Targets</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {id == null && <b>New Sales Target</b>}
                {id != null && <b>Edit Sales Target</b>}
              </BreadcrumbItem>
            </Breadcrumb>
          </h4>
        )}
        {salesTargetView && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Period</h6>
                <Col sm={4}>
                  <>
                    <DateOutput date={salesTargetView.startDate} />
                    {" - "}
                    <DateOutput date={salesTargetView.endDate} />
                  </>
                  {activeUser.hasPrivilege(Privileges.SALES_TARGET.DETAIL) && (
                    <Link to={`/sales-target/${salesTargetView.id}/detail`} className="px-2" title="View">
                      <i className="fa fa-eye"></i>
                    </Link>
                  )}
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by </b>
                  {salesTargetView.createUser}
                  <b> at </b>
                  <DateTimeOutput date={salesTargetView.createdDate} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="deliveryBranch" className="col-sm-3 col-form-label">
                      Store
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getStores}
                        name="store"
                        value={salesTarget.store?.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="startDate" className="col-sm-3 col-form-label">
                      Start Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={salesTarget.startDate}
                        placeholderText="Start Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("startDate", date);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="endDate" className="col-sm-3 col-form-label">
                      End Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={salesTarget.endDate}
                        placeholderText="End Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("endDate", date);
                        }}
                      />
                    </Col>
                  </div>
                </div>
              </CardBody>
            </Card>

            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <SalesTargetRates salesTargetId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <SalesTargetPersons salesTargetId={id} />
                  </CardBody>
                </Card>
              </>
            )}
            <br />
            <br />
            <Card className="shadow-lg nv-sticky-card mt-2">
              <CardBody className="">
                <Row>
                  {id && (
                    <Col>
                      <Button color="success" size="md" onClick={handleUpdateSalesTarget} disabled={saving}>
                        <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                      </Button>
                    </Col>
                  )}
                  {!id && (
                    <Col>
                      <Button color="success" size="md" onClick={handleSaveSalesTarget} disabled={saving}>
                        <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </>
        )}
        {confirmState.open && (
          <SweetAlert
            title={confirmState.title}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setConfirmState({ open: false });
              confirmState.onConfirm();
            }}
            onCancel={() => {
              setConfirmState({ open: false });
            }}
          ></SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

SalesTarget.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SalesTarget);
