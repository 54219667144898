import CreatableSelectBoxWithFilterService from "components/Selectbox/CreatableSelectBoxWithFilterService";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { Button, Col, Label, Row } from "reactstrap";
import { searchCustomersByFilter } from "store/customer/services";
import { getTaskStatus, getUserListContent } from "store/definitions/services";

const TaskFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueOption = useCallback((name, value, valueObject) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: valueObject.option,
    }));
  }, []);

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Task Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.no || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="no"
                  onValueChange={({ value }) => {
                    handleSetFilterValue("no", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="taskStatus" className="col-sm-3 col-form-label">
                Task Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getTaskStatus}
                  name="taskStatus"
                  value={filter.taskStatus}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="plannedDate" className="col-sm-3 col-form-label">
                Assignee
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  service={getUserListContent}
                  name="assignee"
                  value={filter.assignee?.id}
                  isMulti={false}
                  setValue={handleSetValueOption}
                  labelField="fullName"
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="plannedDate" className="col-sm-3 col-form-label">
                Reporter
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  service={getUserListContent}
                  name="reporter"
                  value={filter.reporter?.id}
                  isMulti={false}
                  setValue={handleSetValueOption}
                  labelField="fullName"
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="startCreatedAt" className="col-sm-3 col-form-label">
                Created Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.startCreatedAt}
                      placeholderText="Create Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("startCreatedAt", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.endCreatedAt}
                      placeholderText="Create End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("endCreatedAt", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp; &nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </form>
    </React.Fragment>
  );
};

export default TaskFilterForm;
