import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useState } from "react";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { getStores } from "store/store/services";

const docTypes = [
  {
    value: "Invoice",
    label: "Invoice",
  },
  {
    value: "Receipt",
    label: "Receipt",
  },
];
const sources = [
  {
    value: "PAYMENT",
    label: "PAYMENT",
  },
  {
    value: "FINANCE",
    label: "FINANCE",
  },
];

const InvoiceFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col xl="12" xxl="6">
            <div className="row mb-4">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Document Type
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={() => Promise.resolve(docTypes)}
                  name="docType"
                  value={filter.docType}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Invoice Source
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={() => Promise.resolve(sources)}
                  name="source"
                  value={filter.source}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Order Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.orderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="orderId"
                  onValueChange={({ value }) => {
                    handleSetFilterValue("orderId", value);
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="customerFullName" className="col-sm-3 col-form-label">
                Customer
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.customerFullName || ""}
                  name="customerFullName"
                  placeholder="customer.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("customerFullName", e.target.value);
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="authByFullName" className="col-sm-3 col-form-label">
                Auth By
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.authByFullName}
                  name="authByFullName"
                  placeholder="auth by.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("authByFullName", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col xl="12" xxl="6">
            <div className="row mb-4">
              <Label htmlFor="invoiceNumber" className="col-sm-3 col-form-label">
                Invoice Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.invoiceNumber || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="invoiceNumber"
                  onValueChange={({ value }) => {
                    handleSetFilterValue("invoiceNumber", value);
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="receiptNumber" className="col-sm-3 col-form-label">
                Receipt Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.receiptNumber || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="receiptNumber"
                  onValueChange={({ value }) => {
                    handleSetFilterValue("receiptNumber", value);
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="deliveryDateStart" className="col-sm-3 col-form-label">
                Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.afterThan}
                      placeholderText="Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("afterThan", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.beforeThan}
                      placeholderText="End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("beforeThan", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="branchId" className="col-sm-3 col-form-label">
                Branch
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="branchId"
                  value={filter.branchId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp; &nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </form>
    </React.Fragment>
  );
};

export default InvoiceFilterForm;
