import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { Link, useLocation, withRouter } from "react-router-dom";

import enums, { EnumValues, getEnumLabel } from "common/enums";
import CustomSpinner from "components/Common/CustomSpinner";
import DateTimeOutput from "components/Common/DateTimeOutput";
import { showInfo } from "helpers/utils";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { cancelTask, completeTask, createTask, editTask, getTask, startTask } from "store/task/services";
import TaskDocuments from "./TaskDocuments";
import TaskNotes from "./TaskNotes";
import taskUtils from "./task_utils";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { getUserListContent } from "store/definitions/services";

const initialLoadState = { loading: false, error: null, loaded: false };

const Task = ({ id, history }) => {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [taskView, setTaskView] = useState();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [loadingStart, setLoadingStart] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [confirmState, setConfirmState] = useState({
    open: false,
    onConfirm: null,
    title: "",
  });
  const { search } = useLocation();
  const queryOrderId = new URLSearchParams(search).get("orderId");
  const [task, setTask] = useState(taskUtils.initialValues({ orderId: queryOrderId }));

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setTask((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setTask((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValueOption = useCallback((name, value, valueObject) => {
    setTask((prevValues) => ({
      ...prevValues,
      [name]: valueObject.option,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setTask((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const loadTask = async (taskId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getTask(taskId);
      const prd = taskUtils.mapToFormDataModel(mdl);
      setTask(prd);
      setTaskView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  const validateTask = () => {
    if (!task.assignee?.id) {
      toast.error("Assignee is required");
      return false;
    }
    if (!task.summary) {
      toast.error("Summary is required");
      return false;
    }
    if (!task.description) {
      toast.error("Description is required");
      return false;
    }
    return true;
  };

  //handle task
  const handleSaveTask = async () => {
    if (!validateTask()) {
      return;
    }
    console.log("task", task);
    setSaving(true);
    try {
      const saveModel = taskUtils.mapToSaveModel(task);
      const mdl = await createTask(saveModel);
      showInfo("Task created successfully");
      history.push(`/task/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateTask = async () => {
    if (!validateTask()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = taskUtils.mapToSaveModel(task);
      const mdl = await editTask(id, saveModel);
      setTask(taskUtils.mapToFormDataModel(mdl));
      setTaskView(mdl);
      showInfo("Task updated successfully");
    } finally {
      setSaving(false);
    }
  };

  const handleCompleteTask = async () => {
    async function confirmCompleteTask() {
      setLoadingComplete(true);
      try {
        const mdl = await completeTask(id);
        setTask(taskUtils.mapToFormDataModel(mdl));
        setTaskView(mdl);
        showInfo("Task completed successfully");
      } finally {
        setLoadingComplete(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to COMPLETE the task?",
      onConfirm: confirmCompleteTask,
    });
  };
  const handleStartTask = async () => {
    /*update start */
    if (!validateTask()) {
      return;
    }
    setLoadingStart(true);
    try {
      const saveModel = taskUtils.mapToSaveModel(task);
      const mdl = await editTask(id, saveModel);
      setTask(taskUtils.mapToFormDataModel(mdl));
      setTaskView(mdl);
      // showInfo("Task updated successfully");
    } finally {
      setLoadingStart(false);
    }
    /**update end */

    async function confirmStartTask() {
      setLoadingStart(true);
      try {
        const mdl = await startTask(id);
        setTask(taskUtils.mapToFormDataModel(mdl));
        setTaskView(mdl);
        showInfo("Task started successfully");
      } finally {
        setLoadingStart(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to start the task?",
      onConfirm: confirmStartTask,
    });
  };
  const handleCancelTask = async () => {
    async function confirmCancelTask() {
      setLoadingCancel(true);
      try {
        const mdl = await cancelTask(id);
        setTask(taskUtils.mapToFormDataModel(mdl));
        setTaskView(mdl);
        showInfo("Task cancelled successfully");
      } finally {
        setLoadingCancel(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to CANCEL the task?",
      onConfirm: confirmCancelTask,
    });
  };

  useEffect(() => {
    if (id) {
      loadTask(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.loading && <CustomSpinner />}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Task could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <h4 className="text-primary">
            <Breadcrumb listClassName="p-0">
              <BreadcrumbItem>
                <Link to="/tasks">Tasks</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {id == null && <b>New Task</b>}
                {id != null && <b>Edit Task</b>}
              </BreadcrumbItem>
            </Breadcrumb>
          </h4>
        )}
        {taskView && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Task Number</h6>
                <Col sm={4}>
                  <h5>{taskView.no}</h5>
                </Col>
                <h6 className="col-sm-2">Task Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.taskStatus, taskView.taskStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Created By</h6>
                <Col sm={4}>{taskView.createUser}</Col>
                <h6 className="col-sm-2">Last Modified At</h6>
                <Col sm={4}>
                  <DateTimeOutput date={taskView.updateDate} />
                </Col>
                <h6 className="col-sm-2">Created At</h6>
                <Col sm={4}>
                  <DateTimeOutput date={taskView.createdDate} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="plannedDate" className="col-sm-3 col-form-label">
                      Assignee
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getUserListContent}
                        name="assignee"
                        value={task.assignee?.id}
                        isMulti={false}
                        setValue={handleSetValueOption}
                        labelField="fullName"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                      Summary
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        value={task.summary || ""}
                        name="summary"
                        id="summary"
                        placeholder="summary.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                      Description
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="textarea"
                        value={task.description || ""}
                        name="description"
                        id="description"
                        placeholder="description.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div>
                </div>
              </CardBody>
            </Card>

            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <TaskNotes taskId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <TaskDocuments taskId={id} />
                  </CardBody>
                </Card>
              </>
            )}
            <br />
            <br />
            {(!id ||
              taskView.taskStatus == EnumValues.TASK_STATUS.TO_DO ||
              taskView.taskStatus == EnumValues.TASK_STATUS.IN_PROGRESS) && (
              <Card className="shadow-lg nv-sticky-card mt-2">
                <CardBody className="">
                  <Row>
                    {id && (
                      <>
                        <Col>
                          {taskView.taskStatus == EnumValues.TASK_STATUS.TO_DO && (
                            <>
                              <Button color="success" size="md" onClick={handleStartTask} disabled={loadingStart}>
                                {loadingStart ? <Spinner color="white" size="sm" /> : "Start Task"}
                              </Button>
                            </>
                          )}
                          {taskView.taskStatus == EnumValues.TASK_STATUS.IN_PROGRESS && (
                            <>
                              <Button color="success" size="md" onClick={handleCompleteTask} disabled={loadingComplete}>
                                {loadingComplete ? <Spinner color="white" size="sm" /> : "Complete Task"}
                              </Button>
                            </>
                          )}
                          {(taskView.taskStatus == EnumValues.TASK_STATUS.TO_DO ||
                            taskView.taskStatus == EnumValues.TASK_STATUS.IN_PROGRESS) && (
                            <>
                              &nbsp;&nbsp;
                              <Button color="success" size="md" onClick={handleUpdateTask}>
                                <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                              </Button>
                              &nbsp;&nbsp;
                              <Button color="danger" size="md" onClick={handleCancelTask} disabled={loadingCancel}>
                                {loadingCancel ? <Spinner color="white" size="sm" /> : "Cancel"}
                              </Button>
                            </>
                          )}
                        </Col>
                      </>
                    )}
                    {!id && (
                      <Col>
                        <Button color="success" size="md" onClick={handleSaveTask}>
                          <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            )}
          </>
        )}
        {confirmState.open && (
          <SweetAlert
            title={confirmState.title}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setConfirmState({ open: false });
              confirmState.onConfirm();
            }}
            onCancel={() => {
              setConfirmState({ open: false });
            }}
          ></SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

Task.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Task);
