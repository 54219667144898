import { del, get, post, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//salesTarget
export const getSalesTarget = (id) => get(`/salesTarget/${id}`);
export const editSalesTarget = (id, data) => put(`/salesTarget/${id}`, data);
export const createSalesTarget = (data) => post(`/salesTarget`, data);
export const deleteSalesTarget = (id) => del(`/salesTarget/${id}`);
export const getSalesTargetStats = (id) => get(`/salesTarget/${id}/stats`);

export const getSalesTargets = (pageOpt = { page: 0, size: 1000 }, filter, sort) =>
  get(
    `/salesTarget?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );

export const addPerson = (salesTargetId, data) => put(`/salesTarget/${salesTargetId}/person`, data);
export const addRate = (salesTargetId, data) => put(`/salesTarget/${salesTargetId}/rate`, data);
export const deletePerson = (salesTargetId, salesTargetPersonId) =>
  del(`/salesTarget/${salesTargetId}/person/${salesTargetPersonId}`);
export const deleteRate = (salesTargetId, salesTargetRateId) =>
  del(`/salesTarget/${salesTargetId}/rate/${salesTargetRateId}`);
