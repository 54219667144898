import { useActiveUser } from "hooks";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useRecoilState } from "recoil";
import { getActiveReportDefinitions } from "store/definitions/services";
import { reportsAppliedFilterState } from "./atoms";
import CustomSpinner from "components/Common/CustomSpinner";

export default function Reports() {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const [reportsAppliedFilter, setReportsAppliedFilter] = useRecoilState(reportsAppliedFilterState);

  useEffect(() => {
    loadReports();
  }, []);

  const handleChange = (e) => {
    const query = e.target.value;
    setReportsAppliedFilter(query);
    setReports(filterResults(allReports, query));
  };

  const filterResults = (reports, query) => {
    return reports.filter(
      (report) =>
        !query ||
        (report.name || "").toLowerCase().includes(query.toLowerCase()) ||
        (report.description || "").toLowerCase().includes(query.toLowerCase())
    );
  };

  const loadReports = async () => {
    setLoading(true);
    try {
      const reports = (await getActiveReportDefinitions()).sort((a, b) => a.name.localeCompare(b.name));
      setAllReports(reports);
      setReports(filterResults(reports, reportsAppliedFilter));
    } finally {
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row className="m-2 my-4">
              <h4 className="pb-2">Reports</h4>
              <Col md="12">
                <div className="search-box me-2 d-inline-block">
                  <div className="position-relative">
                    <input
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                      placeholder="Search"
                      value={reportsAppliedFilter || ""}
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mx-2">
              {!loading && reports.length > 0 && (
                <>
                  {reports.map((report) => (
                    <Col sm={6} md={4} key={report.id}>
                      <ReportCard report={report} highlight={reportsAppliedFilter} />
                    </Col>
                  ))}
                </>
              )}
              {!loading && reports.length == 0 && <h6>No reports found</h6>}
              {loading && <CustomSpinner />}
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
function highlightText(text, highlight) {
  if (!text || !highlight) return text;
  const ix = text.toLowerCase().indexOf(highlight.toLowerCase());
  if (ix < 0) return text;
  return (
    text.substring(0, ix) +
    "<mark class='p-0'>" +
    text.substring(ix, ix + highlight.length) +
    "</mark>" +
    text.substring(ix + highlight.length)
  );
}
function ReportCard({ report: { name, description, url, id }, highlight }) {
  return (
    <Card style={{ backgroundColor: "rgb(42 48 66)", padding: 0 }}>
      <CardBody className="pb-0 pt-2" style={{ minHeight: 80 }}>
        <h4>
          <Link to={`/report/${id}`} className="white-link">
            <div dangerouslySetInnerHTML={{ __html: highlightText(name, highlight) }}></div>
          </Link>
        </h4>
        <h6 style={{ color: "#959dabfa" }}>
          <div dangerouslySetInnerHTML={{ __html: highlightText(description, highlight) }}></div>
        </h6>
      </CardBody>
    </Card>
  );
}
