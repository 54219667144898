import moment from "moment";

class TaskUtils {
  initialValues = ({ orderId = "" }) => ({
    id: null,
    status: "y",
    orderId,
    taskStatus: {},
    plannedDate: "",
    orderedDate: "",
    estimateTaskDate: "",
    assignee: {},
    summary: "",
    description: "",
  });
  mapToSaveModel = ({
    id,
    status,
    taskStatus = {},
    plannedDate,
    orderedDate,
    estimateTaskDate,
    orderId,
    assignee = {},
    summary,
    description,
  }) => ({
    id,
    status,
    orderId,
    taskStatus,
    plannedDate: plannedDate && moment(plannedDate).utcOffset(0, true).format("YYYY-MM-DD"),
    orderedDate: orderedDate && moment(orderedDate).utcOffset(0, true).format("YYYY-MM-DD"),
    estimateTaskDate: estimateTaskDate && moment(estimateTaskDate).utcOffset(0, true).format("YYYY-MM-DD"),
    assigneeId: assignee?.id,
    summary,
    description,
  });

  mapToFormDataModel = ({
    id,
    status,
    taskStatus = "",
    order = {},
    plannedDate,
    orderedDate,
    estimateTaskDate,
    assignee,
    summary,
    description,
  }) => ({
    id,
    status,
    taskStatus,
    orderId: order?.id,
    plannedDate: plannedDate && moment(plannedDate, "YYYY-MM-DD").toDate(),
    orderedDate: orderedDate && moment(orderedDate, "YYYY-MM-DD").toDate(),
    estimateTaskDate: estimateTaskDate && moment(estimateTaskDate, "YYYY-MM-DD").toDate(),
    assignee,
    summary,
    description,
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });

  initialFilterValues = () => ({
    no: null,
    taskStatus: null,
    startCreatedAt: null,
    endCreatedAt: null,
    assignee: {},
    reporter: {},
  });

  mapToTaskSearch = (
    filter = {
      no: null,
      taskStatus: null,
      startCreatedAt: null,
      endCreatedAt: null,
      assignee: null,
      reporter: null,
    }
  ) => {
    return {
      no: filter.no,
      taskStatuses: filter.taskStatus ? [filter.taskStatus] : null,
      startCreatedAt: filter.startCreatedAt == null ? null : moment(filter.startCreatedAt).format("YYYY-MM-DD"),
      endCreatedAt: filter.endCreatedAt == null ? null : moment(filter.endCreatedAt).format("YYYY-MM-DD"),
      assigneeId: filter.assignee?.id,
      reporterId: filter.reporter?.id,
    };
  };
}
const taskUtils = new TaskUtils();
export default taskUtils;
