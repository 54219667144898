import { PropTypes } from "prop-types";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import DateTimeOutput from "components/Common/DateTimeOutput";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { getActionLogs } from "store/actionLog/services";
import ActionLogFilterForm from "./ActionLogFilterForm";
import actionLogUtils from "./actionlog_utils";
import { actionLogsAppliedFilterState, actionLogsAppliedSortState } from "./atoms";
import enums, { getEnumLabel } from "common/enums";

const ActionLogs = ({ history }) => {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [appliedFilter, setAppliedFilter] = useRecoilState(actionLogsAppliedFilterState);
  const [appliedSort, setAppliedSort] = useRecoilState(actionLogsAppliedSortState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("ActionLogs");
  const loadActionLogs = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getActionLogs(page, actionLogUtils.mapToActionLogSearch(filter), sort);
      setTotalSize(totalElements);
      setOrders(content);
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (type, details) => {
    // console.log("handlePageChange", details, type);
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadActionLogs(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField, sortOrder };
      setAppliedSort(newSort);
      loadActionLogs(pageOptions, appliedFilter, newSort);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadActionLogs(newPage, appliedFilter, appliedSort);
    setHasFilter(hasAnyValue(appliedFilter));
    // setShowFilter(false);
  };

  const columns = [
    {
      dataField: "createdDate",
      text: "Create Date",
      sort: true,
      formatter: (cell, row) => <DateTimeOutput date={row.createdDate} />,
    },
    {
      dataField: "createUser",
      text: "Create User",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
    },
    {
      dataField: "process",
      text: "Process",
      sort: true,
    },
    {
      text: "Resource",
      dataField: "actionModel",
      sort: true,
      formatter: (cell) => getEnumLabel(enums.actionModels, cell),
    },
    {
      text: "Record ID",
      dataField: "recordId",
      sort: true,
    },
    {
      text: "Parent Resource",
      dataField: "parentAction",
      sort: true,
      formatter: (cell) => getEnumLabel(enums.parentActionTypes, cell),
    },
    {
      text: "Parent Record ID",
      dataField: "parentId",
      sort: true,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Action Logs</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <ActionLogFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                      <h4>Action Logs</h4>
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
                        keyField="id"
                        responsive
                        bordered={true}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={orders}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ActionLogs.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ActionLogs);
