import enums, { getEnumLabel } from "common/enums";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import DateTimeOutput from "components/Common/DateTimeOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import OrderCard from "pages/Order/OrderCard";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getTask } from "store/task/services";

const TaskCard = ({ id }) => {
  const activeUser = useActiveUser();
  const [taskState, setTaskState] = useState({
    error: null,
    loading: false,
    task: null,
  });
  const [editUsers, setEditUsers] = useState(false);
  const [savedTaskUsers, setSavedTaskUsers] = useState([]);
  const { task } = taskState;
  const loadTask = async (showLoading = true) => {
    setTaskState((state) => ({
      ...state,
      loading: showLoading,
    }));
    try {
      const ord = await getTask(id);
      setTaskState((state) => ({
        ...state,
        task: ord,
        loading: false,
      }));
      setSavedTaskUsers(ord.users);
    } catch (error) {
      setTaskState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadTask();
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        {taskState.loading && <CustomSpinner />}
        {taskState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Task could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {taskState.task && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Task Number</h6>
                <Col sm={4}>
                  <h5>
                    {activeUser.hasPrivilege(Privileges.TASK.DETAIL) && (
                      <Link to={`/task/${task.id}/detail`} title="View detail">
                        {task.no}
                      </Link>
                    )}
                    {!activeUser.hasPrivilege(Privileges.TASK.DETAIL) && task.no}
                    {activeUser.hasPrivilege(Privileges.TASK.UPDATE) && (
                      <Link to={`/task/${task.id}`} className="p-2" title="Edit">
                        <i className="fa fa-edit"></i>
                      </Link>
                    )}
                  </h5>
                </Col>
                <h6 className="col-sm-2">Task Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.taskStatus, task.taskStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Started Date</h6>
                <Col sm={4}>
                  <DateOutput date={task.startedAt} />
                </Col>
                <h6 className="col-sm-2">Completed Date</h6>
                <Col sm={4}>
                  <DateOutput date={task.completedAt} />
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by</b> {task.createUser}
                  <b> at</b> <DateTimeOutput date={task.createdDate} />
                </Col>
                <h6 className="col-sm-2">Assignee</h6>
                <Col sm={4}>{task.assignee?.fullName}</Col>
                <h6 className="col-sm-2">Summary</h6>
                <Col sm={4}>{task.summary}</Col>
                <h6 className="col-sm-2">Decription</h6>
                <Col sm={4}>{task.description}</Col>
              </Row>
              {task.order && (
                <Row className="py-1">
                  <Col sm={12}>
                    <h5>Order Details</h5>
                    <hr />
                  </Col>
                  <Col sm={12}>
                    <OrderCard id={task.order?.id} />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default TaskCard;
