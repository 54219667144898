import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createTaxGroups, deleteTaxGroups, editTaxGroups, getTaxGroups } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

export const taxGroupsFormValues = [
  { name: "name", label: "Name", size: 12, type: "text" },
  { name: "description", label: "Description", size: 12, type: "textarea" },
  { name: "value", label: "Value", size: 12, type: "text" },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];

const TaxGroups = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "value",
      text: "Rate %",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Colors</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getTaxGroups,
            deleteapi: deleteTaxGroups,
            createapi: createTaxGroups,
            updateapi: editTaxGroups,
          }}
          tableColumns={columns}
          formValues={{
            values: taxGroupsFormValues,
            validationSchema: {
              name: Yup.string().required("Name is required"),
              description: Yup.string(),
              value: Yup.string().required("Value is required"),
              status: Yup.string(),
            },
          }}
          pageDetails={{ definitionName: "Tax Group", title: "Tax Group List" }}
          privilege={{
            create: Privileges.TAXGROUP.CREATE,
            update: Privileges.TAXGROUP.UPDATE,
            delete: Privileges.TAXGROUP.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(TaxGroups);
