import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

import { Link, withRouter } from "react-router-dom";

import { EnumValues } from "common/enums";
import CustomSpinner from "components/Common/CustomSpinner";
import ImagesModal from "components/Common/ImagesModal";
import MoneyOutput from "components/Common/MoneyOutput";
import CreatableSelectBoxWithFilterService from "components/Selectbox/CreatableSelectBoxWithFilterService";
import CreatableSelectBoxWithService from "components/Selectbox/CreatableSelectBoxWithService";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo, showWarn } from "helpers/utils";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import CustomerAddressCard from "pages/Customers/CustomerAddressCard";
import CustomerCard from "pages/Customers/CustomerCard";
import { customerFormValues, customerValidationSchema } from "pages/Customers/Customers";
import { customerAddressFormValues, customerAddressValidationSchema } from "pages/Definitions/Address/CustomerAddress";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { addressService } from "services/AddressService";
import {
  createCustomers,
  createCustomersAddress,
  getCustomersAddress,
  searchCustomersByFilter,
} from "store/customer/services";
import { getProformaCancelStatus, getSourceOfSaleTypes, getUserListContent } from "store/definitions/services";
import {
  addImageToOrder,
  approveOrder,
  cancelOrder,
  cancelledToApproval,
  createOrder,
  deleteOrderImage,
  editOrder,
  getOrder,
  getOrderDiscountStatus,
  getOrderImages,
  startOrder,
} from "store/order/services";
import { getStores } from "store/store/services";
import OrderCardView from "./OrderCardView";
import OrderDocuments from "./OrderDocuments";
import OrderFinances from "./OrderFinances";
import OrderHeaderCardView from "./OrderHeaderCardView";
import OrderNotes from "./OrderNotes";
import OrderPayments from "./OrderPayments";
import OrderProducts from "./OrderProducts";
import orderUtils from "./order_utils";

const initialLoadState = { loading: false, error: null, loaded: false };

const Order = ({ id, history }) => {
  const activeUser = useActiveUser();
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [reloadAddressKey, setReloadAddressKey] = useState("");
  const [order, setOrder] = useState(orderUtils.initialValues());
  const [orderView, setOrderView] = useState();
  const [showOrderImages, setShowOrderImages] = useState(false);
  const [message, setMessage] = useState();
  const [confirmState, setConfirmState] = useState({
    open: false,
    onConfirm: null,
    title: "",
  });
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setOrder((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const isFormValid =
    // order.billingAddressId &&
    // order.deliveryAddressId &&
    order.status && order.deliveryBranch?.value && order.salesPerson?.value && order.customerId;

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setOrder((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setOrder((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const orderCanBeCanceled = () => {
    if (orderView.orderStatus == EnumValues.ORDER_STATUS.CANCELLED) {
      return false;
    }
    if (activeUser.hasPrivilege(Privileges.ORDER.IN_PROGRESS_UPDATE)) {
      return true;
    }
    if (
      activeUser.hasPrivilege(Privileges.ORDER.CANCEL) &&
      (orderView.orderStatus == EnumValues.ORDER_STATUS.PROFORMA ||
        orderView.orderStatus == EnumValues.ORDER_STATUS.WAITING_FOR_APPROVAL)
    ) {
      return true;
    }
    return false;
  };

  const handleSetCustomerDeliveryAddress = useCallback((name, value, obj, isNew) => {
    if (isNew) {
      setReloadAddressKey(Math.random());
    }
    setOrder((prevValues) => {
      return {
        ...prevValues,
        deliveryAddressId: value,
        billingAddressId: prevValues.billingAddressId || value,
      };
    });
  }, []);
  const handleSetCustomerBillingAddress = useCallback((name, value, obj, isNew) => {
    if (isNew) {
      setReloadAddressKey(Math.random());
    }
    setOrder((prevValues) => {
      return {
        ...prevValues,
        billingAddressId: value,
        deliveryAddressId: prevValues.deliveryAddressId || value,
      };
    });
  }, []);

  const handleSetCustomer = useCallback((name, value) => {
    setOrder((prevValues) => {
      return {
        ...prevValues,
        customerId: value,
        billingAddressId: null,
        deliveryAddressId: null,
      };
    });
  }, []);

  const handleFinanceChanged = () => {
    reloadOrderView(orderView.id);
  };
  const handlePaymentChanged = () => {
    reloadOrderView(orderView.id);
  };
  const handleProductChanged = () => {
    reloadOrderView(orderView.id);
  };

  const reloadOrderView = async (orderId) => {
    const mdl = await getOrder(orderId);
    setOrderView(mdl);
  };

  const loadOrder = async (orderId) => {
    setLoadState({ loading: true, error: null, loaded: false });

    try {
      const mdl = await getOrder(orderId);
      const prd = orderUtils.mapToFormDataModel(mdl);
      setOrder(prd);
      setOrderView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.log("loadorder error", error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  //handle order
  const handleSaveOrder = async () => {
    if (!validateOrder()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = orderUtils.mapToSaveModel(order);
      const mdl = await createOrder(saveModel);
      showInfo("Proforma created successfully");
      history.push(`/order/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateOrder = async () => {
    if (!validateOrder()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = orderUtils.mapToSaveModel(order);
      const mdl = await editOrder(id, saveModel);
      setOrder(orderUtils.mapToFormDataModel(mdl));
      setOrderView(mdl);
      showInfo("Successfully updated");
    } finally {
      setSaving(false);
    }
  };
  const validateOrder = () => {
    if (order.sourceOfSaleTypes?.includes("OTHER") && !order.sourceOfSaleDescription) {
      toast.error("Please enter source of sale description");
      return false;
    }
    return true;
  };
  const handleStartOrder = async () => {
    async function confirmStartOrder() {
      setLoadingCreate(true);
      try {
        const mdl = await startOrder(id);
        setOrder(orderUtils.mapToFormDataModel(mdl));
        setOrderView(mdl);
        setConfirmState({ open: false });
        const { stockToNewOrderProducts } = mdl;
        if (stockToNewOrderProducts != null && stockToNewOrderProducts.length > 0) {
          const messageTitle =
            'Your order has been created successfully, but the following products added from stock are used by other orders, so their status has been changed to "New Order"';
          showWarn(messageTitle);
          setMessage({
            severity: "warning",
            title: messageTitle,
            items: stockToNewOrderProducts,
          });
        } else {
          showInfo("Order created successfully");
        }
      } finally {
        setLoadingCreate(false);
      }
    }
    const { message } = await getOrderDiscountStatus(id);
    if (message != "OK") {
      setConfirmState({
        open: true,
        title: message,
        onConfirm: confirmStartOrder,
      });
    } else {
      confirmStartOrder();
    }
  };
  const handleCancelOrder = async () => {
    if (orderView.orderStatus == EnumValues.ORDER_STATUS.PROFORMA) {
      if (!orderView.proformaCancelStatus) {
        toast.error("Proforma Status Detail must be entered and saved.");
        return;
      }
      if (orderView.proformaCancelStatus == EnumValues.PROFORMA_CANCEL_STATUS.ACTIVE) {
        toast.error("Proforma Status Detail must be a value other than ACTIVE.");
        return;
      }
    }
    const name = orderView.orderId == null ? "Proforma" : "Order";
    async function confirmCancelOrder() {
      setLoadingCancel(true);
      try {
        const mdl = await cancelOrder(id);
        setOrder(orderUtils.mapToFormDataModel(mdl));
        setOrderView(mdl);
        showInfo(`${name} cancelled successfully`);
        setConfirmState({ open: false });
      } finally {
        setLoadingCancel(false);
      }
    }
    setConfirmState({
      open: true,
      title: `Are your sure you want to CANCEL the ${name}?`,
      onConfirm: confirmCancelOrder,
    });
  };
  const handleApproveOrder = async () => {
    async function confirmApproveOrder() {
      setLoadingApprove(true);
      try {
        const mdl = await approveOrder(id);
        setOrder(orderUtils.mapToFormDataModel(mdl));
        setOrderView(mdl);
        showInfo("Proforma approved successfully");
        setConfirmState({ open: false });
      } finally {
        setLoadingApprove(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to APPROVE the proforma?",
      onConfirm: confirmApproveOrder,
    });
  };
  const handleCancelledToApproval = async () => {
    async function confirmApproveOrder() {
      setLoadingApprove(true);
      try {
        const mdl = await cancelledToApproval(id);
        setOrder(orderUtils.mapToFormDataModel(mdl));
        setOrderView(mdl);
        showInfo("Order send to approval successfully");
        setConfirmState({ open: false });
      } finally {
        setLoadingApprove(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are you sure you want to send the canceled-order for approval?",
      onConfirm: confirmApproveOrder,
    });
  };

  //handle images
  const handleAddImage = ({ file, isDefault }) => {
    const imageData = new FormData();
    imageData.append("imageFile", file, file.path);
    imageData.append("isDefault", isDefault);
    return addImageToOrder(id, imageData);
  };

  useEffect(() => {
    if (id) {
      loadOrder(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  const headerEditable =
    orderView == null ||
    orderView.orderStatus == EnumValues.ORDER_STATUS.PROFORMA ||
    activeUser.hasPrivilege(Privileges.ORDER.IN_PROGRESS_UPDATE);
  const customerEditable = orderView == null || orderView.orderStatus != EnumValues.ORDER_STATUS.CANCELLED;
  const metaEditable = headerEditable || customerEditable;

  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.loading && <CustomSpinner />}
        {loadState.loaded && (
          <h4 className="text-primary">
            <Breadcrumb listClassName="p-0">
              <BreadcrumbItem>
                {orderView != null && orderView?.orderId && <Link to="/orders">Orders</Link>}
                {(orderView == null || !orderView?.orderId) && <Link to="/proformas">Proformas</Link>}
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {!id && <b>New Proforma</b>}
                {orderView != null && !orderView?.orderId && <b>Edit Proforma</b>}
                {orderView != null && orderView?.orderId && <b>Edit Order</b>}
              </BreadcrumbItem>
            </Breadcrumb>
          </h4>
        )}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Order could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && orderView && orderView.orderStatus == EnumValues.ORDER_STATUS.PROFORMA && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Proforma Number</h6>
                <Col sm={2}>
                  <h5>{orderView.proformaId}</h5>
                </Col>
                <h6 className="col-sm-2">Created By</h6>
                <Col sm={2}>{orderView.createUser}</Col>
                {activeUser.hasPrivilege(Privileges.CUSTOMER.BALANCE_VIEW) && (
                  <>
                    <h6 className="col-sm-2">Balance</h6>
                    <Col sm={2}>
                      <h5>
                        <MoneyOutput value={orderView.balance} currency="GBP" />
                      </h5>
                    </Col>
                  </>
                )}
              </Row>
              <Row className="py-1">
                <h6 className="col-sm-2">Last Modified At</h6>
                <Col sm={2}>{orderView.updateDate}</Col>
                <h6 className="col-sm-2">Created At</h6>
                <Col sm={2}>{orderView.createdDate}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <>
            {message != null && (
              <UncontrolledAlert color={message.severity}>
                <h6>{message.title}</h6>
                {message.items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </UncontrolledAlert>
            )}

            {!metaEditable && <OrderCardView order={orderView} />}
            {metaEditable && !headerEditable && <OrderHeaderCardView order={orderView} />}
            {headerEditable && (
              <Card className="shadow-lg">
                <CardBody>
                  <div className="px-2 py-2">
                    <div className="row mb-4">
                      <Label htmlFor="deliveryBranch" className="col-sm-3 col-form-label">
                        Branch
                      </Label>
                      <Col sm={9}>
                        <MultiselectWithService
                          service={getStores}
                          name="deliveryBranch"
                          value={order.deliveryBranch.value}
                          isMulti={false}
                          setValue={(...args) => {
                            handleSetValueObj(...args);
                            handleSetValueObj("salesPerson", null, {});
                          }}
                        />
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label htmlFor="salesPerson" className="col-sm-3 col-form-label">
                        Sales Person
                      </Label>
                      <Col sm={9}>
                        <MultiselectWithService
                          service={() => {
                            if (order.deliveryBranch?.value) {
                              return getUserListContent({ branchId: order.deliveryBranch?.value });
                            }
                            return Promise.resolve([]);
                          }}
                          name="salesPerson"
                          value={order.salesPerson.value}
                          isMulti={false}
                          setValue={handleSetValueObj}
                          labelField="fullName"
                          reloadOptionsKey={order.deliveryBranch?.value}
                        />
                      </Col>
                    </div>
                    <div className="row mb-4">
                      <Label htmlFor="deliveryNotes" className="col-sm-3 col-form-label">
                        Source Of Sale
                      </Label>
                      <Col sm={9}>
                        <MultiselectWithService
                          isClearable
                          service={getSourceOfSaleTypes}
                          name="sourceOfSaleTypes"
                          value={order.sourceOfSaleTypes}
                          isMulti={true}
                          id="sourceOfSaleTypes"
                          setValue={handleSetValue}
                        />
                      </Col>
                    </div>
                    {order.sourceOfSaleTypes?.includes("OTHER") && (
                      <div className="row mb-4">
                        <Label htmlFor="deliveryNotes" className="col-sm-3 col-form-label">
                          Source Of Sale Description
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="text"
                            value={order.sourceOfSaleDescription}
                            name="sourceOfSaleDescription"
                            placeholder="description.."
                            className="form-control"
                            onChange={handleChange}
                          />
                        </Col>
                      </div>
                    )}
                    {orderView != null && orderView.orderStatus == EnumValues.ORDER_STATUS.PROFORMA && (
                      <div className="row">
                        <Label htmlFor="deliveryNotes" className="col-sm-3 col-form-label">
                          Proforma Status Detail
                        </Label>
                        <Col sm={9}>
                          <MultiselectWithService
                            isClearable
                            service={getProformaCancelStatus}
                            name="proformaCancelStatus"
                            value={order.proformaCancelStatus}
                            isMulti={false}
                            id="proformaCancelStatus"
                            setValue={handleSetValue}
                          />
                        </Col>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            )}
            {customerEditable && (
              <Card className="shadow-lg">
                <CardBody>
                  <div className="px-2 py-2">
                    <div className="row">
                      <Col sm={12}>
                        <h5>Customer Details</h5>
                        <hr />
                      </Col>
                      <Col sm={12}>
                        <div className="row mb-4">
                          <Label htmlFor="salesPerson" className="col-sm-3 col-form-label">
                            Customer
                          </Label>
                          <Col sm={9}>
                            <CreatableSelectBoxWithFilterService
                              loadService={searchCustomersByFilter}
                              name="customerId"
                              value={order.customerId}
                              handleChange={handleSetCustomer}
                              newItemDetails={{
                                formValues: {
                                  values: customerFormValues,
                                  validationSchema: customerValidationSchema,
                                  itemName: "Customer",
                                },
                                createapi: createCustomers,
                              }}
                            />
                          </Col>
                        </div>
                        {order.customerId && (
                          <div className="row mb-4">
                            <Label htmlFor="salesPerson" className="col-sm-3 col-form-label"></Label>
                            <Col sm={9}>
                              <CustomerCard id={order.customerId} />
                            </Col>
                          </div>
                        )}

                        {order.customerId && (
                          <div className="row mb-4">
                            <Label htmlFor="salesPerson" className="col-sm-3 col-form-label">
                              Customer Address
                            </Label>
                            <Col sm={9}>
                              <Row>
                                <Col>
                                  <Row>
                                    <Col>
                                      <h5>Delivery Address</h5>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <CreatableSelectBoxWithService
                                        service={() => getCustomersAddress({ page: 0 }, order.customerId)}
                                        name="deliveryAddressId"
                                        optionLabelField="address1"
                                        value={order.deliveryAddressId}
                                        handleChange={handleSetCustomerDeliveryAddress}
                                        newItemDetails={{
                                          formValues: {
                                            values: customerAddressFormValues,
                                            validationSchema: customerAddressValidationSchema,
                                            itemName: "Customer Address",
                                          },
                                          createapi: (data) => createCustomersAddress(order.customerId, data),
                                        }}
                                        placeholder="Select Delivery Address.."
                                        ownerId={order.customerId}
                                        reloadOptionsKey={reloadAddressKey}
                                        addressCallback={addressService.setCustomerAddressFields}
                                      />
                                    </Col>
                                  </Row>
                                  {order.deliveryAddressId && (
                                    <Row sm={12}>
                                      <Col style={{ paddingTop: 20 }}>
                                        <CustomerAddressCard id={order.deliveryAddressId} />
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                                <Col>
                                  <Row>
                                    <Col>
                                      <h5>Billing Address</h5>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <CreatableSelectBoxWithService
                                        service={() => getCustomersAddress({ page: 0 }, order.customerId)}
                                        name="billingAddressId"
                                        optionLabelField="address1"
                                        value={order.billingAddressId}
                                        handleChange={handleSetCustomerBillingAddress}
                                        newItemDetails={{
                                          formValues: {
                                            values: customerAddressFormValues,
                                            validationSchema: customerAddressValidationSchema,
                                            itemName: "Customer Address",
                                          },
                                          createapi: (data) => createCustomersAddress(order.customerId, data),
                                        }}
                                        placeholder="Select Billing Address.."
                                        ownerId={order.customerId}
                                        reloadOptionsKey={reloadAddressKey}
                                        addressCallback={addressService.setCustomerAddressFields}
                                      />
                                    </Col>
                                  </Row>
                                  {order.billingAddressId && (
                                    <Row sm={12}>
                                      <Col style={{ paddingTop: 20 }}>
                                        <CustomerAddressCard id={order.billingAddressId} />
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </div>
                        )}
                      </Col>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <OrderProducts
                      orderId={id}
                      type={
                        orderView.orderStatus == EnumValues.ORDER_STATUS.PROFORMA ||
                        activeUser.hasPrivilege(Privileges.ORDER.IN_PROGRESS_UPDATE)
                          ? "edit"
                          : "view"
                      }
                      onChange={handleProductChanged}
                    />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <OrderNotes orderId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <OrderDocuments orderId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <OrderPayments orderId={id} onChange={handlePaymentChanged} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <OrderFinances orderId={id} onChange={handleFinanceChanged} />
                  </CardBody>
                </Card>
              </>
            )}
            <br />
            <br />
            <Card className="shadow-lg nv-sticky-card">
              <CardBody className="">
                <Row>
                  {id && (
                    <Col>
                      {metaEditable && (
                        <>
                          <Button
                            color="success"
                            size="md"
                            onClick={handleUpdateOrder}
                            disabled={saving || !isFormValid}
                          >
                            {saving ? <Spinner color="white" size="sm" /> : "Update"}
                          </Button>
                          &nbsp;&nbsp;
                        </>
                      )}
                      {orderView.orderStatus == EnumValues.ORDER_STATUS.PROFORMA && (
                        <>
                          <Button color="success" size="md" onClick={handleStartOrder} disabled={loadingCreate}>
                            {loadingCreate ? <Spinner color="white" size="sm" /> : "Create Order"}
                          </Button>
                          &nbsp;&nbsp;
                        </>
                      )}
                      {orderCanBeCanceled() && (
                        <>
                          <Button color="danger" size="md" onClick={handleCancelOrder} disabled={loadingCancel}>
                            {loadingCancel ? <Spinner color="white" size="sm" /> : "Cancel"}
                          </Button>
                          &nbsp;&nbsp;
                        </>
                      )}
                      {activeUser.hasPrivilege(Privileges.PROFORMA.APPROVE) &&
                        orderView.orderStatus == EnumValues.ORDER_STATUS.WAITING_FOR_APPROVAL && (
                          <>
                            <Button color="success" size="md" onClick={handleApproveOrder} disabled={loadingApprove}>
                              {loadingApprove ? <Spinner color="white" size="sm" /> : "Approve"}
                            </Button>
                            &nbsp;&nbsp;
                          </>
                        )}
                      {activeUser.hasPrivilege(Privileges.ORDER.CANCELLED_ORDER_TO_APPROVAL) &&
                        orderView.orderStatus == EnumValues.ORDER_STATUS.CANCELLED && (
                          <>
                            <Button
                              color="danger"
                              size="md"
                              onClick={handleCancelledToApproval}
                              disabled={loadingApprove}
                            >
                              {loadingApprove ? <Spinner color="white" size="sm" /> : "Send To Approval"}
                            </Button>
                            &nbsp;&nbsp;
                          </>
                        )}
                      <Button color="primary" size="md" onClick={() => setShowOrderImages(true)}>
                        <i className="fa fa-image"></i> Drawings
                      </Button>
                    </Col>
                  )}
                  {!id && (
                    <Col>
                      <Button color="success" size="md" onClick={handleSaveOrder} disabled={!isFormValid}>
                        <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Create"}
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </>
        )}
      </div>
      {showOrderImages && (
        <ImagesModal
          title="Drawings"
          onClose={() => setShowOrderImages(false)}
          onList={() => getOrderImages(id)}
          onDelete={(imageId) => deleteOrderImage(id, imageId)}
          onUpload={handleAddImage}
        />
      )}
      {confirmState.open && (
        <SweetAlert
          title={confirmState.title}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={confirmState.onConfirm}
          onCancel={() => {
            setConfirmState({ open: false });
          }}
        ></SweetAlert>
      )}
    </React.Fragment>
  );
};

Order.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Order);
