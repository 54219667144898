import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";
import {
  createReportDefinition,
  deleteReportDefinition,
  editReportDefinition,
  getReportDefinitions,
} from "store/definitions/services";
import DateTimeOutput from "components/Common/DateTimeOutput";

export const reportDefinitionFormValues = [
  { name: "name", label: "Name", size: 12, type: "text" },
  { name: "description", label: "Description", size: 12, type: "textarea" },
  { name: "url", label: "URL", size: 12, type: "text" },
  { name: "status", label: "Status", size: 12, type: "checkbox" },
];
const ReportDefinitions = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
      formatter: (cell, row) => <DateTimeOutput date={cell} />,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Report Definitions</title>
        </MetaTags>
        <DefinitionTable
          hasStatus
          services={{
            getapi: getReportDefinitions,
            deleteapi: deleteReportDefinition,
            createapi: createReportDefinition,
            updateapi: editReportDefinition,
          }}
          tableColumns={columns}
          formValues={{
            values: reportDefinitionFormValues,
            validationSchema: {
              name: Yup.string().required("Name is required"),
              status: Yup.string(),
              url: Yup.string().required("URL is required"),
            },
          }}
          pageDetails={{
            definitionName: "Report Definition",
            title: "Report Definition List",
          }}
          privilege={{
            create: Privileges.REPORT_DEFINITION.CREATE,
            update: Privileges.REPORT_DEFINITION.UPDATE,
            delete: Privileges.REPORT_DEFINITION.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ReportDefinitions);
