import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { useActiveUser } from "hooks";
import { useClientPaging } from "hooks/usePaging";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "../../assets/scss/datatables.scss";
import { atom, useRecoilState } from "recoil";
import AddressSelectInput from "components/Common/AddressSelectInput";
import ConfirmButton from "components/Common/ConfirmButton";

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  return cell === "y" ? (
    <i className="fas fa-check-circle text-success"></i>
  ) : (
    <i className="fas fa-times-circle text-danger"></i>
  );
}

const DefinitionTable = ({
  services = {
    getapi: () => Promise(),
    deleteapi: null,
    updateapi: null,
    createapi: null,
    confirmUpdate: null,
  },
  tableColumns = [],
  hasStatus = false,
  pageDetails = { title: "", definitionName: "" },
  formValues = {
    values: [{ name: "", label: "", type: "", size: "" }],
    validationSchema: {},
  },
  privilege = {
    create: null,
    update: null,
    delete: null,
  },
  reloadKey,
  search = true,
  addressCallback,
}) => {
  const activeUser = useActiveUser();
  const [itemsList, setItemsList] = useState({
    loading: false,
    content: [],
    page: {},
  });
  const [pageOptions] = useClientPaging(pageDetails.definitionName, itemsList.content.length);
  const confirmDeleteInitialValues = {
    itemId: "",
    open: false,
    title: "",
    description: "",
    confirmed: false,
    success: false,
    loading: false,
  };
  const [confirmDelete, setConfirmDelete] = useState(confirmDeleteInitialValues);

  const handleGetItems = () => {
    setItemsList((prev) => ({ ...prev, loading: true, page: {} }));
    services
      .getapi({ page: 0 })
      .then((res) => {
        setItemsList({ loading: false, content: res });
      })
      .catch((e) => {
        setItemsList({ loading: false, content: [], page: {} });
      });
  };

  const handleDeleteConfirmation = (id, name) => {
    setConfirmDelete({
      itemId: id,
      open: true,
      title: `Are you sure you want to delete \"${name}\"`,
      description: "",
      confirmed: false,
      success: false,
      loading: false,
    });
  };

  const deleteItem = (id) => {
    // call
    setConfirmDelete({
      itemId: id,
      open: false,
      title: "",
      description: "",
      confirmed: true,
      success: false,
      loading: true,
    });
    services.deleteapi(id).then((res) => {
      // service
      setConfirmDelete({
        itemId: id,
        open: false,
        title: "Deleted.",
        description: "",
        confirmed: true,
        success: true,
        loading: false,
      });
    });
  };

  useEffect(() => {
    handleGetItems();
  }, [reloadKey]);

  const formColums = [...tableColumns];
  if (hasStatus) {
    formColums.push({
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
    });
  }

  const columns = [
    ...formColums,
    {
      dataField: "edit",
      text: "Edit",
      align: "center",
      headerAlign: "center",
      hidden: !services.updateapi || (privilege.update && !activeUser.hasPrivilege(privilege.update)),
      formatter: (cellContent, row) => {
        return (
          <CreateUpdateModal
            isEdit
            details={row}
            callback={handleGetItems}
            updateapi={services.updateapi}
            createapi={services.createapi}
            confirmUpdate={services.confirmUpdate}
            pageDetails={pageDetails}
            formValues={formValues}
            addressCallback={addressCallback}
          />
        );
      },
    },
    {
      dataField: "remove",
      text: "Delete",
      align: "center",
      headerAlign: "center",
      hidden: !services.deleteapi || (privilege.delete && !activeUser.hasPrivilege(privilege.delete)),
      formatter: (cellContent, row) => {
        return (
          <Button color="danger" size="sm" onClick={() => handleDeleteConfirmation(row.id, row.name)}>
            <i className="fa fa-trash"></i>
          </Button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  return (
    <Container fluid>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">
                <div className="d-flex justify-content-between">
                  <h4>{pageDetails.title}</h4> {/* degistir */}
                  {services.createapi && (!privilege.create || activeUser.hasPrivilege(privilege.create)) && (
                    <div>
                      <CreateUpdateModal
                        callback={handleGetItems}
                        updateapi={services.updateapi}
                        createapi={services.createapi}
                        pageDetails={pageDetails}
                        formValues={formValues}
                        addressCallback={addressCallback}
                      />
                    </div>
                  )}
                </div>
              </CardTitle>

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={itemsList.content}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider keyField="id" columns={columns} data={itemsList.content} search={search}>
                    {(toolkitProps) => (
                      <React.Fragment>
                        {search && (
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <CustomSearchBar
                                    {...toolkitProps.searchProps}
                                    pageName={pageDetails.title}
                                    tableContent={itemsList.content}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col xl="12">
                            {/* <div className="table-responsive"> */}
                            <BootstrapTable
                              hover
                              keyField={"id"}
                              responsive
                              bordered={true}
                              striped={false}
                              defaultSorted={defaultSorted}
                              // selectRow={selectRow}
                              classes={`table align-middle table-nowrap${itemsList.loading ? " loading" : ""}`}
                              noDataIndication={() => (itemsList.loading ? <div>&nbsp;</div> : "No data found")}
                              headerWrapperClasses={"thead-light"}
                              wrapperClasses="table-responsive"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                            {/* </div> */}
                          </Col>
                        </Row>

                        {/* <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <SizePerPageDropdownStandalone {...paginationProps} />
                          </Col>
                          <Col className="inner-custom-pagination d-flex">
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row> */}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {confirmDelete.open ? (
        <SweetAlert
          title={confirmDelete.title}
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteItem(confirmDelete.itemId);
          }}
          onCancel={() => setConfirmDelete(confirmDeleteInitialValues)}
        >
          {!confirmDelete.confirmed && "You won't be able to revert this!"}
        </SweetAlert>
      ) : null}
      {confirmDelete.confirmed && confirmDelete.success ? (
        <SweetAlert
          disabled={confirmDelete.loading}
          success
          title={"Successfully deleted."}
          onConfirm={() => {
            setConfirmDelete(confirmDeleteInitialValues);
            handleGetItems();
          }}
        >
          {confirmDelete.loading && <Spinner className="ms-2" color="primary" />}
        </SweetAlert>
      ) : null}
    </Container>
  );
};

const CreateUpdateModal = ({
  details,
  isEdit = false,
  callback,
  createapi,
  updateapi,
  formValues = {
    values: [{ name: "", label: "", type: "", size: "", isMulti: false, visible: null, labelField: "name" }],
    validationSchema: {},
  },
  pageDetails,
  addressCallback,
  confirmUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen((prev) => !prev);
  const [formData, setFormData] = useState({
    loading: false,
    success: null,
    error: null,
  });
  const initialValues = formValues.values.reduce((acc, curr) => {
    const defaultVal = curr.isMulti ? curr.initialValue || [] : curr.initialValue || "";
    if (curr.name === "status") {
      acc[curr.name] = isEdit ? details[curr.name] : "y";
    } else {
      if (curr.getValue == null) {
        acc[curr.name] = isEdit ? details[curr.name] : defaultVal;
      } else {
        acc[curr.name] = isEdit ? curr.getValue(details) : defaultVal;
      }
    }
    return acc;
  }, {});
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object(formValues.validationSchema),
    onSubmit: (values) => {
      setFormData({ loading: true, success: null, error: null });
      if (isEdit) {
        // console.log(validation.values, "val");
        // console.log(validation.values, "----");

        updateapi(details.id, values) // service
          .then((res) => {
            setFormData({ loading: false, success: true, error: null });
            toggleModal();
            callback && callback();
          })
          .catch((err) => {
            console.log(err, "errorr");
            setFormData({
              loading: false,
              success: false,
              error: err.response.data.message,
            });
          });
      } else {
        createapi(values) // service
          .then((res) => {
            setFormData({ loading: false, success: true, error: null });
            validation.resetForm();
            toggleModal();
            callback && callback();
          })
          .catch((err) => {
            setFormData({
              loading: false,
              success: false,
              error: err.response.data.message,
            });
          });
      }
    },
  });

  const handleSubmit = () => {
    validation.handleSubmit();
  };

  function defaultCheckedContact(name) {
    if (name === "contactEmail" && details?.contactEmail) {
      return true;
    }

    if (name === "contactMail" && details?.contactMail) {
      return true;
    }

    if (name === "contactPhone" && details?.contactPhone) {
      return true;
    }

    return false;
  }
  // console.log("validation.errors", validation.errors);
  return (
    <Fragment>
      {pageDetails.definitionName != "CurrencyRate" && (
        <Button color={isEdit ? "warning" : "primary"} size={isEdit ? "sm" : "md"} onClick={toggleModal}>
          {isEdit ? (
            <i className="fa fa-edit"></i>
          ) : (
            <Fragment>
              <i className="fa fa-plus"></i> New {pageDetails.definitionName} {/* degistir */}
            </Fragment>
          )}
        </Button>
      )}

      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {isEdit ? `Edit ${pageDetails.definitionName}` : `Create New ${pageDetails.definitionName}`}{" "}
          </h5>
          <Button onClick={toggleModal} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="p-2">
            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              {formData.error ? <Alert color="danger">{formData.error}</Alert> : null}
              <Row>
                {addressCallback && (
                  <Col sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">Search Address</Label>
                      <AddressSelectInput
                        onChange={(address) => {
                          addressCallback({ setFieldValue: validation.setFieldValue, address });
                        }}
                      ></AddressSelectInput>
                    </div>
                  </Col>
                )}
                {formValues.values.map((i) => {
                  const key = i.name;
                  const visible = i.visible == null || i.visible(validation.values);
                  return !visible ? (
                    <Col sm={i.size} key={key}></Col>
                  ) : i.name === "status" ? (
                    <Col sm={12} key={key}>
                      <StatusCheckBox validation={validation} />
                    </Col>
                  ) : i.type === "select" ? (
                    <Col sm={i.size} key={key}>
                      <div className="mb-3">
                        <Label className="form-label">{i.label}</Label>
                        <MultiselectWithService
                          service={i.options ? () => Promise.resolve(i.options) : i.getService}
                          name={i.name}
                          value={validation.values[i.name]}
                          isMulti={i.isMulti}
                          setValue={validation.setFieldValue}
                          labelField={i.labelField || "name"}
                        />
                        {validation.touched[i.name] && validation.errors[i.name] && (
                          <div className="invalid-feedback" style={{ display: "block" }}>
                            {validation.errors[i.name]}
                          </div>
                        )}
                      </div>
                    </Col>
                  ) : i.type === "datetime" ? (
                    <Col sm={i.size} key={key}>
                      <div className="mb-3">
                        <Label className="form-label">{i.label}</Label>
                        <ReactDatePicker
                          showYearDropdown
                          showMonthDropdown
                          dateFormat="yyyy-MM-dd"
                          isClearable
                          dropdownMode="select"
                          selected={
                            validation.values[i.name]
                              ? moment(validation.values[i.name], "YYYY-MM-DD").isValid() &&
                                moment(validation.values[i.name], "YYYY-MM-DD").toDate()
                              : validation.values[i.name]
                          }
                          placeholderText={`Select ${i.label}`}
                          className="form-control"
                          onChange={(date) => {
                            validation.setFieldValue &&
                              validation.setFieldValue(i.name, date ? moment(date).format("YYYY-MM-DD") : null);
                          }}
                        />
                        {validation.touched[i.name] && validation.errors[i.name] && (
                          <div className="invalid-feedback" style={{ display: "block" }}>
                            {validation.errors[i.name]}
                          </div>
                        )}
                      </div>
                    </Col>
                  ) : (
                    <Col sm={i.size} key={key}>
                      <div className="mb-3">
                        <Label className="form-label">{i.label}</Label>
                        <Input
                          name={i.name}
                          className="form-control"
                          placeholder={i.label}
                          type={i.type}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values[i.name] || ""}
                          invalid={validation.touched[i.name] && validation.errors[i.name] ? true : false}
                          defaultChecked={
                            defaultCheckedContact(i.name) || (i.type == "checkbox" && validation.values[i.name] == true)
                          }
                        />
                        {validation.touched[i.name] && validation.errors[i.name] ? (
                          <FormFeedback type="invalid">{validation.errors[i.name]}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Form>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={toggleModal} color="secondary" data-dismiss="modal">
            Close
          </Button>
          {confirmUpdate && (
            <ConfirmButton onConfirm={handleSubmit} title={confirmUpdate} color="success" disabled={formData.loading}>
              {formData.loading ? <Spinner color="white" size="sm" /> : "Save"}
            </ConfirmButton>
          )}
          {!confirmUpdate && (
            <Button onClick={handleSubmit} color="success" disabled={formData.loading}>
              {formData.loading ? <Spinner color="white" size="sm" /> : "Save"}
            </Button>
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

export const StatusCheckBox = ({ validation }) => {
  const handleChange = (e) => {
    validation.setFieldValue("status", e.target.checked ? "y" : "n");
  };
  return (
    <div className="form-check form-switch mb-3">
      <input
        type="checkbox"
        className="form-check-input"
        id="formStatusSwitch"
        onChange={handleChange}
        // checked={validation.values.status === "y"}
        defaultChecked={validation.values.status === "y"}
      />
      <label className="form-check-label" htmlFor="formStatusSwitch">
        Status Active
      </label>
    </div>
  );
};

const searchInputState = atom({
  key: "DefinitionTable.searchText",
  default: {},
});

const CustomSearchBar = (props) => {
  const { onSearch, pageName, tableContent } = props;
  const [searchText, setSearchText] = useRecoilState(searchInputState);
  useEffect(() => {
    if (searchText[pageName]) {
      search(searchText[pageName]);
    }
  }, [tableContent]);

  const handleChange = (e) => {
    search(e.target.value);
  };
  const search = (txt) => {
    setSearchText((prev) => ({ ...prev, [pageName]: txt }));
    setTimeout(() => {
      onSearch(txt);
    }, 10);
  };
  return (
    <>
      <input
        className="form-control"
        onChange={handleChange}
        type="text"
        placeholder="Search"
        value={searchText[pageName] || ""}
      />
      <i className="bx bx-search-alt search-icon" />
    </>
  );
};

export default DefinitionTable;
