import enums, { getEnumLabel } from "common/enums";
import VariantCard from "pages/Product/VariantCard";
import { Card, CardBody, Col, Row } from "reactstrap";

const OrderedProductCard = ({ orderedProduct }) => {
  return (
    <Row>
      <Col md={12}>
        <Card className="shadow-lg">
          <CardBody>
            <Row className="py-1">
              <h6 className="col-sm-2">Order Number</h6>
              <Col sm={4}>{orderedProduct.order?.orderId}</Col>
              <h6 className="col-sm-2">Product Status</h6>
              <Col sm={4}>{getEnumLabel(enums.orderedProductStatus, orderedProduct.productStatus)}</Col>
            </Row>
            <VariantCard variant={orderedProduct.productVariant} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default OrderedProductCard;
