import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import {
  createAssProblemTypes,
  deleteAssProblemTypes,
  editAssProblemTypes,
  getAssProblemTypes,
} from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

const AssProblemTypes = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>After-Sales Service Problem Types</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getAssProblemTypes,
            deleteapi: deleteAssProblemTypes,
            createapi: createAssProblemTypes,
            updateapi: editAssProblemTypes,
          }}
          tableColumns={columns}
          formValues={{
            values: [
              { name: "name", label: "Name", size: 12, type: "text" },
              { name: "description", label: "Description", size: 12, type: "textarea" },
              { name: "status", label: "status", size: 12, type: "checkbox" },
            ],
            validationSchema: {
              name: Yup.string().required("Name is required"),
              description: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "ASS Problem Type",
            title: "ASS Problem Types",
          }}
          privilege={{
            create: Privileges.ASS_PROBLEM_TYPE.CREATE,
            update: Privileges.ASS_PROBLEM_TYPE.UPDATE,
            delete: Privileges.ASS_PROBLEM_TYPE.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(AssProblemTypes);
