import CustomSpinner from "components/Common/CustomSpinner";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Breadcrumb, BreadcrumbItem, Card, CardTitle, Container } from "reactstrap";
import { getReportDefinition } from "store/definitions/services";

const initialLoadState = { loading: false, error: null, loaded: false };
export default function Report({ id }) {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [report, setReport] = useState();
  useEffect(() => {
    if (id) {
      loadReport(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, [id]);
  const loadReport = async (reportId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const report = await getReportDefinition(reportId);
      setReport(report);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };
  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.loading && <CustomSpinner />}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Report could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <h4 className="text-primary">
            <Breadcrumb listClassName="p-0">
              <BreadcrumbItem>
                <Link to="/reports">Reports</Link>
              </BreadcrumbItem>
              {report && <BreadcrumbItem active>{report.name}</BreadcrumbItem>}
            </Breadcrumb>
          </h4>
        )}
        {loadState.loaded && report && (
          <tableau-viz
            id="tableau-viz"
            src={report.url}
            width="100%"
            height="calc(100vh - 220px)"
            hide-tabs
            toolbar="bottom"
          ></tableau-viz>
        )}
      </div>
    </React.Fragment>
  );
}
