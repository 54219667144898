import { EnumValues } from "common/enums";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";
import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OrderCard from "./OrderCard";
import OrderDocuments from "./OrderDocuments";
import OrderFinances from "./OrderFinances";
import OrderNotes from "./OrderNotes";
import OrderPayments from "./OrderPayments";
import OrderProducts from "./OrderProducts";

const OrderDetailCard = ({ id }) => {
  const [hide, setHide] = useState(false);
  const handleOrderChange = () => {
    setHide(true);
    setTimeout(() => {
      setHide(false);
    }, 10);
  };
  return (
    <Row>
      <Col>
        {!hide && <OrderCard id={id} />}
        <Card className="shadow-lg">
          <CardBody className="">
            <OrderProducts orderId={id} type="view" onChange={handleOrderChange} />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <OrderPayments orderId={id} onChange={handleOrderChange} />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <OrderFinances orderId={id} onChange={handleOrderChange} />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <OrderNotes orderId={id} />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <OrderDocuments orderId={id} />
          </CardBody>
        </Card>
        <ActionLogsCard
          title="Order/Proforma History"
          recordId={id}
          actionModels={[EnumValues.ACTION_MODEL.ORDER, EnumValues.ACTION_MODEL.PROFORMA]}
          parentActions={[EnumValues.PARENT_ACTION_TYPE.ORDER, EnumValues.PARENT_ACTION_TYPE.PROFORMA]}
        />
      </Col>
    </Row>
  );
};

export default OrderDetailCard;
