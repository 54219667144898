import ImagesModal from "components/Common/ImagesModal";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Button, Card, CardBody, Col, Row, UncontrolledCollapse, UncontrolledTooltip } from "reactstrap";
import { addVariantImage, deleteVariantImage, getVariantImages } from "store/product/services";
import ConfirmButton from "../../components/Common/ConfirmButton";
import productUtils from "./product_utils";
import variantUtils from "./variant_utils";

const anonymousFunc = () => {};
const emptyArray = [];
let autoFocusItem = null;

const Variants = (props) => {
  const activeUser = useActiveUser();
  const { items = emptyArray, productId, onDelete, setValues } = props;
  let filteredResults = items;
  const [batchUpdateForm, setBatchUpdateForm] = useState({
    cost: null,
    costCurrency: "GBP",
    price: null,
    priceCurrency: "GBP",
    salesPrice: null,
    salesPriceCurrency: "GBP",
  });
  const [columns, setColumns] = useState([]);
  const currenciesObj = useSelector((state) => state.definitionsReducers.currencies);
  const currencies = currenciesObj.map((item) => ({ label: item.code, value: item.code }));
  const [imagesState, setImagesState] = useState({
    show: false,
    variantId: null,
  });

  const handleShowImages = (id) => {
    setImagesState({
      show: true,
      variantId: id,
    });
  };
  const handleCloseImages = () => {
    setImagesState({
      show: false,
      variantId: null,
    });
  };
  const handleApplyCost = () => {
    const indexList = filteredResults.map((fitem) => items.findIndex((item) => item === fitem));
    setValues(indexList, "cost", batchUpdateForm.cost);
    setValues(indexList, "costCurrency", batchUpdateForm.costCurrency);
  };
  const handleApplyPrice = () => {
    const indexList = filteredResults.map((fitem) => items.findIndex((item) => item === fitem));
    setValues(indexList, "price", batchUpdateForm.price);
    setValues(indexList, "priceCurrency", batchUpdateForm.priceCurrency);
  };
  const handleApplySalesPrice = () => {
    const indexList = filteredResults.map((fitem) => items.findIndex((item) => item === fitem));
    setValues(indexList, "salesPrice", batchUpdateForm.salesPrice);
    setValues(indexList, "salesPriceCurrency", batchUpdateForm.salesPriceCurrency);
  };

  const createColumns = () => {
    const variantHeaders = variantUtils.createHeaders(items);
    const clmns = variantHeaders.map((item) => ({
      text: item.text,
      dataField: item.dataField + ".label",
      filter: items.length == 0 ? undefined : textFilter(),
      // formatter: (cell, variant) => {
      //   console.log("formatter", cell);
      //   return cell;
      // },
    }));
    setColumns([
      ...createBaseColumns({
        ...props,
        showImages: handleShowImages,
        currencies,
        activeUser,
        items,
      }),
      ...clmns,
      {
        dataField: "id",
        text: "Delete",
        hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.DELETE_VARIANT),
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => onDelete(row)}
              title="Are you sure you want to delete this variant?"
            >
              <i className="fa fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ]);
  };
  useEffect(() => {
    createColumns();
  }, [items, currenciesObj]);
  return (
    <>
      <h4 className="text-secondary">
        <b>Product Variants</b>
      </h4>
      <br />
      {items.length > 0 && columns.length > 0 && (
        <>
          <BootstrapTable
            keyField="key"
            data={items}
            columns={columns}
            pagination={paginationFactory({
              showTotal: true,
            })}
            wrapperClasses="table-responsive"
            hover
            filter={filterFactory({
              afterFilter: (newResult) => {
                filteredResults = newResult;
              },
            })}
          />
          <div
            style={{
              textAlign: "right",
            }}
            className="py-3"
          >
            <Button
              color="primary"
              outline
              id="toggler"
              onClick={() => {
                setTimeout(() => {
                  document.getElementById("bottomArea")?.scrollIntoView();
                }, 300);
              }}
            >
              Variant Batch Update
            </Button>
          </div>
          <UncontrolledCollapse toggler="#toggler">
            <Card className="shadow-lg">
              <CardBody>
                <h4 className="text-secondary">
                  <b>Variant Batch Update</b>
                </h4>
                <div className="px-4 py-2">
                  <Row style={{ minHeight: 150 }}>
                    <Col lg={4}>
                      <h6>Cost</h6>
                      <Row style={{ width: 350 }}>
                        <Col style={{ width: 130 }}>
                          <NumberFormat
                            value={batchUpdateForm.cost}
                            style={{ width: 125 }}
                            className="form-control"
                            placeholder="only numbers.."
                            allowNegative={false}
                            name="cost"
                            onValueChange={({ value }) => setBatchUpdateForm({ ...batchUpdateForm, cost: value })}
                            decimalScale={2}
                          />
                        </Col>
                        <Col style={{ width: 125 }}>
                          <Select
                            isMulti={false}
                            style={{ width: 125 }}
                            onChange={(val) => {
                              setBatchUpdateForm({ ...batchUpdateForm, costCurrency: val.value });
                            }}
                            value={currencies.find((item) => batchUpdateForm.costCurrency == item.value)}
                            options={currencies}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect
                          />
                        </Col>
                        <Col style={{ width: 75 }}>
                          <Button color="primary" outline onClick={handleApplyCost}>
                            Apply
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <h6>Price</h6>
                      <Row style={{ width: 350 }}>
                        <Col style={{ width: 130 }}>
                          <NumberFormat
                            value={batchUpdateForm.price}
                            style={{ width: 125 }}
                            className="form-control"
                            placeholder="only numbers.."
                            allowNegative={false}
                            name="price"
                            onValueChange={({ value }) => setBatchUpdateForm({ ...batchUpdateForm, price: value })}
                            decimalScale={2}
                          />
                        </Col>
                        <Col style={{ width: 125 }}>
                          <Select
                            isMulti={false}
                            style={{ width: 125 }}
                            onChange={(val) => {
                              setBatchUpdateForm({ ...batchUpdateForm, priceCurrency: val.value });
                            }}
                            value={currencies.find((item) => batchUpdateForm.priceCurrency == item.value)}
                            options={currencies}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect
                          />
                        </Col>
                        <Col style={{ width: 75 }}>
                          <Button color="primary" outline onClick={handleApplyPrice}>
                            Apply
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <h6>Sell Price</h6>
                      <Row style={{ width: 350 }}>
                        <Col style={{ width: 130 }}>
                          <NumberFormat
                            value={batchUpdateForm.salesPrice}
                            style={{ width: 125 }}
                            className="form-control"
                            placeholder="only numbers.."
                            allowNegative={false}
                            name="salesPrice"
                            onValueChange={({ value }) => setBatchUpdateForm({ ...batchUpdateForm, salesPrice: value })}
                            decimalScale={2}
                          />
                        </Col>
                        <Col style={{ width: 125 }}>
                          <Select
                            isMulti={false}
                            style={{ width: 125 }}
                            onChange={(val) => {
                              setBatchUpdateForm({ ...batchUpdateForm, salesPriceCurrency: val.value });
                            }}
                            value={currencies.find((item) => batchUpdateForm.salesPriceCurrency == item.value)}
                            options={currencies}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect
                          />
                        </Col>
                        <Col style={{ width: 75 }}>
                          <Button color="primary" outline onClick={handleApplySalesPrice}>
                            Apply
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="text-danger">
                    <strong>Notes:</strong>
                    <li>
                      Changes will be applied to all filtered variants, if no filter is defined changes will be applied
                      to all variants
                    </li>
                    <li>After applying you have to save changes to be effected</li>
                  </div>
                </div>
              </CardBody>
            </Card>
          </UncontrolledCollapse>
        </>
      )}
      {imagesState.show && (
        <ImagesModal
          title="Variant Images"
          onClose={handleCloseImages}
          onDelete={(imageId) => deleteVariantImage(productId, imagesState.variantId, imageId)}
          onList={() => getVariantImages(productId, imagesState.variantId)}
          onUpload={({ file, isDefault, documentType }) => {
            const imageData = new FormData();
            imageData.append("imageFile", file, file.path);
            imageData.append("isDefault", isDefault);
            imageData.append("documentType", documentType ?? "");
            return addVariantImage(productId, imagesState.variantId, imageData);
          }}
        />
      )}
      <div id="bottomArea" />
    </>
  );
};

const createBaseColumns = (props) => {
  const { setValue = anonymousFunc, currencies, showImages = anonymousFunc, activeUser, items } = props;
  return [
    {
      text: "Images",
      dataField: "images",
      formatter: (images, row) => {
        if (!row.id) {
          return null;
        }
        const defImage = productUtils.findDefaultImage(images);
        if (defImage) {
          return (
            <div onClick={() => showImages(row.id)} style={{ cursor: "pointer" }}>
              <img src={defImage.awsUrl} width="75" id={`variant_image_${row.id}`} />
              <UncontrolledTooltip target={`variant_image_${row.id}`}>
                <img src={defImage.awsUrl} width="300" />
              </UncontrolledTooltip>
            </div>
          );
        }
        return (
          <Button color="primary" size="sm" onClick={() => showImages(row.id)}>
            <i className="fa fa-image"></i>
          </Button>
        );
      },
    },
    {
      text: "SKU",
      dataField: "sku",
      formatter: (cell, row) => {
        return `${row.sku ? row.sku : ""}`;
      },
    },
    {
      text: "Cost",
      dataField: "cost",
      formatExtraData: { currencies, items },
      hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.EDIT_COST),
      formatter: (cell, row, xxx, { currencies, items }) => {
        const rowIndex = items.findIndex((item) => item === row);
        return (
          <Row style={{ width: 250 }}>
            <Col style={{ width: 130 }}>
              <NumberFormat
                value={row.cost}
                style={{ width: 125 }}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                name="cost"
                autoFocus={row.key == autoFocusItem}
                onValueChange={({ value }) => {
                  autoFocusItem = row.key;
                  setValue(rowIndex, "cost", value);
                }}
                decimalScale={2}
              />
            </Col>
            <Col style={{ width: 100 }}>
              <Select
                isMulti={false}
                style={{ width: 90 }}
                onChange={(val) => {
                  setValue(rowIndex, "costCurrency", val.value, val);
                }}
                value={currencies.find((item) => row.costCurrency == item.value)}
                options={currencies}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect
              />
            </Col>
          </Row>
        );
      },
    },
    {
      text: "Price",
      dataField: "price",
      formatExtraData: { currencies, items },
      formatter: (cell, row, xxx, { currencies, items }) => {
        const rowIndex = items.findIndex((item) => item === row);
        return (
          <Row style={{ width: 250 }}>
            <Col style={{ width: 130 }}>
              <NumberFormat
                value={row.price}
                style={{ width: 125 }}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                name="price"
                autoFocus={row.key == autoFocusItem}
                onValueChange={({ value }) => {
                  autoFocusItem = row.key;
                  setValue(rowIndex, "price", value);
                }}
                decimalScale={2}
              />
            </Col>
            <Col style={{ width: 100 }}>
              <Select
                isMulti={false}
                style={{ width: 90 }}
                onChange={(val) => {
                  setValue(rowIndex, "priceCurrency", val.value, val);
                }}
                value={currencies.find((item) => row.priceCurrency == item.value)}
                options={currencies}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect
              />
            </Col>
          </Row>
        );
      },
    },
    {
      text: "Sell Price",
      dataField: "salesPrice",
      formatExtraData: { currencies, items },
      formatter: (cell, row, xxx, { currencies, items }) => {
        const rowIndex = items.findIndex((item) => item === row);
        return (
          <Row style={{ width: 250 }}>
            <Col style={{ width: 130 }}>
              <NumberFormat
                value={row.salesPrice}
                style={{ width: 125 }}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                name="salesPrice"
                autoFocus={row.key == autoFocusItem}
                onValueChange={({ value }) => {
                  autoFocusItem = row.key;
                  setValue(rowIndex, "salesPrice", value);
                }}
                decimalScale={2}
              />
            </Col>
            <Col style={{ width: 100 }}>
              <Select
                isMulti={false}
                style={{ width: 90 }}
                onChange={(val) => {
                  setValue(rowIndex, "salesPriceCurrency", val.value, val);
                }}
                value={currencies.find((item) => row.salesPriceCurrency == item.value)}
                options={currencies}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect
              />
            </Col>
          </Row>
        );
      },
    },
  ];
};

export default React.memo(Variants);
/*

*/
