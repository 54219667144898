import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { createPriceTag, deletePriceTag, editPriceTag, getPriceTags } from "store/definitions/services";

// {
//     "id": 1,
//     "status": "y",
//     "name": "genel",
//     "code": "#ccc",
//     "phone": "5516542255",
//     "branchType": "SHOP",
//     "shortName": "g",
//     "brandName": "general",
//     "logoLink": "https://kkfkfk.com",
//     "info": "genel kategori"
//   }

export const priceTagFormValues = [
  { name: "name", label: "Name", size: 12, type: "text" },
  { name: "template", label: "HTML Template", size: 12, type: "textarea" },
  { name: "status", label: "Status", size: 12, type: "checkbox" },
];
const PriceTags = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Price Tag</title>
        </MetaTags>
        <DefinitionTable
          hasStatus
          services={{
            getapi: getPriceTags,
            deleteapi: deletePriceTag,
            createapi: createPriceTag,
            updateapi: editPriceTag,
          }}
          tableColumns={columns}
          formValues={{
            values: priceTagFormValues,
            validationSchema: {
              name: Yup.string().required("Name is required"),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "Price Tag",
            title: "Price Tag List",
          }}
          privilege={{
            create: Privileges.PRICETAG.CREATE,
            update: Privileges.PRICETAG.UPDATE,
            delete: Privileges.PRICETAG.DELETE,
          }}
        />
        <DocumentationCard />
      </div>
    </React.Fragment>
  );
};

function DocumentationCard() {
  return (
    <Container fluid>
      <Card>
        <UncontrolledAccordion>
          <AccordionItem>
            <AccordionHeader targetId="1">
              <Row>
                <h5 className="text-primary">
                  <b>Documentation</b>
                </h5>
              </Row>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <Card className="shadow">
                <CardBody>
                  <Row className="pt-2">
                    <Col sm={2}>
                      <b>Context Attributes</b>
                    </Col>
                    <Col sm={10}>
                      <ul>
                        <li>
                          <b>FINAL_PRICE</b> : Final Price
                        </li>
                        <li>
                          <b>FINAL_PRICE_CURRENCY</b>
                        </li>
                        <li>
                          <b>BASE_PRICE</b> : Base Price
                        </li>
                        <li>
                          <b>BASE_PRICE_CURRENCY</b>
                        </li>
                        <li>
                          <b>DISCOUNTED_PRICE</b> : Base Price - Final Price
                        </li>
                        <li>
                          <b>DISCOUNTED_PRICE_CURRENCY</b>
                        </li>
                        <li>
                          <b>VARIANT_OPTIONS</b> : List Of variant options (attributeName, name)
                        </li>
                        <li>
                          <b>PRODUCT_NAME</b>
                        </li>
                        <li>
                          <b>MATERIAL_NAME</b>
                        </li>
                        <li>
                          <b>COLOR_NAME</b>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <b>Reference Page</b>
                    </Col>
                    <Col sm={10}>
                      <a href="https://www.thymeleaf.org/doc/tutorials/3.0/usingthymeleaf.html" target="_blank">
                        Thymeleaf 3.0
                      </a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </Card>
    </Container>
  );
}

export default withRouter(PriceTags);
