import { useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Label, Row, Spinner } from "reactstrap";

import ValidationForm from "components/ValidationForm";
import { showInfo } from "helpers/utils";
import { useActiveUser } from "hooks";
import NumberFormat from "react-number-format";
import { addPackageToProduct, editProductPackage, getProductPackage } from "store/product/services";
import productUtils from "./product_utils";

const initialLoadState = { loading: false, error: null, loaded: false };

const ProductPackageForm = ({ packageId = null, onSave, onUpdate, productId }) => {
  const activeUser = useActiveUser();
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [productPackage, setProductPackage] = useState(productUtils.initialPackageValues());

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setProductPackage((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setProductPackage((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setProductPackage((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);
  const handleNumberChange = useCallback(({ name, value }) => {
    setProductPackage((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const loadProductPackage = async (packageId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getProductPackage(productId, packageId);
      const prd = productUtils.mapToPackageFormDataModel(mdl);
      setProductPackage(prd);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  //handle productPackage
  const handleSaveProductPackage = async () => {
    setSaving(true);
    try {
      const saveModel = productUtils.mapToPackageSaveModel(productPackage);
      const mdl = await addPackageToProduct(productId, saveModel);
      showInfo("Package added successfully");
      onSave && onSave(mdl);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateProductPackage = async () => {
    setSaving(true);
    try {
      const saveModel = productUtils.mapToPackageSaveModel(productPackage);
      const mdl = await editProductPackage(productId, packageId, saveModel);
      setProductPackage(productUtils.mapToPackageFormDataModel(mdl));
      showInfo("Package updated successfully");
      onUpdate && onUpdate(mdl);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (packageId) {
      loadProductPackage(packageId);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <Row>
      <Col>
        {loadState.loading && <Spinner size="md">Loading...</Spinner>}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Package could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <ValidationForm>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <Row>
                    <Col sm={6} md={4}>
                      <Row>
                        <Label htmlFor="totalAmount" className="col-sm-3 col-form-label">
                          Width (cm)
                        </Label>
                        <Col sm={9}>
                          <NumberFormat
                            value={productPackage.width || ""}
                            name="width"
                            placeholder="Width.."
                            className="form-control"
                            allowNegative={false}
                            onValueChange={({ value }) => handleNumberChange({ value, name: "width" })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6} md={4}>
                      <Row>
                        <Label htmlFor="totalAmount" className="col-sm-3 col-form-label">
                          Height (cm)
                        </Label>
                        <Col sm={9}>
                          <NumberFormat
                            value={productPackage.height || ""}
                            name="height"
                            placeholder="Height.."
                            className="form-control"
                            allowNegative={false}
                            onValueChange={({ value }) => handleNumberChange({ value, name: "height" })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6} md={4}>
                      <Row>
                        <Label htmlFor="totalAmount" className="col-sm-3 col-form-label">
                          Depth (cm)
                        </Label>
                        <Col sm={9}>
                          <NumberFormat
                            value={productPackage.depth || ""}
                            name="depth"
                            placeholder="Depth.."
                            className="form-control"
                            allowNegative={false}
                            onValueChange={({ value }) => handleNumberChange({ value, name: "depth" })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6} md={4}>
                      <Row>
                        <Label htmlFor="totalAmount" className="col-sm-3 col-form-label">
                          Weight (kg)
                        </Label>
                        <Col sm={9}>
                          <NumberFormat
                            value={productPackage.weight || ""}
                            name="weight"
                            placeholder="Weight.."
                            className="form-control"
                            allowNegative={false}
                            onValueChange={({ value }) => handleNumberChange({ value, name: "weight" })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6} md={4}>
                      <Row>
                        <Label htmlFor="totalAmount" className="col-sm-3 col-form-label">
                          Volume (m3)
                        </Label>
                        <Col sm={9}>
                          <NumberFormat
                            value={productPackage.volume || ""}
                            name="volume"
                            placeholder="Volume.."
                            className="form-control"
                            allowNegative={false}
                            onValueChange={({ value }) => handleNumberChange({ value, name: "volume" })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6} md={4}>
                      <Row>
                        <Label htmlFor="totalAmount" className="col-sm-3 col-form-label">
                          Index
                        </Label>
                        <Col sm={9}>
                          <NumberFormat
                            value={productPackage.index || ""}
                            name="index"
                            placeholder="Index.."
                            className="form-control"
                            allowNegative={false}
                            allowDecimals={false}
                            decimalScale={0}
                            onValueChange={({ value }) => handleNumberChange({ value, name: "index" })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    {packageId && (
                      <Col>
                        <Button
                          color="success"
                          size="md"
                          onClick={handleUpdateProductPackage}
                          validate
                          disabled={saving}
                        >
                          <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                        </Button>
                      </Col>
                    )}
                    {!packageId && (
                      <Col>
                        <Button color="success" size="md" onClick={handleSaveProductPackage} validate disabled={saving}>
                          <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              </CardBody>
            </Card>
          </ValidationForm>
        )}
      </Col>
    </Row>
  );
};

export default ProductPackageForm;
