import { useCallback, useEffect, useState } from "react";
import { get } from "helpers/axios_helper";

const cache = new Map();
const fetching = {};

export default function useHttpCache(url, defaltVal) {
  const [data, setData] = useState(defaltVal);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(null);

  const handleFetch = useCallback(async (url) => {
    setLoading(true);
    try {
      const freshData = await get(url);
      cache.set(url, freshData);
      setData(freshData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      fetching[url] = null;
      throw e;
    }
  }, []);

  const handleInit = useCallback(async (url) => {
    const cachedData = cache.get(url);
    if (cachedData != null) {
      setData(cachedData);
      return;
    }
    handleFetch(url);
  }, []);

  useEffect(() => {
    let wait = 0;
    if (fetching[url] == null) {
      fetching[url] = true;
    } else if (cache.get(url) == null) {
      wait = 500;
    }
    if (wait > 0) {
      setTimeout(() => handleInit(url), wait);
    } else {
      handleInit(url);
    }
    return () => {
      setData(defaltVal);
    };
  }, [url, trigger]);

  const handleRefresh = useCallback(() => {
    cache.delete(url);
    fetching[url] = null;
    setTrigger(url + "_" + new Date().getTime());
  }, [url]);

  return { data, loading, refresh: handleRefresh };
}
