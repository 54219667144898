import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createStore, deleteStore, editStore, getStores } from "store/store/services";

import { getWarehouse } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import { UncontrolledTooltip } from "reactstrap";
import Privileges from "models/Privileges";

// {
//     "id": 1,
//     "status": "y",
//     "name": "genel",
//     "code": "#ccc",
//     "phone": "5516542255",
//     "branchType": "SHOP",
//     "shortName": "g",
//     "brandName": "general",
//     "logoLink": "https://kkfkfk.com",
//     "info": "genel kategori"
//   }

export const branchFormValues = [
  { name: "name", label: "Name", size: 6, type: "text" },
  { name: "code", label: "Code", size: 12, type: "text" },
  { name: "phone", label: "Phone", size: 6, type: "text" },

  { name: "shortName", label: "Short Name", size: 6, type: "text" },
  { name: "brandName", label: "Brand Name", size: 6, type: "text" },
  {
    name: "warehouseIds",
    label: "Warehouse",
    size: 12,
    type: "select",
    isMulti: true,
    getService: getWarehouse,
    getValue: (value) => {
      return value.warehouses?.map((item) => item.id);
    },
  },

  { name: "logoLink", label: "Logo Link", size: 6, type: "text" },
  { name: "info", label: "Info", size: 6, type: "text" },
  { name: "status", label: "status", size: 12, type: "checkbox" },
];
const Branches = () => {
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },

    {
      dataField: "shortName",
      text: "Short Name",
      sort: true,
    },
    {
      dataField: "brandName",
      text: "Brand Name",
      sort: true,
    },
    {
      dataField: "warehouseIds",
      text: "Warehouse Name",
      sort: true,
      formatter: (cellContent, row, index) => {
        if (row.warehouses.length == 0) {
          return null;
        }
        if (row.warehouses.length == 1) {
          return row.warehouses[0].name;
        }
        const targetId = `targetProduct${index}`;
        return (
          <div>
            <span
              href="#"
              id={targetId}
              style={{
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {row.warehouses[0].name} ...
            </span>
            <UncontrolledTooltip placement="top" target={targetId}>
              {row.warehouses.map((item) => item.name).join(", ")}
            </UncontrolledTooltip>
          </div>
        );
      },
    },

    {
      dataField: "logoLink",
      text: "Logo Link",
      sort: true,
    },
    {
      dataField: "info",
      text: "Info",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Store</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getStores,
            deleteapi: deleteStore,
            createapi: createStore,
            updateapi: editStore,
          }}
          tableColumns={columns}
          formValues={{
            values: branchFormValues,
            validationSchema: {
              name: Yup.string().required("Name is required"),
              code: Yup.string(),
              phone: Yup.string(),
              shortName: Yup.string(),
              brandName: Yup.string(),
              warehouseIds: Yup.array(),
              logoLink: Yup.string(),
              info: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "Store",
            title: "Store List",
          }}
          privilege={{
            create: Privileges.STORE.CREATE,
            update: Privileges.STORE.UPDATE,
            delete: Privileges.STORE.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Branches);
