import ConfirmButton from "components/Common/ConfirmButton";
import NumberOutput from "components/Common/NumberOutput";
import ValidationForm from "components/ValidationForm";
import { useActiveUser } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { addRate, deleteRate, getSalesTarget } from "store/salesTarget/services";

const initialSalesTargetRateState = { open: false, error: null, loading: false, salesTargetRate: {} };
const SalesTargetRates = ({ salesTargetId }) => {
  const activeUser = useActiveUser();
  const [salesTargetRatePopupState, setSalesTargetRatePopupState] = useState(initialSalesTargetRateState);
  const [salesTargetRates, setSalesTargetRates] = useState([]);
  const [salesTarget, setSalesTarget] = useState(null);
  const openRateAddPopup = () => {
    setSalesTargetRatePopupState((prev) => ({ ...prev, salesTargetRate: {}, open: true }));
  };
  const handleAddRate = async (salesTargetPerson) => {
    return addRate(salesTargetId, salesTargetPerson).then(() => {
      loadSalesTargetRates(salesTargetId);
      setSalesTargetRatePopupState((prev) => ({ ...prev, salesTargetRate: {}, open: false }));
    });
  };
  const handleCloseRatePopup = () => {
    setSalesTargetRatePopupState((prev) => ({ ...prev, open: false }));
  };
  const loadSalesTargetRates = async (salesTargetId) => {
    const salesTarget = await getSalesTarget(salesTargetId);
    setSalesTargetRates(salesTarget.rates);
  };
  const loadSalesTarget = async (salesTargetId) => {
    const salesTarget = await getSalesTarget(salesTargetId);
    setSalesTarget(salesTarget);
  };
  const handleDeleteRate = async ({ id }) => {
    await deleteRate(salesTargetId, id);
    loadSalesTargetRates(salesTargetId);
  };
  useEffect(() => {
    loadSalesTarget(salesTargetId);
    loadSalesTargetRates(salesTargetId);
  }, [salesTargetId]);

  const productColumns = useMemo(
    () => [
      {
        text: "Target",
        dataField: "target",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => <NumberOutput value={cell} />,
      },
      {
        text: "Rate",
        dataField: "rate",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => <NumberOutput value={cell} />,
      },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteRate(row)}
              title="Are you sure you want to delete the target?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    [salesTarget]
  );
  return (
    <>
      <span style={{ float: "right" }}>
        <Button color="primary" size="sm" onClick={openRateAddPopup}>
          <i className="fa fa-plus"></i> Add Store Target
        </Button>
      </span>
      <>
        <h4 className="text-secondary">
          <b>Store Targets</b>
        </h4>
        <br />
        {salesTargetRates.length > 0 && (
          <Row>
            <Col xl="12">
              <div className="table-responsive">
                <BootstrapTable keyField="id" data={salesTargetRates} columns={productColumns} />
              </div>
            </Col>
          </Row>
        )}
      </>

      {salesTargetRatePopupState.open && (
        <SalesTargetRateModal onClose={handleCloseRatePopup} onSubmit={handleAddRate} />
      )}
    </>
  );
};

const SalesTargetRateModal = ({ targetRate, onClose, onSubmit }) => {
  const [salesTargetRate, setSalesTargetRate] = useState({
    target: targetRate?.target,
    rate: targetRate?.rate,
  });
  const [loading, setLoading] = useState(false);
  const handleSetFormValue = useCallback((name, value) => {
    setSalesTargetRate((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit({ ...salesTargetRate });
    } finally {
      setLoading(false);
    }
  };
  return (
    <ValidationForm>
      <Modal isOpen centered={true} size="lg">
        <ModalHeader toggle={onClose}>{!targetRate?.id ? "Add new target" : "Edit target"}</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12}>
              <div className="row mb-4">
                <Label htmlFor="target" className="col-sm-3 col-form-label">
                  Target
                </Label>
                <Col sm={9}>
                  <NumberFormat
                    value={salesTargetRate.target || ""}
                    className="form-control"
                    placeholder="only numbers.."
                    allowNegative={false}
                    name="target"
                    onValueChange={({ value }) => {
                      handleSetFormValue("target", value);
                    }}
                    validations={["required"]}
                    validationeventname="onValueChange"
                  />
                </Col>
              </div>
            </Col>
            <Col sm={12}>
              <div className="row mb-4">
                <Label htmlFor="rate" className="col-sm-3 col-form-label">
                  Rate
                </Label>
                <Col sm={9}>
                  <NumberFormat
                    value={salesTargetRate.rate || ""}
                    className="form-control"
                    placeholder="only numbers.."
                    allowNegative={false}
                    name="rate"
                    onValueChange={({ value }) => {
                      handleSetFormValue("rate", value);
                    }}
                    validations={["required"]}
                    validationeventname="onValueChange"
                  />
                </Col>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSubmitForm} color="primary" disabled={loading} validate>
            Add target
          </Button>
        </ModalFooter>
      </Modal>
    </ValidationForm>
  );
};

export default SalesTargetRates;
