import enums, { EnumValues, getEnumLabel } from "common/enums";
import DateTimeOutput from "components/Common/DateTimeOutput";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { showInfo } from "helpers/utils";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Col, Label, Row, Spinner } from "reactstrap";
import { getTransferStatus } from "store/definitions/services";
import { getBranches } from "store/store/services";
import { completeTransfer, createTransfer, editTransfer, getTransfer } from "store/transfer/services";
import TransferDocuments from "./TransferDocuments";
import TransferProducts from "./TransferProducts";
import transferUtils from "./transfer_utils";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";

const initialLoadState = { loading: false, error: null, loaded: false };

const Transfer = ({ id, history }) => {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [transfer, setTransfer] = useState(transferUtils.initialValues());
  const [transferView, setTransferView] = useState();
  const [saving, setSaving] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [reloadProducts, setReloadProducts] = useState();
  const [confirmState, setConfirmState] = useState({
    open: false,
    onConfirm: null,
    title: "",
  });

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setTransfer((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setTransfer((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const loadTransfer = async (transferId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getTransfer(transferId);
      const prd = transferUtils.mapToFormDataModel(mdl);
      setTransfer(prd);
      setTransferView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };
  const validateTransfer = () => {
    if (!transfer.senderLocation?.value) {
      toast.error("Sender location is required");
      return false;
    }
    if (!transfer.arrivalLocation?.value) {
      toast.error("Arrical location is required");
      return false;
    }
    if (transfer.senderLocation.value == transfer.arrivalLocation.value) {
      toast.error("Sender location cant be same as arrival location");
      return false;
    }
    return true;
  };
  const handleSaveTransfer = async () => {
    if (!validateTransfer()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = transferUtils.mapToSaveModel(transfer);
      const mdl = await createTransfer(saveModel);
      showInfo("Transfer created successfully");
      history.push(`/transfer/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateTransfer = async () => {
    if (!validateTransfer()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = transferUtils.mapToSaveModel(transfer);
      const mdl = await editTransfer(id, saveModel);
      setTransfer(transferUtils.mapToFormDataModel(mdl));
      setTransferView(mdl);
      setReloadProducts(new Date());
      showInfo("Transfer updated successfully");
    } finally {
      setSaving(false);
    }
  };
  const handleCancelTransfer = async () => {
    async function confirmCancel() {
      try {
        const saveModel = transferUtils.mapToSaveModel({
          ...transfer,
          transferStatus: { value: EnumValues.TRANSFER_STATUS.CANCELLED },
        });
        const mdl = await editTransfer(id, saveModel);
        setTransfer(transferUtils.mapToFormDataModel(mdl));
        setTransferView(mdl);
        setReloadProducts(new Date());
        showInfo("Transfer cancelled successfully");
      } finally {
        setLoadingCancel(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to CANCEL the transfer?",
      onConfirm: confirmCancel,
    });
  };
  const handleCompleteTransfer = async () => {
    async function confirmComplete() {
      setLoadingComplete(true);
      try {
        const mdl = await completeTransfer(id);
        setTransfer(transferUtils.mapToFormDataModel(mdl));
        setTransferView(mdl);
        setReloadProducts(new Date());
        showInfo("Transfer completed successfully");
      } finally {
        setLoadingComplete(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to COMPLETE the transfer?",
      onConfirm: confirmComplete,
    });
  };
  useEffect(() => {
    if (id) {
      loadTransfer(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);
  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Transfer could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <h4 className="text-primary">
            <Breadcrumb listClassName="p-0">
              <BreadcrumbItem>
                <Link to="/transfers">Transfers</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {id == null && <b>New Transfer</b>}
                {id != null && <b>Edit Transfer</b>}
              </BreadcrumbItem>
            </Breadcrumb>
          </h4>
        )}
        {transferView && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Transfer Number</h6>
                <Col sm={4}>
                  <h5>{transferView.transferNumber}</h5>
                </Col>
                <h6 className="col-sm-2">Transfer Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.transferStatus, transferView.transferStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by </b>
                  {transferView.createUser}
                  <b> at </b>
                  <DateTimeOutput date={transferView.createdDate} />
                </Col>
                <h6 className="col-sm-2">Last Modified At</h6>
                <Col sm={4}>
                  <DateTimeOutput date={transferView.updateDate} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <>
            <Card>
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="senderLocation" className="col-sm-3 col-form-label">
                      Sender Location
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getBranches}
                        name="senderLocation"
                        value={transfer.senderLocation.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="arrivalLocation" className="col-sm-3 col-form-label">
                      Arrival Location
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getBranches}
                        name="arrivalLocation"
                        value={transfer.arrivalLocation.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                      />
                    </Col>
                  </div>
                  {/* <div className="row mb-4">
                    <Label htmlFor="vehiclePlateNo" className="col-sm-3 col-form-label">
                      Vehicle Plate No
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        value={transfer.vehiclePlateNo}
                        name="vehiclePlateNo"
                        placeholder="Vehicle Plate No.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div> */}
                  {/* <div className="row mb-4">
                    <Label htmlFor="receiverUser" className="col-sm-3 col-form-label">
                      Receiver User
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getUserListContent}
                        name="receiverUser"
                        value={transfer.receiverUser.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        labelField="fullName"
                      />
                    </Col>
                  </div> */}
                  {/* <div className="row mb-4">
                    <Label htmlFor="senderUser" className="col-sm-3 col-form-label">
                      Sender User
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getUserListContent}
                        name="senderUser"
                        value={transfer.senderUser.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        labelField="fullName"
                      />
                    </Col>
                  </div> */}
                </div>
              </CardBody>
            </Card>
            {(!id || transferView?.transferStatus == EnumValues.TRANSFER_STATUS.ACTIVE) && (
              <Card className="shadow-lg nv-sticky-card mt-2">
                <CardBody className="">
                  <Row>
                    {id && (
                      <Col className="d-flex gap-2">
                        <Button color="success" size="md" onClick={handleUpdateTransfer} disabled={saving}>
                          <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                        </Button>
                        {transferView.transferStatus == EnumValues.TRANSFER_STATUS.ACTIVE && (
                          <>
                            <Button color="danger" size="md" onClick={handleCancelTransfer} disabled={loadingCancel}>
                              {loadingCancel ? <Spinner color="white" size="sm" /> : "Cancel"}
                            </Button>
                            <Button
                              color="success"
                              size="md"
                              onClick={handleCompleteTransfer}
                              disabled={loadingComplete}
                            >
                              {loadingComplete ? <Spinner color="white" size="sm" /> : "Complete Transfer"}
                            </Button>
                          </>
                        )}
                      </Col>
                    )}
                    {!id && (
                      <Col>
                        <Button color="success" size="md" onClick={handleSaveTransfer} disabled={saving}>
                          <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            )}
            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <TransferProducts transferId={id} reloadKey={reloadProducts} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <TransferDocuments transferId={id} />
                  </CardBody>
                </Card>
              </>
            )}
          </>
        )}
      </div>
      {confirmState.open && (
        <SweetAlert
          title={confirmState.title}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmState({ open: false });
            confirmState.onConfirm();
          }}
          onCancel={() => {
            setConfirmState({ open: false });
          }}
        ></SweetAlert>
      )}
    </React.Fragment>
  );
};

Transfer.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Transfer);
