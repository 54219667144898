import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import SalesTarget from "./SalesTarget";

const SalesTargetPage = (props) => {
  const { id } = useParams();
  return (
    <>
      <MetaTags>
        <title>Novia - Sales Target</title>
      </MetaTags>
      <SalesTarget id={id} />
    </>
  );
};

SalesTargetPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SalesTargetPage);
