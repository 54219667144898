import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useState } from "react";
import NumberFormat from "react-number-format";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import {
  getActionLogModels,
  getActionLogTypes,
  getParentActionLogTypes,
  getUserListContent,
} from "store/definitions/services";

const ActionLogFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="parentAction" className="col-sm-3 col-form-label">
                Parent Resource
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getParentActionLogTypes}
                  name="parentAction"
                  value={filter.parentAction}
                  isMulti={false}
                  id="parentAction"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="parentRecordId" className="col-sm-3 col-form-label">
                Parent Record ID
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.parentRecordId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="parentRecordId"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("parentRecordId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="actionTypes" className="col-sm-3 col-form-label">
                Action
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getActionLogTypes}
                  name="actionTypes"
                  value={filter.actionTypes}
                  isMulti
                  id="actionTypes"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="actionModel" className="col-sm-3 col-form-label">
                Resource
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getActionLogModels}
                  name="actionModel"
                  value={filter.actionModel}
                  isMulti={false}
                  id="actionModel"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="recordId" className="col-sm-3 col-form-label">
                Record ID
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.recordId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="recordId"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("recordId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="name" className="col-sm-3 col-form-label">
                Process
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.process || ""}
                  name="process"
                  placeholder="process.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="actionType" className="col-sm-3 col-form-label">
                User
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getUserListContent}
                  name="userId"
                  value={filter.userId}
                  isMulti={false}
                  id="userId"
                  setValue={handleSetFilterValue}
                  labelField="fullName"
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Order ID
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.orderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="orderId"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("orderId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="proformaId" className="col-sm-3 col-form-label">
                Proforma ID
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.proformaId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="proformaId"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("proformaId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default ActionLogFilterForm;
