import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
  Spinner,
  Modal,
  Input,
  Label,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Yup from "yup";
import { useFormik } from "formik";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "../../assets/scss/datatables.scss";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { findAllByPlaceholderText } from "@testing-library/react";
import DateRange from "components/Common/DateRange";

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  return cell === "y" ? (
    <i className="fas fa-check-circle text-success"></i>
  ) : (
    <i className="fas fa-times-circle text-danger"></i>
  );
}

const ListDefinitionTable = ({
  services = {
    getapi: () => Promise(),
    deleteapi: () => Promise(),
    updateapi: () => Promise(),
    createapi: () => Promise(),
  },
  tableColumns = [],
  hasStatus = true,
  pageDetails = { title: "", definitionName: "" },
  formValues = {
    values: [{ name: "", label: "", type: "", size: "" }],
    validationSchema: {},
  },
}) => {
  const [itemsList, setItemsList] = useState({
    loading: false,
    content: [],
    page: {},
  });
  const confirmDeleteInitialValues = {
    itemId: "",
    open: false,
    title: "",
    description: "",
    confirmed: false,
    success: false,
    loading: false,
  };
  const [confirmDelete, setConfirmDelete] = useState(confirmDeleteInitialValues);

  const handleGetItems = () => {
    setItemsList({ loading: true, content: [], page: {} });
    services.getapi({ page: 0 }).then((res) => {
      setItemsList({ loading: false, content: res });
    });
  };

  useEffect(() => {
    handleGetItems();
  }, []);

  const formColums = [...tableColumns];

  const columns = [...formColums];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: itemsList.length, // replace later with size(customers),
    custom: true,
    cellEdit: {
      // You can only see this prop when type is cellEdit
      id: "",
      name: "",
    },
    hidePageListOnlyOnePage: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;
  function fillDate(value) {
    document.getElementById("value").value = d.split("/").reverse().join("-");
  }
  return (
    <Container fluid>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">
                <div className="d-flex justify-content-between">
                  <h4>{pageDetails.title}</h4> {/* degistir */}
                  <div>
                    <CreateUpdateModal
                      callback={handleGetItems}
                      updateapi={services.updateapi}
                      createapi={services.createapi}
                      pageDetails={pageDetails}
                      formValues={formValues}
                    />
                  </div>
                </div>
              </CardTitle>

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={itemsList.content}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider keyField="id" columns={columns} data={itemsList.content} search>
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="auto">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <DateRange />
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                hover
                                keyField={"id"}
                                responsive
                                bordered={true}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {confirmDelete.open ? (
        <SweetAlert
          title={confirmDelete.title}
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteItem(confirmDelete.itemId);
          }}
          onCancel={() => setConfirmDelete(confirmDeleteInitialValues)}
        >
          {!confirmDelete.confirmed && "You won't be able to revert this!"}
        </SweetAlert>
      ) : null}
      {confirmDelete.confirmed ? (
        <SweetAlert
          disabled={confirmDelete.loading}
          success
          title={"Successfully deleted."}
          onConfirm={() => {
            setConfirmDelete(confirmDeleteInitialValues);
            handleGetItems();
          }}
        >
          {confirmDelete.loading && <Spinner className="ms-2" color="primary" />}
        </SweetAlert>
      ) : null}
    </Container>
  );
};

const CreateUpdateModal = ({
  details,
  isEdit = false,
  callback,
  createapi,
  updateapi,
  formValues = {
    values: [{ name: "", label: "", type: "", size: "" }],
    validationSchema: {},
  },
  pageDetails,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen((prev) => !prev);
  const [formData, setFormData] = useState({
    loading: false,
    success: null,
    error: null,
  });
  const initialValues = formValues.values.reduce((acc, curr) => {
    if (curr.name === "status") {
      acc[curr.name] = isEdit ? details[curr.name] : "y";
    } else {
      if (curr.getValue == null) {
        acc[curr.name] = isEdit ? details[curr.name] : "";
      } else {
        acc[curr.name] = isEdit ? curr.getValue(details) : "";
      }
    }
    return acc;
  }, {});
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object(formValues.validationSchema),
    onSubmit: (values) => {
      setFormData({ loading: true, success: null, error: null });
      if (isEdit) {
        // console.log(validation.values, "val");
        // console.log(validation.values, "----");

        updateapi(details.id, values) // service
          .then((res) => {
            setFormData({ loading: false, success: true, error: null });
            toggleModal();
            callback && callback();
          })
          .catch((err) => {
            console.log(err, "errorr");
            setFormData({
              loading: false,
              success: false,
              error: err.response.data.message,
            });
          });
      } else {
        createapi(values) // service
          .then((res) => {
            setFormData({ loading: false, success: true, error: null });
            validation.resetForm();
            toggleModal();
            callback && callback();
          })
          .catch((err) => {
            setFormData({
              loading: false,
              success: false,
              error: err.response.data.message,
            });
          });
      }
    },
  });

  const handleSubmit = () => {
    validation.handleSubmit();
  };

  return (
    <Fragment>
      {pageDetails.definitionName != "CurrencyRate" && (
        <Button color={isEdit ? "warning" : "primary"} size={isEdit ? "sm" : "md"} onClick={toggleModal}>
          {isEdit ? (
            <i className="fa fa-edit"></i>
          ) : (
            <Fragment>
              <i className="fa fa-plus"></i> New {pageDetails.definitionName} {/* degistir */}
            </Fragment>
          )}
        </Button>
      )}

      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {isEdit ? `Edit ${pageDetails.definitionName}` : `Create New ${pageDetails.definitionName}`}{" "}
            {/* degistir */}
          </h5>
          <Button onClick={toggleModal} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="p-2">
            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              {formData.error ? <Alert color="danger">{formData.error}</Alert> : null}

              <Row>
                {formValues.values.map((i) => {
                  const key = i.name;
                  return i.name === "status" ? (
                    <Col sm={12} key={key}>
                      <StatusCheckBox validation={validation} />
                    </Col>
                  ) : i.type === "select" ? (
                    <Col sm={i.size} key={key}>
                      <div className="mb-3">
                        <Label className="form-label">{i.label}</Label>
                        <MultiselectWithService
                          service={i.getService}
                          name={i.name}
                          value={validation.values[i.name]}
                          isMulti={i.isMulti}
                          setValue={validation.setFieldValue}
                        />
                      </div>
                    </Col>
                  ) : (
                    <Col sm={i.size} key={key}>
                      <div className="mb-3">
                        <Label className="form-label">{i.label}</Label>
                        <Input
                          name={i.name}
                          className="form-control"
                          placeholder={i.type !== "datetime" ? `Enter ${i.label}` : "yyyy-mm-dd"}
                          type={i.type}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values[i.name] || ""}
                          invalid={validation.touched[i.name] && validation.errors[i.name] ? true : false}
                        />
                        {validation.touched[i.name] && validation.errors[i.name] ? (
                          <FormFeedback type="invalid">{validation.errors[i.name]}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Form>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={toggleModal} color="secondary" data-dismiss="modal">
            Close
          </Button>
          <Button onClick={handleSubmit} color="success" disabled={formData.loading}>
            {formData.loading ? <Spinner color="white" size="sm" /> : "Save"}
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export const StatusCheckBox = ({ validation }) => {
  const handleChange = (e) => {
    validation.setFieldValue("status", e.target.checked ? "y" : "n");
  };
  return (
    <div className="form-check form-switch mb-3">
      <input
        type="checkbox"
        className="form-check-input"
        id="formStatusSwitch"
        onChange={handleChange}
        // checked={validation.values.status === "y"}
        defaultChecked={validation.values.status === "y"}
      />
      <label className="form-check-label" htmlFor="formStatusSwitch">
        Status Active
      </label>
    </div>
  );
};

export default ListDefinitionTable;
