import { Card, CardBody, Col, Row } from "reactstrap";
import TransferCard from "./TransferCard";
import TransferDocuments from "./TransferDocuments";
import TransferProducts from "./TransferProducts";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";
import { EnumValues } from "common/enums";

const TransferDetailCard = ({ id }) => {
  return (
    <Row>
      <Col>
        <TransferCard id={id} />
        <Card className="shadow-lg">
          <CardBody className="">
            <TransferProducts transferId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <TransferDocuments transferId={id} type="view" />
          </CardBody>
        </Card>
        <ActionLogsCard
          title="Transfer History"
          recordId={id}
          actionModels={[EnumValues.ACTION_MODEL.TRANSFER]}
          parentActions={[EnumValues.PARENT_ACTION_TYPE.TRANSFER]}
        />
      </Col>
    </Row>
  );
};

export default TransferDetailCard;
