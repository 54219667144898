import enums, { getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import DateOutput from "components/Common/DateOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { getFinances, getInvoice } from "store/finance/services";
import { financesAppliedFilterState } from "./atoms";
import FinanceFilterForm from "./FinanceFilterForm";
import financeUtils from "./finance_utils";
import { downloadService } from "services/DownloadService";

const Finances = ({ history }) => {
  const activeUser = useActiveUser();
  const [finances, setFinances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appliedFilter, setAppliedFilter] = useRecoilState(financesAppliedFilterState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Finances");
  const loadFinances = async (page, filter) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getFinances(page, financeUtils.mapToFinanceSearch(filter));
      setTotalSize(totalElements);
      setFinances(content);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFinances(pageOptions, appliedFilter);
  }, []);

  const handlePageChange = (arg0, { page, sizePerPage }) => {
    loadFinances(setPageNumber(page, sizePerPage), appliedFilter);
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadFinances(newPage, appliedFilter);
    setHasFilter(hasAnyValue(appliedFilter));
  };
  const handleDownloadInvoice = async (financeId) => {
    const data = await getInvoice(financeId);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };
  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      align: "center",
      headerAlign: "center",
      hidden:
        !activeUser.hasPrivilege(Privileges.ORDER.UPDATE_PF) && !activeUser.hasPrivilege(Privileges.FINANCE.UPDATE),
      formatter: (cell, row) => {
        return (
          <ActionMenu>
            <DropdownMenu>
              {activeUser.hasPrivilege(Privileges.FINANCE.UPDATE) && (
                <DropdownItem onClick={() => history.push(`/finance/${row.id}`)}>Edit</DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.ORDER.UPDATE_PF) && (
                <DropdownItem onClick={() => history.push(`/order/${row.order?.id}/payments-and-finances`)}>
                  Order Payments & Finances
                </DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.INVOICE.DOWNLOAD) && (
                <DropdownItem onClick={() => handleDownloadInvoice(row.id)}>Invoice PDF</DropdownItem>
              )}
            </DropdownMenu>
          </ActionMenu>
        );
      },
    },
    {
      text: "Order No",
      dataField: "order.orderId",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
              <Link to={`/order/${row.order.id}/detail`} title="View order detail">
                {cell}
              </Link>
            )}
            {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && cell}
          </>
        );
      },
    },
    {
      text: "Proforma No",
      dataField: "order.proformaId",
      sort: false,
    },
    {
      text: "Date",
      dataField: "date",
      sort: false,
      formatter: (cell) => <DateOutput date={cell} />,
    },
    {
      text: "Customer",
      dataField: "order.customer.fullName",
    },
    {
      dataField: "order.orderStatus",
      text: "Order Status",
      formatter: (cell) => getEnumLabel(enums.orderStatus, cell),
    },
    {
      dataField: "paymentStatus",
      text: "Finance Status",
      formatter: (cell) => getEnumLabel(enums.financePaymentStatuses, cell),
    },
    {
      text: "Completed Date",
      dataField: "completedDate",
    },
    {
      text: "Auth Code",
      dataField: "authCode",
    },
    {
      text: "Auth By",
      dataField: "authBy.fullName",
    },
    {
      text: "Created By",
      dataField: "createUser",
    },
    {
      text: "Finance Company",
      dataField: "financeCompanyRate.financeCompany.companyName",
    },
    {
      text: "Branch",
      dataField: "order.deliveryBranch.name",
    },
    {
      text: "Commission",
      dataField: "financeCommission",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => <MoneyOutput value={cell} />,
    },
    {
      text: "Amount",
      dataField: "totalAmount",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => <MoneyOutput value={cell} />,
    },
  ];
  return (
    <>
      <MetaTags>
        <title>Novia - Finances</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <FinanceFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                      <h4>Finances</h4>
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        keyField="id"
                        responsive
                        bordered={true}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={finances}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Finances.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Finances);
