import ConfirmButton from "components/Common/ConfirmButton";
import NumberOutput from "components/Common/NumberOutput";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import ValidationForm from "components/ValidationForm";
import { useActiveUser } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getUserListContent } from "store/definitions/services";
import { addPerson, deletePerson, getSalesTarget } from "store/salesTarget/services";

const initialSalesTargetPersonStateState = { open: false, error: null, loading: false, salesTargetPerson: {} };
const SalesTargetPersons = ({ salesTargetId }) => {
  const activeUser = useActiveUser();
  const [salesTargetPersonPopupState, setSalesTargetPersonPopupState] = useState(initialSalesTargetPersonStateState);
  const [salesTargetPersons, setSalesTargetPersons] = useState([]);
  const [salesTarget, setSalesTarget] = useState(null);
  const openPersonAddPopup = () => {
    setSalesTargetPersonPopupState((prev) => ({ ...prev, salesTargetPerson: {}, open: true }));
  };
  const handleAddPerson = async (salesTargetPerson) => {
    return addPerson(salesTargetId, salesTargetPerson).then(() => {
      loadSalesTargetPersons(salesTargetId);
      setSalesTargetPersonPopupState((prev) => ({ ...prev, salesTargetPerson: {}, open: false }));
    });
  };
  const handleClosePersonPopup = () => {
    setSalesTargetPersonPopupState((prev) => ({ ...prev, open: false }));
  };
  const loadSalesTargetPersons = async (salesTargetId) => {
    const salesTarget = await getSalesTarget(salesTargetId);
    setSalesTargetPersons(salesTarget.persons);
  };
  const loadSalesTarget = async (salesTargetId) => {
    const salesTarget = await getSalesTarget(salesTargetId);
    setSalesTarget(salesTarget);
  };
  const handleDeletePerson = async ({ id }) => {
    await deletePerson(salesTargetId, id);
    loadSalesTargetPersons(salesTargetId);
  };
  useEffect(() => {
    loadSalesTarget(salesTargetId);
    loadSalesTargetPersons(salesTargetId);
  }, [salesTargetId]);

  const productColumns = useMemo(
    () => [
      {
        text: "Person",
        dataField: "person.fullName",
      },
      {
        text: "Target",
        dataField: "target",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => <NumberOutput value={cell} />,
      },
      {
        text: "Rate",
        dataField: "rate",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => <NumberOutput value={cell} />,
      },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeletePerson(row)}
              title="Are you sure you want to delete the person target?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    [salesTarget]
  );
  return (
    <>
      <span style={{ float: "right" }}>
        <Button color="primary" size="sm" onClick={openPersonAddPopup}>
          <i className="fa fa-plus"></i> Add Person Target
        </Button>
      </span>
      <>
        <h4 className="text-secondary">
          <b>Person Targets</b>
        </h4>
        <br />
        {salesTargetPersons.length > 0 && (
          <Row>
            <Col xl="12">
              <div className="table-responsive">
                <BootstrapTable keyField="id" data={salesTargetPersons} columns={productColumns} />
              </div>
            </Col>
          </Row>
        )}
      </>

      {salesTargetPersonPopupState.open && (
        <SalesTargetPersonModal onClose={handleClosePersonPopup} onSubmit={handleAddPerson} />
      )}
    </>
  );
};

const SalesTargetPersonModal = ({ targetPerson, onClose, onSubmit }) => {
  const [salesTargetPerson, setSalesTargetPerson] = useState({
    userId: targetPerson?.user?.id,
    target: targetPerson?.target,
    rate: targetPerson?.rate,
  });
  const [loading, setLoading] = useState(false);
  const handleSetFormValue = useCallback((name, value) => {
    setSalesTargetPerson((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit({ ...salesTargetPerson });
    } finally {
      setLoading(false);
    }
  };
  return (
    <ValidationForm>
      <Modal isOpen centered={true} size="lg">
        <ModalHeader toggle={onClose}>{!targetPerson?.id ? "Add new person target" : "Edit person target"}</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12}>
              <div className="row mb-4">
                <Label htmlFor="storeId" className="col-sm-3 col-form-label">
                  Sales Person
                </Label>
                <Col sm={9}>
                  <MultiselectWithService
                    service={getUserListContent}
                    name="userId"
                    setValue={handleSetFormValue}
                    labelField="fullName"
                    valueField="id"
                    value={salesTargetPerson.userId}
                    validations={["required"]}
                    validationeventname="setValue"
                  />
                </Col>
              </div>
            </Col>
            <Col sm={12}>
              <div className="row mb-4">
                <Label htmlFor="target" className="col-sm-3 col-form-label">
                  Target
                </Label>
                <Col sm={9}>
                  <NumberFormat
                    value={salesTargetPerson.target || ""}
                    className="form-control"
                    placeholder="only numbers.."
                    allowNegative={false}
                    name="target"
                    onValueChange={({ value }) => {
                      handleSetFormValue("target", value);
                    }}
                    validations={["required"]}
                    validationeventname="onValueChange"
                  />
                </Col>
              </div>
            </Col>
            <Col sm={12}>
              <div className="row mb-4">
                <Label htmlFor="rate" className="col-sm-3 col-form-label">
                  Rate
                </Label>
                <Col sm={9}>
                  <NumberFormat
                    value={salesTargetPerson.rate || ""}
                    className="form-control"
                    placeholder="only numbers.."
                    allowNegative={false}
                    name="rate"
                    onValueChange={({ value }) => {
                      handleSetFormValue("rate", value);
                    }}
                    validations={["required"]}
                    validationeventname="onValueChange"
                  />
                </Col>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSubmitForm} color="primary" disabled={loading} validate>
            Add target
          </Button>
        </ModalFooter>
      </Modal>
    </ValidationForm>
  );
};

export default SalesTargetPersons;
