import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//transfer
export const getTransfer = (id) => get(`/transfer/${id}`);
export const editTransfer = (id, data) => put(`/transfer/${id}`, data);
export const completeTransfer = (id, data) => put(`transfer/${id}/complete`, data);
export const createTransfer = (data) => post(`/transfer`, data);
export const createTransferAsCompleted = (data) => post(`/transfer/saveAsComplete`, data);
export const deleteTransfer = (id) => del(`/transfer/${id}`);
export const getTransferProducts = (id) => get(`/transfer/${id}/products`);
export const getAvailableProducts = (id) => get(`/transfer/availableProducts?branchId=${id}`);

//transfer products
export const addProductToTransfer = (transferId, data) => put(`/transfer/${transferId}/product`, data);
export const deleteTransferProduct = (transferId, data) => del(`/transfer/${transferId}/product`, data);

export const addNoteToTransfer = (transferId, data) => put(`/transfer/${transferId}/note`, data);
export const getTransferNotes = (transferId) => get(`/transfer/${transferId}/note`);
export const deleteTransferNote = (transferId, noteId) => del(`/transfer/${transferId}/note/${noteId}`);

export const addDocumentToTransfer = (transferId, data, type) =>
  put(`/transfer/${transferId}/document?type=${type}`, data);
export const getTransferDocuments = (transferId) => get(`/transfer/${transferId}/document`);
export const deleteTransferDocument = (transferId, docId) => del(`/transfer/${transferId}/document/${docId}`);

export const getTransfers = (pageOpt = { page: 0, size: 1000 }, filter, sort) =>
  get(
    `/transfer/search?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );
