import CustomSpinner from "components/Common/CustomSpinner";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getInventoryProducts } from "store/order/ordered-product-services";
import { checkGetOrCreateVariant, getProduct, getProducts, getVariantBySku } from "store/product/services";
import Attributes from "./Attributes";
import VariantCard from "./VariantCard";
import productUtils from "./product_utils";

const VariantModal = ({ onClose, onSelect, selectedVariant }) => {
  const [productLoading, setProductLoading] = useState(false);
  const [variantLoading, setVariantLoading] = useState(false);
  const [productId, setProductId] = useState();
  const [product, setProduct] = useState({
    productId: null,
  });
  const [formValues, setFormValues] = useState({
    color: null,
    attributes: [],
  });
  const [variant, setVariant] = useState();
  const [inStockOnly, setInStockOnly] = useState(false);

  useEffect(() => {
    loadVariant();
  }, [formValues]);

  useEffect(() => {
    if (selectedVariant?.productId) {
      handleProductSelected("products", selectedVariant.productId);
    }
  }, [selectedVariant?.productId]);

  const loadVariant = async () => {
    if (validateForm(false)) {
      setVariantLoading(true);
      try {
        const variant = await checkGetOrCreateVariant(productId, {
          colorId: formValues.color == null ? null : formValues.color.value,
          optionIds: formValues.attributes.map((a) => a.values[0].value),
          create: false,
        });
        setVariant(variant);
      } finally {
        setVariantLoading(false);
      }
    } else {
      setVariant(null);
    }
  };

  const handleInStockProductSelected = async (name, productId, { option }) => {
    const variant = await getVariantBySku(option.sku);
    const product = await getProduct(variant.productId);
    onSelect({ product, variant, stock: true });
  };

  const handleProductSelected = async (name, productId) => {
    setProductLoading(true);
    try {
      setVariant(null);
      const mdl = await getProduct(productId);
      const product = productUtils.mapToFormDataModel(mdl);
      setProductId(productId);
      setProduct(product);
      setFormValues({
        color:
          selectedVariant?.productId == mdl.id && selectedVariant?.color ? { value: selectedVariant.color.id } : null,
        attributes: product.attributes
          .filter((a) => a.attribute.inputType == "SELECT")
          .map((a) => {
            let values = [];
            if (selectedVariant && selectedVariant?.productId == mdl.id) {
              if (selectedVariant.option1 && selectedVariant.option1.attributeId == a.attribute.id) {
                values = [{ value: selectedVariant.option1.id }];
              } else if (selectedVariant.option2 && selectedVariant.option2.attributeId == a.attribute.id) {
                values = [{ value: selectedVariant.option2.id }];
              } else if (selectedVariant.option3 && selectedVariant.option3.attributeId == a.attribute.id) {
                values = [{ value: selectedVariant.option3.id }];
              } else if (selectedVariant.option4 && selectedVariant.option4.attributeId == a.attribute.id) {
                values = [{ value: selectedVariant.option4.id }];
              } else if (selectedVariant.option5 && selectedVariant.option5.attributeId == a.attribute.id) {
                values = [{ value: selectedVariant.option5.id }];
              } else if (selectedVariant.option6 && selectedVariant.option6.attributeId == a.attribute.id) {
                values = [{ value: selectedVariant.option6.id }];
              } else if (selectedVariant.option7 && selectedVariant.option7.attributeId == a.attribute.id) {
                values = [{ value: selectedVariant.option7.id }];
              } else if (selectedVariant.option8 && selectedVariant.option8.attributeId == a.attribute.id) {
                values = [{ value: selectedVariant.option8.id }];
              }
            }
            return {
              attribute: a.attribute,
              value: null,
              values,
              options: [...a.values],
            };
          }),
      });
    } finally {
      setProductLoading(false);
    }
  };

  const handleSelectVariant = async () => {
    if (!validateForm(true)) {
      return;
    }
    let selectedVariant = variant;
    if (variant.id == null) {
      setVariantLoading(true);
      try {
        selectedVariant = await checkGetOrCreateVariant(productId, {
          colorId: formValues.color == null ? null : formValues.color.value,
          optionIds: formValues.attributes.map((a) => a.values[0].value),
          create: true,
        });
      } finally {
        setVariantLoading(false);
      }
    }
    onSelect({ product, variant: selectedVariant });
  };

  const validateForm = (showMessage) => {
    if (product == null || product.productId == null) {
      showMessage && toast.error("Product should be selected");
      return false;
    }
    if (product.colors.length > 0 && (formValues.color == null || formValues.color.value == null)) {
      showMessage && toast.error("Color should be selected");
      return false;
    }
    const invalidAttr = formValues.attributes.find(
      (a) =>
        (a.attribute.inputType == "SELECT" && (a.values == null || a.values.length == 0)) ||
        (a.attribute.inputType != "SELECT" && (a.value == null || a.value == ""))
    );
    if (invalidAttr != null) {
      showMessage && toast.error("Attribute should be selected");
      return false;
    }
    return true;
  };

  const handleSetValue = useCallback((name, value, valueObject) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);

  const handleAttributeValueChange = useCallback((id, value) => {
    setFormValues((prevValues) => {
      const attr = prevValues.attributes.find((item) => item.attribute.id == id);
      attr.value = value;
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);
  const handleAttributeValuesChange = useCallback((id, values, objValues) => {
    setFormValues((prevValues) => {
      const attr = prevValues.attributes.find((item) => item.attribute.id == id);
      attr.values = objValues;
      return {
        ...prevValues,
        attributes: [...prevValues.attributes],
      };
    });
  }, []);

  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Select Product Variant</ModalHeader>
      <ModalBody>
        <div className="p-2">
          <div className="row">
            <Col sm={12}>
              <div className="form-check pb-2" style={{ float: "right" }}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inStockOnly"
                  defaultChecked={inStockOnly}
                  onChange={(e) => {
                    setInStockOnly(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="inStockOnly">
                  In Stock Only
                </label>
              </div>
            </Col>
          </div>
          {inStockOnly && (
            <div className="row mb-4">
              <Label htmlFor="product" className="col-sm-3 col-form-label">
                In Stock Products
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  valueField="variantId"
                  service={() =>
                    getInventoryProducts(
                      {
                        page: 0,
                        size: 1000,
                      },
                      { minStock: 1 },
                      { sortField: "name", sortDirection: "asc" }
                    )
                      .then((res) => res.content)
                      .then((res) => res.map((m) => ({ ...m, name: `${m.name} - ${m.variantName} (${m.stock})` })))
                  }
                  name="inStockProducts"
                  value={undefined}
                  isMulti={false}
                  setValue={handleInStockProductSelected}
                  autoFocus
                  defaultMenuIsOpen
                />
              </Col>
            </div>
          )}
          {!inStockOnly && (
            <>
              <div className="row mb-4">
                <Label htmlFor="product" className="col-sm-3 col-form-label">
                  Products
                </Label>
                <Col sm={9}>
                  <MultiselectWithService
                    service={getProducts}
                    name="products"
                    value={productId}
                    isMulti={false}
                    setValue={handleProductSelected}
                    autoFocus={selectedVariant == null}
                    defaultMenuIsOpen={selectedVariant == null}
                  />
                </Col>
              </div>
              {productLoading && <CustomSpinner />}
              {!productLoading && product.productId && (
                <>
                  {product.colors.length > 0 && (
                    <div className="row mb-4">
                      <Label className="col-sm-3 col-form-label" htmlFor="autoSizingSelect">
                        Color
                      </Label>
                      <div className="col-9">
                        <MultiselectWithService
                          service={() => Promise.resolve(product.colors)}
                          name="color"
                          value={formValues.color?.value}
                          isMulti={false}
                          isClearable
                          setValue={handleSetValue}
                          labelField="nameFirstMaterial"
                        />
                      </div>
                    </div>
                  )}
                  <Attributes
                    items={formValues.attributes}
                    setValue={handleAttributeValueChange}
                    setValues={handleAttributeValuesChange}
                    isMulti={false}
                  />
                </>
              )}
              {variantLoading && <CustomSpinner />}
              {!variantLoading && variant && <VariantCard variant={variant} />}
            </>
          )}
        </div>
      </ModalBody>
      {!variantLoading && variant && !inStockOnly && (
        <ModalFooter>
          <Button color="success" size="md" onClick={handleSelectVariant}>
            Select
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default VariantModal;
