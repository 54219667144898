import ActionMenu from "components/Common/ActionMenu";
import BasicPopover from "components/Common/BasicPopover";
import CustomSpinner from "components/Common/CustomSpinner";
import ImagesModal from "components/Common/ImagesModal";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { DropdownItem, DropdownMenu, Modal, ModalBody, ModalHeader } from "reactstrap";
import { downloadService } from "services/DownloadService";
import { getActivePriceTags } from "store/definitions/services";
import { downloadPriceTag, getVariantImages } from "store/product/services";
import VariantImage from "./VariantImage";
import VariantPriceHistories from "./VariantPriceHistories";

const VariantsView = ({
  product = {
    id: null,
    variants: [],
    attributeValueOptions: [],
  },
}) => {
  const activeUser = useActiveUser();
  const [variantIdForImage, setVariantIdForImage] = useState(null);
  const [variantIdForPriceHistory, setVariantIdForPriceHistory] = useState(null);
  const [priceTags, setPriceTags] = useState();
  const [loading, setLoading] = useState(true);

  const loadOptions = async () => {
    setLoading(true);
    try {
      await loadPriceTags();
    } finally {
      setLoading(false);
    }
  };

  const loadPriceTags = async () => {
    const priceTags = await getActivePriceTags();
    setPriceTags(priceTags);
    return Promise.resolve();
  };

  useEffect(() => {
    loadOptions();
    return () => {
      setPriceTags([]);
    };
  }, []);

  const handleDownloadPriceTag = async (priceTagId, variantId) => {
    const data = await downloadPriceTag(priceTagId, variantId);
    downloadService.openBufferedData({
      data,
      contentType: "text/html",
    });
  };

  const variantColumns = useMemo(() => {
    const attrColumns = [];
    if (product.variants.length > 0) {
      product.variants.forEach((variant) => {
        for (let index = 1; index <= 8; index++) {
          const opt = variant[`option${index}`];
          if (opt) {
            const dataField = `option${index}.name`;
            if (attrColumns.find((a) => a.dataField == dataField) == null) {
              attrColumns.push({
                text: opt.attributeName,
                dataField,
                filter: textFilter(),
              });
            }
          }
        }
      });
    }
    const columns = [
      {
        text: "Images",
        dataField: "images",
        formatter: (images, row) => (
          <VariantImage onClick={() => setVariantIdForImage(row.id)} variant={row}></VariantImage>
        ),
      },
      {
        text: "SKU",
        dataField: "sku",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Cost",
        dataField: "cost",
        hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.VIEW_COST),
        formatter: (cell, row) => {
          return <MoneyOutput value={row.cost} currency={row.costCurrency} />;
        },
      },
      {
        text: "Price",
        dataField: "price",
        formatter: (cell, row) => {
          return <MoneyOutput value={row.price} currency={row.priceCurrency} />;
        },
      },
      {
        text: "Sell Price",
        dataField: "salesPrice",
        formatter: (cell, row) => {
          return <MoneyOutput value={row.salesPrice} currency={row.salesPriceCurrency} />;
        },
      },
      {
        text: "Color",
        dataField: "color.nameFirstMaterial",
        filter: textFilter(),
      },
      ...attrColumns,
      {
        text: "Stock Count",
        dataField: "stockInfo.totalCount",
        align: "center",
        sort: true,
        headerAlign: "center",
        formatter: (cell, row) => {
          if (cell <= 0) {
            return cell;
          }
          return (
            <BasicPopover
              content={
                <>
                  {row.stockInfo.branches.map((b) => (
                    <div key={b.branchId} className="p-1">
                      <li>{`${b.branchName} (${b.count})`}</li>
                    </div>
                  ))}
                </>
              }
              trigger={
                <button className="btn btn-success btn-rounded" title="Show branch details">
                  {cell}
                </button>
              }
              title="Branches"
            />
          );
        },
      },
      {
        text: "Actions",
        dataField: "actions",
        align: "center",
        headerAlign: "center",
        hidden:
          !activeUser.hasPrivilege(Privileges.PRODUCT.DOWNLOAD_PRICETAG) &&
          !activeUser.hasPrivilege(Privileges.PRODUCT.VARIANT_PRICE_HISTORY),
        formatter: (cell, row) => {
          return (
            <ActionMenu>
              <DropdownMenu>
                {activeUser.hasPrivilege(Privileges.PRODUCT.DOWNLOAD_PRICETAG) && priceTags?.length > 0 && (
                  <>
                    <DropdownItem disabled>
                      <u>
                        <i>
                          <h6>Price Tags</h6>
                        </i>
                      </u>
                    </DropdownItem>
                    {priceTags?.map((pt) => (
                      <DropdownItem key={pt.id} onClick={() => handleDownloadPriceTag(pt.id, row.id)}>
                        {pt.name}
                      </DropdownItem>
                    ))}
                    <DropdownItem divider />
                  </>
                )}
                {activeUser.hasPrivilege(Privileges.PRODUCT.VARIANT_PRICE_HISTORY) && (
                  <DropdownItem onClick={() => setVariantIdForPriceHistory(row.id)}>Price History</DropdownItem>
                )}
              </DropdownMenu>
            </ActionMenu>
          );
        },
      },
    ];
    return columns;
  }, [product, priceTags]);
  return (
    <>
      {loading && <CustomSpinner />}
      {!loading && (
        <BootstrapTable
          keyField="id"
          noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
          loading={loading}
          data={product.variants}
          columns={variantColumns}
          filter={filterFactory()}
          hover
          responsive
          bordered={true}
          striped={false}
          defaultSorted={[{ dataField: "stockInfo.totalCount", order: "desc" }]}
          // selectRow={selectRow}
          wrapperClasses="table-responsive"
          classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
          headerWrapperClasses="thead-light"
          pagination={paginationFactory({
            hidePageListOnlyOnePage: true,
            hideSizePerPage: true,
            sizePerPage: 20,
          })}
        />
      )}
      {variantIdForImage && (
        <ImagesModal
          editable={false}
          onList={() => getVariantImages(product.id, variantIdForImage)}
          onClose={() => setVariantIdForImage(null)}
        />
      )}
      {variantIdForPriceHistory && (
        <PriceHistoryModal variantId={variantIdForPriceHistory} onClose={() => setVariantIdForPriceHistory(null)} />
      )}
    </>
  );
};

function PriceHistoryModal({ variantId, onClose }) {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Price History</ModalHeader>
      <ModalBody>
        <VariantPriceHistories variantId={variantId} />
      </ModalBody>
    </Modal>
  );
}

export default React.memo(VariantsView);
