import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Label,
  Col,
  CardTitle,
  Button,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { Link, useLocation, withRouter } from "react-router-dom";

import {
  createManufacturers,
  getCurrencies,
  getTicketStatus,
  getAssProblemTypes,
  getAssProblemSubjects,
} from "store/definitions/services";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import TicketProducts from "./TicketProducts";
import { createTicket, editTicket, getTicket } from "store/ass/services";
import { showInfo } from "helpers/utils";
import TicketNotes from "./TicketNotes";
import CreatableSelectBoxWithFilterService from "components/Selectbox/CreatableSelectBoxWithFilterService";
import { createCustomers, searchCustomersByFilter } from "store/customer/services";
import { customerFormValues } from "pages/Customers/Customers";
import CustomerCard from "pages/Customers/CustomerCard";
import ticketUtils from "./ticket_utils";
import { searchOrders } from "store/order/services";
import ValidationForm from "components/ValidationForm";
import ReactDatePicker from "react-datepicker";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import TicketDocuments from "./TicketDocuments";

const initialLoadState = { loading: false, error: null, loaded: false };

const Ticket = ({ id, history }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryOrderId = searchParams.get("orderId");
  const queryCustomerId = searchParams.get("customerId");
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [ticket, setTicket] = useState(
    ticketUtils.initialValues({
      orderId: queryOrderId,
      customerId: queryCustomerId,
    })
  );
  const [ticketView, setTicketView] = useState();
  const activeUser = useActiveUser();

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setTicket((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setTicket((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setTicket((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const handleAddProduct = useCallback((products) => {
    setTicket((prevValues) => {
      const { orderedProducts } = prevValues;
      products.forEach((p) => {
        if (orderedProducts.find((item) => item.id == p.id) == null) {
          orderedProducts.push(p);
        }
      });
      return {
        ...prevValues,
        orderedProducts: [...orderedProducts],
      };
    });
  }, []);

  const handleDeleteProduct = useCallback((product) => {
    setTicket((prevValues) => {
      prevValues.orderedProducts.splice(
        prevValues.orderedProducts.findIndex((item) => item.id == product.id),
        1
      );
      return {
        ...prevValues,
      };
    });
  }, []);

  const loadTicket = async (ticketId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getTicket(ticketId);
      const prd = ticketUtils.mapToFormDataModel(mdl);
      setTicket(prd);
      setTicketView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  //handle ticket
  const handleSaveTicket = async () => {
    setSaving(true);
    try {
      const saveModel = ticketUtils.mapToSaveModel(ticket);
      const mdl = await createTicket(saveModel);
      showInfo("Ticket created successfully");
      history.push(`/ticket/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateTicket = async () => {
    setSaving(true);
    try {
      const saveModel = ticketUtils.mapToSaveModel(ticket);
      const mdl = await editTicket(id, saveModel);
      setTicket(ticketUtils.mapToFormDataModel(mdl));
      setTicketView(mdl);
      showInfo("Ticket updated successfully");
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadTicket(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.loading && (
          <Spinner size="sm" className="float-end">
            Loading...
          </Spinner>
        )}
        <h4 className="text-primary">
          <Breadcrumb listClassName="p-0">
            <BreadcrumbItem>
              <Link to="/tickets">Tickets</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {!id && <b>New Ticket</b>}
              {id && <b>Edit Ticket</b>}
            </BreadcrumbItem>
          </Breadcrumb>
        </h4>
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Ticket could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {ticketView && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-3">Ticket Number</h6>
                <Col>
                  <h5>
                    {activeUser.hasPrivilege(Privileges.ASS.DETAIL) && (
                      <Link to={`/ticket/${ticketView.id}/detail`} title="View ticket detail">
                        {ticketView.id}
                      </Link>
                    )}
                    {!activeUser.hasPrivilege(Privileges.ASS.DETAIL) && ticketView.id}
                  </h5>
                </Col>
                <h6 className="col-sm-3">Created By</h6>
                <Col>{ticketView.createUser}</Col>
              </Row>
              <Row className="py-1">
                <h6 className="col-sm-3">Last Modified At</h6>
                <Col>{ticketView.updateDate}</Col>
                <h6 className="col-sm-3">Created At</h6>
                <Col>{ticketView.createdDate}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <ValidationForm>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="ticketStatus" className="col-sm-3 col-form-label">
                      Ticket Status
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getTicketStatus}
                        name="ticketStatus"
                        value={ticket.ticketStatus?.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        validations={["required"]}
                        validationeventname="setValue"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="problemSubject" className="col-sm-3 col-form-label">
                      Problem Subject
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getAssProblemSubjects}
                        name="problemSubject"
                        value={ticket.problemSubject?.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        isClearable
                        validations={["required"]}
                        validationeventname="setValue"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="problemType" className="col-sm-3 col-form-label">
                      Problem Type
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getAssProblemTypes}
                        name="problemType"
                        value={ticket.problemType?.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        isClearable
                        validations={["required"]}
                        validationeventname="setValue"
                      />
                    </Col>
                  </div>
                  {ticket.problemType?.value && (
                    <div className="row mb-4">
                      <Label htmlFor="problemType2" className="col-sm-3 col-form-label">
                        Promlem Type 2
                      </Label>
                      <Col sm={9}>
                        <MultiselectWithService
                          service={getAssProblemTypes}
                          name="problemType2"
                          value={ticket.problemType2?.value}
                          isMulti={false}
                          setValue={handleSetValueObj}
                          isClearable
                        />
                      </Col>
                    </div>
                  )}
                  {ticket.problemType?.value && ticket.problemType2?.value && (
                    <div className="row mb-4">
                      <Label htmlFor="problemType3" className="col-sm-3 col-form-label">
                        Promlem Type 3
                      </Label>
                      <Col sm={9}>
                        <MultiselectWithService
                          service={getAssProblemTypes}
                          name="problemType3"
                          value={ticket.problemType3?.value}
                          isMulti={false}
                          setValue={handleSetValueObj}
                          isClearable
                        />
                      </Col>
                    </div>
                  )}
                  {ticket.problemType?.value && ticket.problemType2?.value && ticket.problemType3?.value && (
                    <div className="row mb-4">
                      <Label htmlFor="problemType4" className="col-sm-3 col-form-label">
                        Promlem Type 4
                      </Label>
                      <Col sm={9}>
                        <MultiselectWithService
                          service={getAssProblemTypes}
                          name="problemType4"
                          value={ticket.problemType4?.value}
                          isMulti={false}
                          setValue={handleSetValueObj}
                          isClearable
                        />
                      </Col>
                    </div>
                  )}
                  {ticket.problemType?.value &&
                    ticket.problemType2?.value &&
                    ticket.problemType3?.value &&
                    ticket.problemType4?.value && (
                      <div className="row mb-4">
                        <Label htmlFor="problemType5" className="col-sm-3 col-form-label">
                          Promlem Type 5
                        </Label>
                        <Col sm={9}>
                          <MultiselectWithService
                            service={getAssProblemTypes}
                            name="problemType5"
                            value={ticket.problemType5?.value}
                            isMulti={false}
                            setValue={handleSetValueObj}
                            isClearable
                          />
                        </Col>
                      </div>
                    )}
                  <div className="row mb-4">
                    <Label htmlFor="amount" className="col-sm-3 col-form-label">
                      Amount
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="number"
                        value={ticket.amount}
                        name="amount"
                        id="amount"
                        placeholder="only numbers.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="serviceDate" className="col-sm-3 col-form-label">
                      Service Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={ticket.serviceDate}
                        showTimeInput
                        placeholderText="Service Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("serviceDate", date);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="reminderDate" className="col-sm-3 col-form-label">
                      Reminder Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={ticket.reminderDate}
                        showTimeInput
                        placeholderText="Reminder Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("reminderDate", date);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="salesPerson" className="col-sm-3 col-form-label">
                      Customer
                    </Label>
                    <Col sm={9}>
                      <CreatableSelectBoxWithFilterService
                        loadService={searchCustomersByFilter}
                        name="customerId"
                        value={ticket.customerId}
                        handleChange={(name, value) => {
                          handleSetValue(name, value);
                          handleSetValue("order", null);
                          handleSetValueObj("order", null);
                        }}
                        newItemDetails={{
                          formValues: {
                            values: customerFormValues,
                            itemName: "Customer",
                          },
                          createapi: createCustomers,
                        }}
                      />
                    </Col>
                  </div>
                  {ticket.customerId && (
                    <div className="row mb-4">
                      <Label htmlFor="customerId" className="col-sm-3 col-form-label"></Label>
                      <Col sm={9}>
                        <CustomerCard id={ticket.customerId} />
                      </Col>
                    </div>
                  )}
                  {ticket.customerId && (
                    <div className="row mb-4">
                      <Label htmlFor="order" className="col-sm-3 col-form-label">
                        Orders
                      </Label>
                      <Col sm={9}>
                        <MultiselectWithService
                          service={() =>
                            searchOrders({ customerIds: [ticket.customerId] }).then((data) => data.content)
                          }
                          name="order"
                          labelField="orderId"
                          value={ticket.order?.value}
                          isMulti={false}
                          setValue={handleSetValueObj}
                          isClearable
                          reloadOptionsKey={ticket.customerId}
                        />
                      </Col>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
            <Card className="shadow-lg">
              <CardBody className="">
                <TicketProducts ticket={ticket} onAdd={handleAddProduct} onDelete={handleDeleteProduct} />
              </CardBody>
            </Card>
            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <TicketNotes ticketId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <TicketDocuments ticketId={id} />
                  </CardBody>
                </Card>
              </>
            )}
            <br />
            <br />
            <Card
              className="shadow-lg"
              style={{
                backgroundColor: "#2a3042",
                // bticketTop: "1px solid #E7E7E7",
                // textAlign: "center",
                // padding: "20px",
                position: "fixed",
                // left: 0,
                bottom: 0,
                zIndex: 1,
                // height: "60px",
                // width: "80%",
              }}
            >
              <CardBody className="">
                <Row>
                  {id && (
                    <Col>
                      <Button color="success" size="md" onClick={handleUpdateTicket} validate>
                        <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                      </Button>
                    </Col>
                  )}
                  {!id && (
                    <Col>
                      <Button color="success" size="md" onClick={handleSaveTicket} validate>
                        <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </ValidationForm>
        )}
      </div>
    </React.Fragment>
  );
};

Ticket.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Ticket);
