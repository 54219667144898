import DateTimeOutput from "components/Common/DateTimeOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getProductPriceHistories, getVariantPriceHistories } from "store/product/services";

const VariantPriceHistories = ({ variantId }) => {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    loadHistories();
  }, [variantId]);

  const loadHistories = async () => {
    setLoading(true);
    try {
      const histories = await getVariantPriceHistories(variantId);
      setHistories(histories);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        text: "Date",
        dataField: "createdDate",
        formatter: (createdDate, row) => <DateTimeOutput date={createdDate} />,
        // filter: textFilter(),
        sort: true,
      },
      {
        text: "Cost",
        dataField: "cost",
        hidden: !activeUser.hasPrivilege(Privileges.PRODUCT.VIEW_COST),
        formatter: (cell, row) => {
          return <MoneyOutput value={row.cost} currency={row.costCurrency} />;
        },
        sort: true,
      },
      {
        text: "Price",
        dataField: "price",
        formatter: (cell, row) => {
          return <MoneyOutput value={row.price} currency={row.priceCurrency} />;
        },
        sort: true,
      },
      {
        text: "Sell Price",
        dataField: "salesPrice",
        formatter: (cell, row) => {
          return <MoneyOutput value={row.salesPrice} currency={row.salesPriceCurrency} />;
        },
        sort: true,
      },
    ],
    []
  );

  return (
    <BootstrapTable
      keyField="id"
      noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
      loading={loading}
      data={histories}
      columns={columns}
      filter={filterFactory()}
      hover
      responsive
      bordered={true}
      striped={false}
      defaultSorted={[{ dataField: "createdDate", order: "desc" }]}
      // selectRow={selectRow}
      wrapperClasses="table-responsive"
      classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
      headerWrapperClasses="thead-light"
      pagination={paginationFactory({
        hidePageListOnlyOnePage: true,
        hideSizePerPage: true,
        sizePerPage: 20,
      })}
    />
  );
};

export default VariantPriceHistories;
