import moment from "moment";

class FinanceUtils {
  initialValues = (orderId) => ({
    id: null,
    status: "y",
    authBy: {},
    orderId,
    financeCompany: {},
    financeCompanyRate: {},
    date: new Date(),
    authCode: "",
    totalAmount: "",
    financeCommission: "",
    paymentStatus: "OPEN",
  });
  mapToSaveModel = ({
    id,
    status = "y",
    orderId,
    date,
    authBy = {},
    financeCompanyRate = {},
    totalAmount,
    authCode,
    paymentStatus,
  }) => ({
    id,
    status,
    authById: authBy?.value,
    financeCompanyRateId: financeCompanyRate?.value,
    orderId,
    date: date && moment(date).format("YYYY-MM-DD HH:mm:ss"),
    totalAmount,
    authCode,
    paymentStatus,
  });

  mapToFormDataModel = ({
    id,
    status,
    authBy,
    financeCompanyRate,
    order,
    date,
    totalAmount,
    authCode,
    financeCommission,
    paymentStatus,
  }) => ({
    id,
    status,
    authBy: {
      label: authBy?.fullName,
      value: authBy?.id,
    },
    date: date && moment(date, "YYYY-MM-DD HH:mm:ss").toDate(),
    orderId: order?.id,
    financeCompany: {
      label: financeCompanyRate?.financeCompany?.companyName,
      value: financeCompanyRate?.financeCompany?.id,
    },
    financeCompanyRate: {
      label: `${financeCompanyRate?.month} (${financeCompanyRate?.rate}%)`,
      value: financeCompanyRate?.id,
      option: financeCompanyRate,
    },
    totalAmount,
    authCode,
    financeCommission,
    paymentStatus,
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });

  initialFilterValues = () => ({
    orderId: null,
    branchId: null,
    financeCompanyId: null,
    authById: null,
    timePeriod: null,
    authCode: "",
    startDate: null,
    endDate: null,
    completedStartDate: null,
    completedEndDate: null,
    paymentStatus: null,
    status: "",
  });

  mapToFinanceSearch = (
    filter = {
      orderId: null,
      branchId: null,
      financeCompanyId: null,
      authById: null,
      timePeriod: null,
      authCode: "",
      startDate: null,
      endDate: null,
      completedStartDate: null,
      completedEndDate: null,
      paymentStatus: null,
      status: "",
    }
  ) => {
    return {
      orderId: filter.orderId,
      branchId: filter.branchId,
      financeCompanyId: filter.financeCompanyId,
      authById: filter.authById,
      timePeriod: filter.timePeriod,
      authCode: filter.authCode,
      startDate: filter.startDate == null ? null : moment(filter.startDate).format("YYYY-MM-DD"),
      endDate: filter.endDate == null ? null : moment(filter.endDate).format("YYYY-MM-DD"),
      completedStartDate:
        filter.completedStartDate == null ? null : moment(filter.completedStartDate).format("YYYY-MM-DD"),
      completedEndDate: filter.completedEndDate == null ? null : moment(filter.completedEndDate).format("YYYY-MM-DD"),
      status: filter.status,
      paymentStatus: filter.paymentStatus,
    };
  };
}
const financeUtils = new FinanceUtils();
export default financeUtils;
