import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { getProductCategories, getProductTypes, getStatuses } from "store/definitions/services";

const ProductFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    onSubmit({});
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="name" className="col-sm-3 col-form-label">
                Name
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.name || ""}
                  name="name"
                  placeholder="name.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="sku" className="col-sm-3 col-form-label">
                SKU
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.sku || ""}
                  name="sku"
                  placeholder="sku.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="variantSku" className="col-sm-3 col-form-label">
                Variant SKU
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.variantSku || ""}
                  name="variantSku"
                  placeholder="Variant Sku.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label className="col-sm-3 col-form-label" htmlFor="categoryId">
                Category
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  labelField="nameWithPath"
                  service={getProductCategories}
                  name="categoryId"
                  value={filter.categoryId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="barcode" className="col-sm-3 col-form-label">
                Barcode
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.barcode || ""}
                  name="barcode"
                  placeholder="barcode.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="typeId" className="col-sm-3 col-form-label">
                Product Type
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getProductTypes}
                  name="typeId"
                  value={filter.typeId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label className="col-sm-3 col-form-label" htmlFor="status">
                Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStatuses}
                  name="status"
                  value={filter.status}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp; &nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </form>
    </React.Fragment>
  );
};

export default ProductFilterForm;
