import MoneyOutput from "components/Common/MoneyOutput";
import PercentageOutput from "components/Common/PercentageOutput";
import { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getSalesTargetStats } from "store/salesTarget/services";
import SalesTargetCard from "./SalesTargetCard";

const SalesTargetStats = ({ id }) => {
  const [salesTargeState, setSalesTargetState] = useState({
    error: null,
    loading: false,
    salesTarget: null,
  });
  const { salesTarget } = salesTargeState;
  const loadSalesTargetStats = async () => {
    setSalesTargetState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const ord = await getSalesTargetStats(id);
      setSalesTargetState((state) => ({
        ...state,
        salesTarget: ord,
        loading: false,
      }));
    } catch (error) {
      setSalesTargetState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadSalesTargetStats();
  }, [id]);
  return (
    <Row>
      <Col>
        <SalesTargetCard id={id} />
        {salesTargeState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Sales Target could not be loaded, please try again</CardTitle>
          </Card>
        )}
        <SalesTargetStatsCard salesTarget={salesTarget} loading={salesTargeState.loading} />
      </Col>
    </Row>
  );
};

function SalesTargetStatsCard({ salesTarget, loading }) {
  const data = [
    ...(salesTarget?.rates || [])
      .sort((o1, o2) => o1.target - o2.target)
      .map((p) => ({
        ...p,
        type: "S",
        typeLabel: "Store",
        name: salesTarget.store?.name,
        key: `${"store"}T${p.target}`,
      })),
    ...(salesTarget?.persons || [])
      .sort((o1, o2) => o1.person?.fullName.localeCompare(o2.person?.fullName) || o1.target - o2.target)
      .map((p) => ({
        ...p,
        type: "P",
        typeLabel: "Sales Person",
        name: p.person?.fullName,
        key: `${"person"}P${p.person?.id}T${p.target}`,
      })),
  ];
  const handleColorStyle = useCallback((cell, row) => {
    if (row.active) {
      return { color: "green", fontWeight: 600, fontSize: "1rem" };
    }
    return null;
  });
  const columns = useMemo(
    () => [
      {
        text: "",
        dataField: "typeLabel",
      },
      {
        text: "",
        dataField: "name",
      },
      {
        text: "Target",
        dataField: "target",
        headerAlign: "right",
        align: "right",
        formatter: (cell) => <MoneyOutput value={cell} />,
      },
      {
        text: "Rate",
        dataField: "rate",
        headerAlign: "right",
        align: "right",
        formatter: (cell) => <PercentageOutput value={cell} />,
      },
      {
        text: "Current Revenue",
        dataField: "currentRevenue",
        headerAlign: "right",
        align: "right",
        formatter: (cell) => <MoneyOutput value={cell} />,
      },
      {
        text: "To Target",
        dataField: "toTarget",
        headerAlign: "right",
        align: "right",
        style: handleColorStyle,
        formatter: (cell) => <PercentageOutput value={cell} />,
      },
      {
        text: "Current Bonus",
        dataField: "currentBonus",
        headerAlign: "right",
        align: "right",
        style: handleColorStyle,
        formatter: (cell) => <MoneyOutput value={cell} />,
      },
      {
        text: "Bonus from Store",
        dataField: "bonusFromStore",
        headerAlign: "right",
        align: "right",
        style: handleColorStyle,
        formatter: (cell, row) => <MoneyOutput value={row.type === "S" ? null : cell} />,
      },
      {
        text: "Total Bonus",
        dataField: "totalBonus",
        headerAlign: "right",
        align: "right",
        style: handleColorStyle,
        formatter: (cell, row) => <MoneyOutput value={row.type === "S" ? null : cell} />,
      },
    ],
    [salesTarget]
  );
  return (
    <Card className="shadow-lg">
      <CardBody>
        <h4 className="text-secondary">
          <b>Target States</b>
        </h4>
        <BootstrapTable
          hover
          noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
          loading={loading}
          keyField="key"
          responsive
          bordered={false}
          striped={false}
          columns={columns}
          data={data}
          wrapperClasses="table-responsive"
          classes={`list-table table align-middle table-nowrap${loading ? " loading" : ""}`}
          headerWrapperClasses={"thead-light"}
          bodyClasses="my-4"
        />
      </CardBody>
    </Card>
  );
}

export default SalesTargetStats;
