import ConfirmButton from "components/Common/ConfirmButton";
import CustomSpinner from "components/Common/CustomSpinner";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { deleteProductPackage, getProductPackages } from "store/product/services";
import ProductPackageForm from "./ProductPackageForm";

const initialPackageState = { open: false, packageId: null };
const ProductPackages = ({ productId, type, onChange }) => {
  const [packageState, setPackageState] = useState(initialPackageState);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const openPackageAddPopup = () => {
    setPackageState({ packageId: null, open: true });
  };
  const openPackageUpdatePopup = ({ id: packageId }) => {
    setPackageState({ packageId: packageId, open: true });
  };
  const handlePackageSaved = ({ id: packageId }) => {
    setPackageState({ packageId: null, open: false });
    loadPackages(productId);
    onChange && onChange();
  };
  const handlePackageUpdated = () => {
    setPackageState({ packageId: null, open: false });
    loadPackages(productId);
    onChange && onChange();
  };
  const handleClosePackage = () => {
    setPackageState({ packageId: null, open: false });
  };
  const loadPackages = async (productId) => {
    setLoading(true);
    try {
      const data = await getProductPackages(productId);
      setPackages(data);
    } finally {
      setLoading(false);
    }
  };
  const handleDeletePackage = async ({ id }) => {
    await deleteProductPackage(productId, id);
    loadPackages(productId);
    onChange && onChange();
  };
  useEffect(() => {
    loadPackages(productId);
    return () => {
      setPackages([]);
    };
  }, [productId]);

  const packageColumns = useMemo(
    () => [
      {
        text: "Index",
        dataField: "index",
        sort: true,
      },
      {
        text: "Width (cm)",
        dataField: "width",
        sort: true,
      },
      {
        text: "Height (cm)",
        dataField: "height",
        sort: true,
      },
      {
        text: "Depth (cm)",
        dataField: "depth",
        sort: true,
      },
      {
        text: "Weight (kg)",
        dataField: "weight",
        sort: true,
      },
      {
        text: "Volume (m3)",
        dataField: "volume",
        sort: true,
      },
      {
        dataField: "edit",
        text: "Edit",
        align: "center",
        headerAlign: "center",
        hidden: type === "view",
        formatter: (cell, row) => {
          return (
            <Button color="warning" size="sm" onClick={() => openPackageUpdatePopup(row)}>
              <i className="fa fa-edit"></i>
            </Button>
          );
        },
      },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        headerAlign: "center",
        hidden: type === "view",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeletePackage(row)}
              title="Are you sure you want to delete the package?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Packages</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openPackageAddPopup}>
              <i className="fa fa-plus"></i>Add Package
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && packages.length == 0 && "No packages found."}
          {!loading && packages.length > 0 && (
            <BootstrapTable
              keyField="id"
              defaultSorted={[{ dataField: "index", order: "asc" }]}
              data={packages}
              columns={packageColumns}
              wrapperClasses="table-responsive"
              classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
              headerWrapperClasses={"thead-light"}
            />
          )}
        </Col>
      </Row>
      {packageState.open && (
        <PackageModal
          onClose={handleClosePackage}
          productId={productId}
          packageId={packageState.packageId}
          onSave={handlePackageSaved}
          onUpdate={handlePackageUpdated}
        />
      )}
    </>
  );
};

const PackageModal = ({ packageId, onClose, onSave, onUpdate, productId }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!packageId ? "Add new package" : "Edit package"}</ModalHeader>
      <ModalBody>
        <ProductPackageForm productId={productId} packageId={packageId} onSave={onSave} onUpdate={onUpdate} />
      </ModalBody>
    </Modal>
  );
};

export default ProductPackages;
