import ConfirmButton from "components/Common/ConfirmButton";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { getShipmentProducts } from "../../store/shipment/services";
import { EnumValues } from "common/enums";

export default function ShipmentGoodsInPopup({ shipmentId, onClose, onSubmit }) {
  const [goodsInProducts, setGoodsInProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    const shipmentOrderedProducts = await getShipmentProducts(shipmentId);
    setGoodsInProducts(
      shipmentOrderedProducts.filter((s) => s.canShipment).map((s) => ({ ...s, userEntryCount: s.quantity }))
    );
  };

  const handleSubmit = async (data) => {
    const p = data.filter((p) => p.userEntryCount > 0);
    if (p.length == 0) {
      toast.error("Please select at least one product");
      return;
    }
    setLoading(true);
    try {
      await onSubmit(p);
    } finally {
      setLoading(false);
    }
  };

  const productColumns = [
    {
      text: "Product",
      dataField: "productVariant.productName",
    },
    {
      text: "Variant",
      dataField: "productVariant.name",
    },
    {
      text: "SKU",
      dataField: "sku",
    },
    {
      text: "Total Quantity",
      dataField: "quantity",
      align: "center",
    },
    {
      text: "Goods In Quantity",
      dataField: "userEntryCount",
      align: "center",
      style: { verticalAlign: "middle" },
      formatter: (cell, row) => {
        return (
          <NumberFormat
            value={row.userEntryCount}
            className="form-control w-80px py-1"
            allowNegative={false}
            onValueChange={({ value }) => {
              row.userEntryCount = value;
            }}
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue == null || (floatValue >= 0 && floatValue <= row.quantity);
            }}
          />
        );
      },
    },
    {
      text: "Delete",
      dataField: "delete",
      align: "center",
      style: { verticalAlign: "middle" },
      formatter: (cell, row) => {
        return (
          <Button
            color="danger"
            onClick={() => {
              goodsInProducts.splice(goodsInProducts.indexOf(row), 1);
              setGoodsInProducts([...goodsInProducts]);
            }}
          >
            <i className="fas fa-trash"></i>
          </Button>
        );
      },
    },
  ];
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Goods In</ModalHeader>
      <ModalBody>
        <BootstrapTable
          keyField="id"
          data={goodsInProducts}
          columns={productColumns}
          noDataIndication={"No data found"}
          wrapperClasses="table-responsive"
          classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
          headerWrapperClasses={"thead-light"}
        />
      </ModalBody>
      <ModalFooter>
        <ConfirmButton
          color="success"
          data={goodsInProducts}
          onConfirm={handleSubmit}
          title="Are you sure you want to goods-in?"
          disabled={loading}
        >
          {loading ? <Spinner color="white" size="sm" /> : "Goods In"}
        </ConfirmButton>
      </ModalFooter>
    </Modal>
  );
}
