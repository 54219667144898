import moment from "moment";

class ShipmentUtils {
  initialValues = () => ({
    id: null,
    status: "y",
    deliveryBranch: {},
    shipmentStatus: {},
    vehicleType: {},
    containerNo: "",
    plateNo: "",
    exitDate: new Date(),
    arrivalDate: new Date(),
    totalVehicleCapacity: "",
    totalVolumeType: {
      label: "M3",
      value: "M3",
    },
    productsVolume: "",
    productsVolumeType: {
      label: "M3",
      value: "M3",
    },
    cost: "",
    currency: {
      label: "GBP",
      value: "GBP",
    },
  });
  mapToSaveModel = ({
    id,
    status,
    deliveryBranch = {},
    shipmentStatus = {},
    vehicleType = {},
    containerNo = "",
    plateNo = "",
    exitDate,
    arrivalDate,
    totalVehicleCapacity = "",
    totalVolumeType = {},
    productsVolume = "",
    productsVolumeType = {},
    cost = "",
    currency = {},
  }) => ({
    id,
    status,
    deliveryBranchId: deliveryBranch?.value,
    shipmentStatus: shipmentStatus?.value,
    vehicleType: vehicleType?.value,
    containerNo,
    plateNo,
    exitDate: exitDate && moment(exitDate).utcOffset(0, true).format(),
    arrivalDate: arrivalDate && moment(arrivalDate).utcOffset(0, true).format(),
    totalVehicleCapacity,
    totalVolumeType: totalVolumeType?.value,
    productsVolume,
    productsVolumeType: productsVolumeType?.value,
    cost,
    currency: currency?.value,
  });

  mapToFormDataModel = ({
    id,
    status,
    deliveryBranch = {},
    shipmentStatus = "",
    vehicleType = "",
    containerNo = "",
    plateNo = "",
    exitDate,
    arrivalDate,
    totalVehicleCapacity = "",
    totalVolumeType = {},
    productsVolume = "",
    productsVolumeType = {},
    cost = "",
    currency = "",
  }) => ({
    id,
    status,
    deliveryBranch: {
      label: deliveryBranch?.name,
      value: deliveryBranch?.id,
    },
    shipmentStatus: {
      label: shipmentStatus,
      value: shipmentStatus,
    },
    vehicleType: {
      label: vehicleType,
      value: vehicleType,
    },
    containerNo,
    plateNo,
    exitDate: exitDate && moment(exitDate, "YYYY-MM-DD HH:mm:ss").toDate(),
    arrivalDate: arrivalDate && moment(arrivalDate, "YYYY-MM-DD HH:mm:ss").toDate(),
    totalVehicleCapacity,
    totalVolumeType: {
      label: totalVolumeType,
      value: totalVolumeType,
    },
    productsVolume,
    productsVolumeType: {
      label: productsVolumeType,
      value: productsVolumeType,
    },
    cost,
    currency: {
      label: currency,
      value: currency,
    },
  });

  mapToProductAddModel = ({ id, status = "y", variant = {}, quantity, notes }) => ({
    id,
    status,
    variantId: variant?.id,
    quantity,
    notes,
  });

  mapToOrderedProductAddModel = ({ orderedProduct, notes }) => ({
    id: orderedProduct?.id,
    notes,
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });
  initialFilterValues = () => ({
    shipmentNo: null,
    orderId: null,
    shipmentStatus: null,
    branchId: null,
    vehicleType: null,
    containerNo: "",
    plateNo: "",
    exitDateStart: null,
    exitDateEnd: null,
    arrivalDateStart: null,
    arrivalDateEnd: null,
    vehicleType: null,
  });

  mapToShipmentSearch = (
    filter = {
      shipmentNo: null,
      orderId: null,
      shipmentStatus: null,
      branchId: null,
      vehicleType: null,
      containerNo: "",
      plateNo: "",
      exitDateStart: null,
      exitDateEnd: null,
      arrivalDateStart: null,
      arrivalDateEnd: null,
      vehicleType: null,
    }
  ) => {
    return {
      shipmentNo: filter.shipmentNo,
      orderId: filter.orderId,
      statuses: filter.shipmentStatus ? [filter.shipmentStatus] : [],
      branchIds: filter.branchId ? [filter.branchId] : [],
      vehicleType: filter.vehicleType,
      containerNo: filter.containerNo,
      plateNo: filter.plateNo,
      vehicleType: filter.vehicleType,
      exitDateStart: filter.exitDateStart == null ? null : moment(filter.exitDateStart).format("YYYY-MM-DD"),
      exitDateEnd: filter.exitDateEnd == null ? null : moment(filter.exitDateEnd).format("YYYY-MM-DD"),
      arrivalDateStart: filter.arrivalDateStart == null ? null : moment(filter.arrivalDateStart).format("YYYY-MM-DD"),
      arrivalDateEnd: filter.arrivalDateEnd == null ? null : moment(filter.arrivalDateEnd).format("YYYY-MM-DD"),
    };
  };
}
const shipmentUtils = new ShipmentUtils();
export default shipmentUtils;
