import enums, { getEnumLabel } from "common/enums";
import ConfirmButton from "components/Common/ConfirmButton";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { deleteFinance } from "store/finance/services";
import { getOrderFinances } from "../../store/order/services";
import Finance from "../Finance/Finance";

const initialFinanceState = { open: false, financeId: null };
const OrderFinances = ({ orderId, type, onChange }) => {
  const [financeState, setFinanceState] = useState(initialFinanceState);
  const [finances, setFinances] = useState([]);
  const [loading, setLoading] = useState(false);
  const openFinanceAddPopup = () => {
    setFinanceState({ financeId: null, open: true });
  };
  const openFinanceUpdatePopup = ({ id: financeId }) => {
    setFinanceState({ financeId, open: true });
  };
  const handleFinanceSaved = ({ id: financeId }) => {
    setFinanceState({ financeId: null, open: false });
    loadFinances(orderId);
    onChange && onChange();
  };
  const handleFinanceUpdated = () => {
    setFinanceState({ financeId: null, open: false });
    loadFinances(orderId);
    onChange && onChange();
  };
  const handleCloseFinance = () => {
    setFinanceState({ financeId: null, open: false });
  };
  const loadFinances = async (orderId) => {
    setLoading(true);
    try {
      const data = await getOrderFinances(orderId);
      setFinances(data);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteFinance = async ({ id }) => {
    await deleteFinance(id);
    loadFinances(orderId);
    onChange && onChange();
  };
  useEffect(() => {
    loadFinances(orderId);
  }, [orderId]);

  const financeColumns = useMemo(
    () => [
      {
        text: "Date",
        dataField: "date",
        formatter: (cell) => <DateOutput date={cell} />,
      },
      {
        dataField: "paymentStatus",
        text: "Finance Status",
        // sort: true,
        formatter: (cell) => getEnumLabel(enums.financePaymentStatuses, cell),
      },
      {
        text: "Commission",
        dataField: "financeCommission",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} />,
      },
      {
        text: "Amount",
        dataField: "totalAmount",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} />,
      },
      {
        text: "Auth Code",
        dataField: "authCode",
      },
      {
        text: "Auth By",
        dataField: "authBy.fullName",
      },
      {
        text: "Finance Company",
        dataField: "financeCompanyRate.financeCompany.companyName",
      },
      {
        text: "Created By",
        dataField: "createUser",
      },
      {
        dataField: "edit",
        text: "Edit",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <Button color="warning" size="sm" onClick={() => openFinanceUpdatePopup(row)}>
              <i className="fa fa-edit"></i>
            </Button>
          );
        },
      },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteFinance(row)}
              title="Are you sure you want to delete the finance?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    []
  );
  const financeViewColumns = useMemo(
    () => [
      {
        text: "Date",
        dataField: "date",
        formatter: (cell) => <DateOutput date={cell} />,
      },
      {
        dataField: "paymentStatus",
        text: "Finance Status",
        // sort: true,
        formatter: (cell) => getEnumLabel(enums.financePaymentStatuses, cell),
      },
      {
        text: "Commission",
        dataField: "financeCommission",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} />,
      },
      {
        text: "Total Amount",
        dataField: "totalAmount",
        align: "right",
        headerAlign: "right",
        formatter: (cell, row) => <MoneyOutput value={cell} />,
      },
      {
        text: "Auth Code",
        dataField: "authCode",
      },
      {
        text: "Auth By",
        dataField: "authBy.fullName",
      },
      {
        text: "Created By",
        dataField: "createUser",
      },
      {
        text: "Finance Company",
        dataField: "financeCompanyRate.financeCompany.companyName",
      },
    ],
    []
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Finances</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openFinanceAddPopup}>
              <i className="fa fa-plus"></i>Add Finance
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && finances.length > 0 && (
            <div className="table-responsive">
              {type == "view" ? (
                <BootstrapTable
                  keyField="id"
                  data={finances}
                  columns={financeViewColumns}
                  wrapperClasses="table-responsive"
                  classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                  headerWrapperClasses={"thead-light"}
                />
              ) : (
                <BootstrapTable
                  keyField="id"
                  data={finances}
                  columns={financeColumns}
                  wrapperClasses="table-responsive"
                  classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                  headerWrapperClasses={"thead-light"}
                />
              )}
            </div>
          )}
        </Col>
      </Row>
      {financeState.open && (
        <FinanceModal
          onClose={handleCloseFinance}
          orderId={orderId}
          financeId={financeState.financeId}
          onSave={handleFinanceSaved}
          onUpdate={handleFinanceUpdated}
        />
      )}
    </>
  );
};

const FinanceModal = ({ financeId, onClose, onSave, onUpdate, orderId }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!financeId ? "Add new finance" : "Edit finance"}</ModalHeader>
      <ModalBody>
        <Finance orderId={orderId} financeId={financeId} onSave={onSave} onUpdate={onUpdate} />
      </ModalBody>
    </Modal>
  );
};

export default OrderFinances;
