import PropTypes from "prop-types";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import "./ForgetPassword.css";

//redux

import { Link, withRouter } from "react-router-dom";

// Formik Validation
import { useFormik } from "formik";
import * as Yup from "yup";

// import images
import { forgotPassword } from "store/auth/services";
import logo from "../../assets/images/logo/logo.png";
import profile from "../../assets/images/profile-img.png";
import bg from "assets/images/login/bg.jpg";
import loginbg from "assets/images/login/loginbg.png";

const ForgetPasswordNewPage = (props) => {
  const [forgetError, setForgetError] = useState();
  const [forgetSuccessMsg, setForgetSuccessMsg] = useState();
  const [loading, setLoading] = useState(false);

  async function handleForgotPassword(email) {
    setLoading(true);
    try {
      await forgotPassword(email);
      setForgetSuccessMsg(
        `We sent a password reset link to your "${email}" email address, if you didn't get, you can retry by this page`
      );
      setForgetError(null);
      validation.resetForm();
    } catch (e) {
      setForgetSuccessMsg(null);
      setForgetError(e.response?.data?.message || "Unknown error occured");
    } finally {
      setLoading(false);
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email").email(),
    }),
    onSubmit: (values) => {
      handleForgotPassword(values.email);
    },
  });

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | Novia Furniture CRM</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div
        className="account-pages my-5 pt-sm-5"
        style={{
          backgroundImage: "url(" + bg + ")",
          height: "100vh",
          position: "absolute",
          top: -50,
          left: 0,
          width: "100%",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <div
              style={{
                width: "450px",
                height: "650px",
                paddingTop: "100px",
                // backgroundSize: "cover",
                // color: "white",
                // border: "1px solid white",
              }}
            >
              <div
                style={{
                  backgroundImage: "url(" + loginbg + ")",
                  color: "white",
                  // border: "1px solid white",
                  width: "100%",
                  height: "100%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="p-2">
                  <Form
                    className="form-horizontal reset-form"
                    style={{ margin: "45% 50px 50px 50px" }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control emailInput"
                        placeholder="Enter email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={validation.touched.email && validation.errors.email ? true : false}
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
                            {loading ? <Spinner color="white" size="sm" /> : "Reset"}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div className="mt-5 text-center">
                    <p>
                      Go back to{" "}
                      <Link to="login" className="font-weight-medium text-muted">
                        Login
                      </Link>{" "}
                    </p>
                    <p>© {new Date().getFullYear()} Novia.</p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordNewPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordNewPage);
