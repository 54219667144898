import ConfirmButton from "components/Common/ConfirmButton";
import CustomSpinner from "components/Common/CustomSpinner";
import ImagesModal from "components/Common/ImagesModal";
import OrderedProductModal from "pages/Order/OrderedProductModal";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, Row } from "reactstrap";
import { searchOrderedProducts } from "store/order/ordered-product-services";
import { getVariantOrProductImages } from "store/product/services";

const initialOrderedProductState = { open: false, error: null, loading: false };
const TicketProducts = ({ ticket, onAdd, onDelete }) => {
  const { orderedProducts: products } = ticket;
  const customerId = ticket.customerId;
  const orderId = ticket.order?.value;
  const orderedProductFilter = {
    orderIds: orderId && [orderId],
    customerIds: customerId && [customerId],
  };
  const [orderedProductState, setOrderedProductState] = useState(initialOrderedProductState);
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const [loading, setLoading] = useState(false);
  const openOrderedProductAddPopup = () => {
    setOrderedProductState((prev) => ({ ...prev, product: {}, open: true }));
  };
  const handleProduct = (products) => {
    onAdd(products);
    setOrderedProductState((prev) => ({ ...prev, open: false }));
  };

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        headerAlign: "center",
        align: "center",
        formatter: (productVariant) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: productVariant.productId,
                variantId: productVariant.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "Product",
        dataField: "productVariant.productName",
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
      },
      {
        text: "SKU",
        dataField: "sku",
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
      },
      {
        text: "Quantity",
        dataField: "quantity",
        headerAlign: "right",
        align: "right",
      },
      {
        text: "Delete",
        dataField: "delete",
        headerAlign: "center",
        align: "center",
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => onDelete(row)}
              title="Are you sure you want to delete the product?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    []
  );
  const expandRow = useMemo(
    () => ({
      showExpandColumn: false,
      expandByColumnOnly: true,
      expanded: products.filter((i) => i.ticketNotes || i.description).map((item, i) => item.id),
      renderer: (row) => (
        <>
          <div className="w-100 row">
            <div className="col-2 col-xs-1 d-flex justify-content-end">
              <b>Note:</b>
            </div>
            <span className="col-10 col-xs-11 d-flex justify-content-start text-nowrap">
              {row.ticketNotes || row.description}
            </span>
          </div>
        </>
      ),
    }),
    [products]
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
        {(customerId || orderId) && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" outline onClick={openOrderedProductAddPopup}>
              <i className="fa fa-plus"></i>Add Product
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && products.length > 0 && (
            <BootstrapTable
              keyField="id"
              data={products}
              columns={productColumns}
              wrapperClasses="table-responsive"
              classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
              headerWrapperClasses={"thead-light"}
              expandRow={expandRow}
            />
          )}
        </Col>
      </Row>
      {orderedProductState.open && (
        <OrderedProductModal
          multiple
          showProductName
          showVariantName
          service={() =>
            searchOrderedProducts(orderedProductFilter).then((data) =>
              data.content.filter((item) => products.find((p) => p.id == item.id) == null)
            )
          }
          onClose={() => setOrderedProductState({ ...initialOrderedProductState })}
          onSelect={handleProduct}
        />
      )}
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

export default TicketProducts;
