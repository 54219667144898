import DateTimeOutput from "components/Common/DateTimeOutput";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";
import React from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import {
  createColorChart,
  deleteColorChart,
  editColorChart,
  getColorCharts,
  getColors,
  setColorsOfColorChart,
} from "store/definitions/services";
import * as Yup from "yup";

const ColorCharts = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "colors",
      text: "Colors",
      sort: true,
      formatter: (cellContent, row, index) => {
        if (row.colors.length == 0) {
          return null;
        }
        if (row.colors.length == 1) {
          return row.colors[0].name;
        }
        const targetId = `targetProduct${index}`;
        return (
          <div>
            <span
              href="#"
              id={targetId}
              style={{
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {row.colors[0].nameFirstMaterial} ...
            </span>
            <UncontrolledTooltip placement="top" target={targetId}>
              {row.colors.map((item) => item.nameFirstMaterial).join(", ")}
            </UncontrolledTooltip>
          </div>
        );
      },
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
      formatter: (cell, row) => <DateTimeOutput date={cell} />,
    },
  ];

  const createColorChartApi = async (data) => {
    const color = await createColorChart(data);
    return setColorsOfColorChart(color.id, { ids: data.colorIds });
  };
  const updateColorChartApi = async (id, data) => {
    const color = await editColorChart(id, data);
    return setColorsOfColorChart(color.id, { ids: data.colorIds });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Color Chars</title>
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getColorCharts,
            deleteapi: deleteColorChart,
            createapi: createColorChartApi,
            updateapi: updateColorChartApi,
            confirmUpdate:
              "Added or deleted colors will be added or deleted in all products to which this color palette is attached. Are you sure you want to update anyway?",
          }}
          tableColumns={columns}
          formValues={{
            values: [
              { name: "name", label: "Name", size: 12, type: "text" },
              { name: "description", label: "Description", size: 12, type: "textarea" },
              { name: "status", label: "status", size: 12, type: "checkbox" },
              {
                name: "colorIds",
                label: "Colors",
                size: 12,
                type: "select",
                isMulti: true,
                getService: getColors,
                labelField: "nameFirstMaterial",
                getValue: (value) => {
                  return value.colors.map((item) => item.id);
                },
              },
            ],
            validationSchema: {
              name: Yup.string().required("Name is required"),
              description: Yup.string().nullable(),
              status: Yup.string(),
              colorIds: Yup.array().default([]).min(1, "Color is required"),
            },
          }}
          pageDetails={{
            definitionName: "Color Chart",
            title: "Color Charts",
          }}
          privilege={{
            create: Privileges.COLOR_CHART.CREATE,
            update: Privileges.COLOR_CHART.UPDATE,
            delete: Privileges.COLOR_CHART.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ColorCharts);
