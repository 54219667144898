import axios from "axios";

const axiosApi = axios.create({
  baseURL: "https://ws.postcoder.com/pcw",
});
const API_KEY = "PCWN9-6DJTU-KBU3F-67ZB9";
// const API_KEY = "PCW9A-K4PJF-5N73J-VYCX7";
class AddressService {
  addressSuggestion = (query) =>
    axiosApi
      .get(`/${API_KEY}/address/uk/${query}`, {
        params: {
          identifier: "noviacrm",
          lines: "1",
          exclude: "postcode,county,posttown",
        },
      })
      .then((res) => res.data);

  setCustomerAddressFields({ setFieldValue, address }) {
    setFieldValue("address1", address.addressline1 || address.summaryline);
    setFieldValue("address2", "");
    setFieldValue("postCode", address.postcode);
    setFieldValue("town", address.county);
    setFieldValue("city", address.posttown);
  }
}
export const addressService = new AddressService();
