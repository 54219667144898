import CustomSpinner from "components/Common/CustomSpinner";
import MoneyOutput from "components/Common/MoneyOutput";
import NumberOutput from "components/Common/NumberOutput";
import ReactECharts from "echarts-for-react";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { getDashboard } from "store/dashboard/services";
import DashboardFilterForm from "./DashboardFilterForm";
import { dashboardAppliedFilterState, dashboardState } from "./atoms";
import dashboardUtils from "./dashboard_utils";
import Privileges from "models/Privileges";
import PercentageOutput from "components/Common/PercentageOutput";

function Dashboard({}) {
  const [loading, setLoading] = useState(false);
  const activeUser = useActiveUser();

  const [dashboard, setDashboard] = useRecoilState(dashboardState);
  const [appliedFilter, setAppliedFilter] = useRecoilState(dashboardAppliedFilterState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const { data } = dashboard;

  useEffect(() => {
    if (dashboard.lastUpdateTime == null || new Date().getTime() - dashboard.lastUpdateTime.getTime() > 5 * 60000) {
      loadDashboard(appliedFilter);
    }
  }, []);

  const loadDashboard = async (filter) => {
    setLoading(true);
    try {
      const data = await getDashboard(dashboardUtils.mapToOrderSearch(filter));
      setDashboard({
        data,
        lastUpdateTime: new Date(),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    loadDashboard(appliedFilter);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const handleRefresh = () => {
    loadDashboard(appliedFilter);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Novia Furniture CRM</title>
        </MetaTags>
        {!activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
          <Container fluid>Welcome to Novia Furniture CRM</Container>
        )}
        {activeUser.hasPrivilege(Privileges.DASHBOARD.MENU) && (
          <Container fluid>
            <Row className="pb-2">
              <Col>
                <div className="nv-grid">
                  <div className="nv-row">
                    <h4>Dashboard</h4>
                  </div>
                </div>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <Button color="primary" size="sm" outline onClick={handleRefresh} disabled={loading}>
                  Refresh
                </Button>
                {dashboard.lastUpdateTime && (
                  <div className="text-info">
                    <small className="text-info">
                      Last Updated:{" "}
                      <b>
                        {Number((new Date().getTime() - dashboard.lastUpdateTime.getTime()) / 60000).toFixed(0)} minutes
                        ago
                      </b>
                    </small>
                  </div>
                )}
              </Col>
            </Row>
            <Card>
              <Row>
                <Col className="col-12">
                  <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                        <div className="px-2">Filter Form</div>
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <DashboardFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </Col>
              </Row>
            </Card>
            {loading && <CustomSpinner />}
            {data && !loading && (
              <Row>
                <Col md={12}>
                  <Card className="shadow-lg">
                    <CardBody>
                      <h2>Orders</h2>
                      <Row className="py-1">
                        <Col sm={8}>
                          <Row>
                            <Col sm={6}>
                              <DashboardCard label="Revenue" value={data.revenue} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Orders" value={data.orders} />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Coefficient" value={data.coefficient} />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Product Sold" value={data.totalProductSold} />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Cancelled" value={data.cancelledAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Cancelled" value={data.cancelledCount} />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Avg. Discount Rate" value={data.averageDiscountRate} isPercentage />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Avg. Basket" value={data.averageBasketAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Open Balance" value={data.openBalance} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Refund" value={data.refund} isAmount />
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={4} style={{ height: "100%" }}>
                          <ReactECharts
                            style={{ backgroundColor: "#2a3042", color: "#fff", borderRadius: 10, height: 480 }}
                            option={{
                              title: {
                                textStyle: { color: "#fff" },
                                text: "Store Revenues",
                                padding: 15,
                                // subtext: "Purely fictitious",
                                x: "left",
                              },
                              tooltip: {
                                trigger: "item",
                                formatter: "{b} : {c} ({d}%)",
                              },
                              legend: {
                                orient: "horizontal",
                                left: "left",
                                bottom: 0,
                                padding: 15,
                                textStyle: { color: "#fff" },
                                data: [
                                  ...data.branchRevenues.filter((item) => item.amount > 0).map((item) => item.name),
                                ],
                              },
                              series: [
                                {
                                  // name: "access source",
                                  type: "pie",
                                  radius: "55%",
                                  // center: ["50%", "60%"],
                                  data: [
                                    ...data.branchRevenues
                                      .filter((item) => item.amount > 0)
                                      .map((item) => ({
                                        name: item.name,
                                        value: item.amount,
                                      })),
                                  ],
                                  label: {
                                    formatter: "{b} ({d}%)",
                                  },
                                },
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                      <div className="pt-4">
                        <h2>Payment With</h2>
                      </div>
                      <Row className="py-1">
                        <Col sm={8}>
                          <Row>
                            <Col sm={6}>
                              <DashboardCard label="Card" value={data.paymentWithCardAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Cash" value={data.paymentWithCashAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Finance" value={data.paymentFinanceAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard
                                label="Bank Transfer"
                                value={data.paymentWithBankTransferAmount}
                                isAmount
                              />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Store Credit" value={data.paymentWithStoreCredit} isAmount />
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={4} style={{ height: "100%" }}>
                          <ReactECharts
                            style={{ backgroundColor: "#2a3042", color: "#fff", borderRadius: 10, height: 280 }}
                            option={{
                              title: {
                                textStyle: { color: "#fff" },
                                text: "Payment With",
                                padding: 15,
                                x: "left",
                              },
                              tooltip: {
                                trigger: "item",
                                formatter: "{b} : {c} ({d}%)",
                              },
                              legend: {
                                orient: "horizontal",
                                left: "left",
                                bottom: 0,
                                padding: 15,
                                textStyle: { color: "#fff" },
                                data: [
                                  { name: "Card", value: data.paymentWithCardAmount },
                                  { name: "Cash", value: data.paymentWithCashAmount },
                                  { name: "Finance", value: data.paymentFinanceAmount },
                                  { name: "Bank T.", value: data.paymentWithBankTransferAmount },
                                ],
                              },
                              series: [
                                {
                                  // name: "access source",
                                  type: "pie",
                                  radius: "55%",
                                  // center: ["50%", "60%"],
                                  data: [
                                    { name: "Card", value: data.paymentWithCardAmount },
                                    { name: "Cash", value: data.paymentWithCashAmount },
                                    { name: "Finance", value: data.paymentFinanceAmount },
                                    { name: "Bank T.", value: data.paymentWithBankTransferAmount },
                                  ],
                                  label: {
                                    formatter: "{b} ({d}%)",
                                  },
                                },
                              ],
                            }}
                          />
                        </Col>
                      </Row>

                      <div className="pt-4">
                        <h2>Cash Flow</h2>
                      </div>
                      <Row className="py-1">
                        <Col sm={8}>
                          <Row>
                            <Col sm={6}>
                              <DashboardCard label="Money In" value={data.moneyIn} isAmount />
                            </Col>
                            <Col sm={6}></Col>
                            <Col sm={6}>
                              <DashboardCard label="Card" value={data.moneyInCardAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Cash" value={data.moneyInCashAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard
                                label="Finance"
                                value={data.moneyInFinanceAmount}
                                isAmount
                                calcDetail={
                                  <>
                                    {" ("}
                                    <MoneyOutput value={data.moneyInFinanceAmountTotal} />
                                    {" - "}
                                    <MoneyOutput value={data.moneyInFinanceAmountCommision} />
                                    {")"}
                                  </>
                                }
                              />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Bank Transfer" value={data.moneyInBankTransferAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Store Credit" value={data.moneyInStoreCreditAmount} isAmount />
                            </Col>
                            <Col sm={6}></Col>
                            <Col sm={12}>
                              <h4>Payment Type</h4>
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Deposit" value={data.paymentDepositAmount} isAmount />
                            </Col>
                            <Col sm={6}>
                              <DashboardCard label="Balance" value={data.paymentBalanceAmount} isAmount />
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={4} style={{ height: "100%" }}>
                          <ReactECharts
                            style={{ backgroundColor: "#2a3042", color: "#fff", borderRadius: 10, height: 520 }}
                            option={{
                              title: {
                                textStyle: { color: "#fff" },
                                text: "Cash Flow",
                                padding: 15,
                                x: "left",
                              },
                              tooltip: {
                                trigger: "item",
                                formatter: "{b} : {c} ({d}%)",
                              },
                              legend: {
                                orient: "horizontal",
                                left: "left",
                                bottom: 0,
                                padding: 15,
                                textStyle: { color: "#fff" },
                                data: [
                                  { name: "Card", value: data.moneyInCardAmount },
                                  { name: "Cash", value: data.moneyInCashAmount },
                                  { name: "Finance", value: data.moneyInFinanceAmount },
                                  { name: "Bank T.", value: data.moneyInBankTransferAmount },
                                ],
                              },
                              series: [
                                {
                                  // name: "access source",
                                  type: "pie",
                                  radius: "55%",
                                  // center: ["50%", "60%"],
                                  data: [
                                    { name: "Card", value: data.moneyInCardAmount },
                                    { name: "Cash", value: data.moneyInCashAmount },
                                    { name: "Finance", value: data.moneyInFinanceAmount },
                                    { name: "Bank T.", value: data.moneyInBankTransferAmount },
                                  ],
                                  label: {
                                    formatter: "{b} ({d}%)",
                                  },
                                },
                              ],
                            }}
                          />
                        </Col>
                      </Row>

                      <div className="pt-4">
                        <h2>Proformas</h2>
                      </div>
                      <Row className="py-1">
                        <Col sm={3}>
                          <DashboardCard label="Proforma" value={data.totalProformaAmount} isAmount />
                        </Col>
                        <Col sm={3}>
                          <DashboardCard label="Proforma" value={data.totalProformaCount} />
                        </Col>
                      </Row>

                      <div className="pt-4">
                        <h2>Finance</h2>
                      </div>
                      <Row className="py-1">
                        <Col sm={3}>
                          <DashboardCard label="Open Balance" value={data.financeOpenBalance} isAmount />
                        </Col>
                        <Col sm={3}>
                          <DashboardCard label="Take Payment" value={data.financeTakePayments} />
                        </Col>
                        <Col sm={3}>
                          <DashboardCard label="Open Commissions" value={data.financeOpenCommissions} isAmount />
                        </Col>
                        <Col sm={3}>
                          <DashboardCard label="Paid Commissions" value={data.financePaidCommissions} isAmount />
                        </Col>
                      </Row>

                      <div className="pt-4">
                        <h2>Products In Process</h2>
                      </div>
                      <Row className="py-1">
                        <Col sm={3}>
                          <DashboardCardCountAmount
                            subHeader="In Production"
                            label="Open Balance"
                            count={data.inProductionProductCount}
                            amount={data.inProductionOpenBalance}
                            iconClassName="bx bx-cog"
                          />
                        </Col>
                        <Col sm={3}>
                          <DashboardCardCountAmount
                            subHeader="In Shipment"
                            label="Open Balance"
                            count={data.inShipmentProductCount}
                            amount={data.inShipmentOpenBalance}
                            iconClassName="bx bxs-ship"
                          />
                        </Col>
                        <Col sm={3}>
                          <DashboardCardCountAmount
                            subHeader="Waiting For Delivery"
                            label="Open Balance"
                            count={data.inWaitingForDeliveryProductCount}
                            amount={data.inWaitingForDeliveryOpenBalance}
                            iconClassName="bx bx-history"
                          />
                        </Col>
                        <Col sm={3}>
                          <DashboardCardCountAmount
                            subHeader="Delivery Planned"
                            label="Open Balance"
                            count={data.inDeliveryPlannedProductCount}
                            amount={data.inDeliveryPlannedOpenBalance}
                            iconClassName="bx bxs-truck"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}

function DashboardCard({ label, value, isAmount, isPercentage, calcDetail }) {
  return (
    <Card style={{ backgroundColor: "rgb(42 48 66)", padding: 0 }}>
      <CardBody className="pb-0 pt-2">
        <div className="d-flex align-items-center gap-2">
          <h2 style={{ color: "#fff" }}>
            {isAmount ? (
              <MoneyOutput value={value} />
            ) : isPercentage ? (
              <PercentageOutput value={value} />
            ) : (
              <NumberOutput value={value} minScale={0} />
            )}
          </h2>
          {calcDetail && <h4 style={{ color: "#fff" }}>{calcDetail}</h4>}
        </div>
        <h4 style={{ color: "#959dabfa" }}>{label}</h4>
      </CardBody>
    </Card>
  );
}
function DashboardCardCountAmount({ label, count, amount, subHeader, iconClassName }) {
  return (
    <Card style={{ backgroundColor: "rgb(42 48 66)", padding: 0 }}>
      <CardBody className="pb-0 pt-2">
        <div className="d-flex">
          <h2 style={{ color: "#fff", width: "50%" }}>
            <NumberOutput value={count} minScale={0} />
          </h2>
          {iconClassName && (
            <div className="d-flex justify-content-end" style={{ color: "#fff", width: "50%" }}>
              <i className={iconClassName} style={{ fontSize: 18 }}></i>
            </div>
          )}
        </div>
        {subHeader && <h4 style={{ color: "#959dabfa", width: "100%" }}>{subHeader}</h4>}
        <div className="d-flex">
          <h4 style={{ color: "#959dabfa", width: "50%" }}>{label}</h4>
          <h2 className="d-flex justify-content-end" style={{ color: "#fff", width: "50%" }}>
            <MoneyOutput value={amount || 0} />
          </h2>
        </div>
      </CardBody>
    </Card>
  );
}

export default Dashboard;
