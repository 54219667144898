import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import Report from "./Report";

const ReportPage = (props) => {
  const { id } = useParams();
  return (
    <>
      <MetaTags>
        <title>Novia - Report</title>
      </MetaTags>
      <Report id={id} />
    </>
  );
};

ReportPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ReportPage);
