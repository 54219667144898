import { get } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

export const getActionLogs = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/actionLogs?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );
export const getRecordHistory = (pageOpt = { page: 0, size: 100 }, filter, sort) =>
  get(
    `/actionLogs/recordHistory?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${
      pageOpt.page
    }&size=${pageOpt.size}&&${toQueryParams(filter)}`
  );
