import React, { useState } from "react";
import { Col } from "reactstrap";

import DateTimeOutput from "components/Common/DateTimeOutput";
import { useRemotePaging } from "hooks/usePaging";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Row } from "reactstrap";
import { getRecordHistory } from "store/actionLog/services";

const RecordActionLogs = ({ recordId = 0, actionModels = [], parentActions = [], title }) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [appliedSort, setAppliedSort] = useState({ sortField: "createdDate", sortOrder: "desc" });
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("ActionLogs." + title);
  const loadActionLogs = async (page, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getRecordHistory(page, { recordOrParentRecordId: recordId, actionModels, parentActions }, sort);
      setTotalSize(totalElements);
      setOrders(content);
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (type, details) => {
    // console.log("handlePageChange", details, type);
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadActionLogs(setPageNumber(page, sizePerPage), appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField, sortOrder };
      setAppliedSort(newSort);
      loadActionLogs(pageOptions, newSort);
    }
  };

  const columns = [
    {
      dataField: "createdDate",
      text: "Create Date",
      sort: true,
      formatter: (cell, row) => <DateTimeOutput date={row.createdDate} />,
    },
    {
      dataField: "createUser",
      text: "Create User",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
    },
    {
      dataField: "process",
      text: "Process",
      sort: true,
    },
    // {
    //   text: "Resource",
    //   dataField: "actionModel",
    //   sort: true,
    //   formatter: (cell) => getEnumLabel(enums.actionModels, cell),
    // },
    // {
    //   text: "Record ID",
    //   dataField: "recordId",
    //   sort: true,
    // },
    // {
    //   text: "Parent Resource",
    //   dataField: "parentAction",
    //   sort: true,
    //   formatter: (cell) => getEnumLabel(enums.parentActionTypes, cell),
    // },
    // {
    //   text: "Parent Record ID",
    //   dataField: "parentId",
    //   sort: true,
    // },
    // {
    //   text: "Record ID",
    //   dataField: "recordId",
    //   sort: true,
    // },
  ];
  return (
    <React.Fragment>
      <Row>
        <Col xl="12">
          <BootstrapTable
            hover
            noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
            loading={loading}
            defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
            keyField="id"
            responsive
            bordered={true}
            striped={false}
            remote
            pagination={paginationFactory(pageOptions)}
            columns={columns}
            data={orders}
            onTableChange={handlePageChange}
            wrapperClasses="table-responsive"
            classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
            headerWrapperClasses={"thead-light"}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RecordActionLogs;
