import { PropTypes } from "prop-types";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import { EnumValues } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import DateOutput from "components/Common/DateOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import OrderStatusLabel from "pages/Order/OrderStatusLabel";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
  Spinner,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { downloadService } from "services/DownloadService";
import { deleteOrder, exportProformasToCsv, getProformaDetailsPdf, getProformas } from "store/order/services";
import ProformaCancelStatusLabel from "./ProformaCancelStatusLabel";
import ProformaFilterForm from "./ProformaFilterForm";
import { proformasAppliedFilterState, proformasAppliedSortState } from "./atoms";
import proformaUtils from "./proforma_utils";

const Proformas = ({ history }) => {
  const activeUser = useActiveUser();
  const [deleteConfirmState, setDeleteConfirmState] = useState({
    open: false,
    id: null,
  });
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [appliedFilter, setAppliedFilter] = useRecoilState(proformasAppliedFilterState);
  const [appliedSort, setAppliedSort] = useRecoilState(proformasAppliedSortState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Proformas");
  const [loadingExportToCsv, setLoadingExportToCsv] = useState(false);
  const loadOrders = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getProformas(page, proformaUtils.mapToProformaSearch(filter), sort);
      setTotalSize(totalElements);
      setOrders(content);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   loadOrders(pageOptions, appliedFilter, appliedSort);
  // }, []);
  const handleDelete = async (id) => {
    try {
      await deleteOrder(id);
      toast.info("Proforma deleted successfully");
      loadOrders(pageOptions, appliedFilter, appliedSort);
    } finally {
      setDeleteConfirmState({ open: false });
    }
  };
  const handlePageChange = (type, details) => {
    // console.log("handlePageChange", details, type);
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadOrders(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField, sortOrder };
      setAppliedSort(newSort);
      loadOrders(pageOptions, appliedFilter, newSort);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadOrders(newPage, appliedFilter, appliedSort);
    setHasFilter(hasAnyValue(appliedFilter));
    // setShowFilter(false);
  };

  const handleDownloadProformaDetails = async (orderId) => {
    const data = await getProformaDetailsPdf(orderId);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };

  const handleExportToCsv = async () => {
    setLoadingExportToCsv(true);
    try {
      const data = await exportProformasToCsv(
        pageOptions,
        proformaUtils.mapToProformaSearch(appliedFilter),
        appliedSort
      );
      downloadService.downloadBufferedData({
        data,
        contentType: "text/csv",
        fileName: "proformas.csv",
      });
    } finally {
      setLoadingExportToCsv(false);
    }
  };

  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      align: "center",
      headerAlign: "center",
      hidden:
        !activeUser.hasPrivilege(Privileges.PROFORMA.UPDATE_PF) &&
        !activeUser.hasPrivilege(Privileges.PROFORMA.UPDATE) &&
        !activeUser.hasPrivilege(Privileges.PROFORMA.DELETE) &&
        !activeUser.hasPrivilege(Privileges.PROFORMA.DETAIL),
      formatter: (cell, row) => {
        return (
          <ActionMenu>
            <DropdownMenu>
              {activeUser.hasPrivilege(Privileges.PROFORMA.UPDATE_PF) && (
                <DropdownItem onClick={() => history.push(`/order/${row.id}/payments-and-finances`)}>
                  Payments & Finances
                </DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.PROFORMA.UPDATE) && (
                <DropdownItem onClick={() => history.push(`/order/${row.id}`)}>Edit Proforma</DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.PROFORMA.DELETE) && (
                <DropdownItem
                  onClick={() => {
                    setDeleteConfirmState({ open: true, id: row.id });
                  }}
                >
                  Delete Proforma
                </DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.PROFORMA.DETAIL) && (
                <DropdownItem onClick={() => handleDownloadProformaDetails(row.id)}>Proforma Details PDF</DropdownItem>
              )}
            </DropdownMenu>
          </ActionMenu>
        );
      },
    },
    {
      dataField: "b.name",
      text: "Store",
      sort: true,
      formatter: (cell, row) => row.branch?.name,
    },
    {
      text: "Proforma Number",
      dataField: "proformaId",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
              <Link to={`/order/${row.id}/detail`} title="View proforma detail">
                {cell}
              </Link>
            )}
            {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && cell}
          </>
        );
      },
      // sort: true,
    },
    {
      dataField: "customer",
      text: "Customer",
      sort: true,
      formatter: (cell) => cell.fullName,
    },
    {
      text: "Total",
      dataField: "netPrice",
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => <MoneyOutput value={cell} currency={row.priceCurrency || "GBP"} />,
      // sort: true,
    },
    {
      text: "Proforma Date",
      dataField: "updateDate",
      sort: true,
      formatter: (cell) => <DateOutput date={cell} />,
    },
    {
      dataField: "salesPerson",
      text: "Sales Person",
      // sort: true,
      formatter: (cell) => cell.fullName,
    },
    {
      dataField: "proformaCancelStatus",
      text: "Proforma Status Detail",
      align: "center",
      headerAlign: "center",
      // sort: true,
      formatter: (cell, row) => {
        if (
          row.orderId == null &&
          (row.orderStatus == EnumValues.ORDER_STATUS.PROFORMA || row.orderStatus == EnumValues.ORDER_STATUS.CANCELLED)
        ) {
          return <ProformaCancelStatusLabel status={cell} />;
        }
        return null;
      },
    },
    {
      dataField: "orderStatus",
      text: "Proforma Status",
      align: "center",
      headerAlign: "center",
      // sort: true,
      formatter: (cell) => <OrderStatusLabel orderStatus={cell} />,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Proformas</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <ProformaFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex flex-wrap justify-content-between">
                      <h4>Proformas</h4>
                      <div className="d-flex flex-wrap justify-content-end gap-2 pb-2">
                        {orders.length > 0 && (
                          <Button outline size="sm" onClick={handleExportToCsv} disabled={loadingExportToCsv}>
                            Export to CSV {loadingExportToCsv && <Spinner size="sm" />}
                          </Button>
                        )}
                        {activeUser.hasPrivilege(Privileges.PROFORMA.CREATE) && (
                          <Button color="primary" size="sm" onClick={() => history.push(`/create-order`)}>
                            <i className="fa fa-plus"></i> New Proforma
                          </Button>
                        )}
                      </div>
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
                        keyField="id"
                        responsive
                        bordered={true}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={orders}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {deleteConfirmState.open && (
          <SweetAlert
            title="Are you sure you want to delete the proforma"
            warning
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleDelete(deleteConfirmState.id)}
            onCancel={() => {
              setDeleteConfirmState({ open: false });
            }}
          ></SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

Proformas.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Proformas);
