import { useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card } from "reactstrap";
import RecordActionLogs from "./RecordActionLogs";

const ActionLogsCard = ({ onClose, recordId = 0, actionModels = [], parentActions = [], title }) => {
  const [open, setOpen] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const handleToggleExpantion = () => {
    setOpen((prev) => !prev);
    if (!showLogs) {
      setShowLogs(true);
    }
  };
  return (
    <Card className="shadow-lg">
      <Accordion open={open ? "1" : undefined} toggle={handleToggleExpantion}>
        <AccordionItem>
          <AccordionHeader targetId="1">
            <h4 className="text-secondary">
              <b>{title || "Action Logs"}</b>
            </h4>
          </AccordionHeader>
          <AccordionBody accordionId="1">
            {showLogs && (
              <RecordActionLogs
                title={title || "Action Logs"}
                recordId={recordId}
                actionModels={actionModels}
                parentActions={parentActions}
              />
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default ActionLogsCard;
