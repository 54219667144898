import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";
import {
  createAssProblemSubject,
  deleteAssProblemSubject,
  editAssProblemSubject,
  getAssProblemSubjects,
} from "store/definitions/services";

const AssProblemSubjects = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>After-Sales Service Problem Subjects</title>
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getAssProblemSubjects,
            deleteapi: deleteAssProblemSubject,
            createapi: createAssProblemSubject,
            updateapi: editAssProblemSubject,
          }}
          tableColumns={columns}
          formValues={{
            values: [
              { name: "name", label: "Name", size: 12, type: "text" },
              { name: "status", label: "status", size: 12, type: "checkbox" },
            ],
            validationSchema: {
              name: Yup.string().required("Name is required"),
              status: Yup.string(),
            },
          }}
          pageDetails={{
            definitionName: "ASS Problem Subject",
            title: "ASS Problem Subjects",
          }}
          privilege={{
            create: Privileges.ASS_PROBLEM_SUBJECT.CREATE,
            update: Privileges.ASS_PROBLEM_SUBJECT.UPDATE,
            delete: Privileges.ASS_PROBLEM_SUBJECT.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(AssProblemSubjects);
