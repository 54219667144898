import enums, { getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import CustomerCard from "pages/Customers/CustomerCard";
import OrderCard from "pages/Order/OrderCard";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardTitle, Col, DropdownItem, DropdownMenu, Row } from "reactstrap";
import { downloadService } from "services/DownloadService";
import { getTicket, getTicketPdf } from "store/ass/services";

const { getCustomer } = require("store/customer/services");

const TicketCard = ({ id }) => {
  const activeUser = useActiveUser();
  const [ticketState, setTicketState] = useState({
    error: null,
    loading: false,
    ticket: null,
  });
  const { ticket } = ticketState;
  const loadTicket = async () => {
    setTicketState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const ord = await getTicket(id);
      setTicketState((state) => ({
        ...state,
        ticket: ord,
        loading: false,
      }));
    } catch (error) {
      setTicketState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  const handleDownloadTicket = async () => {
    const data = await getTicketPdf(id);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };
  useEffect(() => {
    loadTicket();
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        {ticketState.loading && <CustomSpinner />}
        {!ticketState.loading && ticketState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Ticket could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {!ticketState.loading && ticketState.ticket && (
          <>
            <Row className="py-1">
              <h6 className="col-sm-2">Ticket Number</h6>
              <Col sm={4}>
                <h5>
                  {activeUser.hasPrivilege(Privileges.ASS.DETAIL) && (
                    <Link to={`/ticket/${ticket.id}/detail`} title="View ticket detail">
                      {ticket.id}
                    </Link>
                  )}
                  {!activeUser.hasPrivilege(Privileges.ASS.DETAIL) && ticket.id}
                  {activeUser.hasPrivilege(Privileges.ASS.UPDATE) && (
                    <Link to={`/ticket/${ticket.id}`} className="p-2" title="Edit ticket">
                      <i className="fa fa-edit"></i>
                    </Link>
                  )}
                  {activeUser.hasPrivilege(Privileges.ASS.DETAIL) && (
                    <ActionMenu toggleClassName="py-0" horizontal>
                      <DropdownMenu>
                        <DropdownItem onClick={handleDownloadTicket}>Export to PDF</DropdownItem>
                      </DropdownMenu>
                    </ActionMenu>
                  )}
                </h5>
              </Col>
              <h6 className="col-sm-2">Ticket Status</h6>
              <Col sm={4}>
                <h5>{getEnumLabel(enums.sshStatus, ticket.sshStatus)}</h5>
              </Col>
            </Row>
            <Row className="py-1">
              <h6 className="col-sm-2">Created By</h6>
              <Col sm={4}>{ticket.createUser}</Col>
              <h6 className="col-sm-2">Created At</h6>
              <Col sm={4}>{ticket.createdDate}</Col>
            </Row>
            <Row className="py-1">
              <h6 className="col-sm-2">Service Date</h6>
              <Col sm={4}>
                <DateOutput date={ticket.serviceDate} />
              </Col>
              <h6 className="col-sm-2">Reminder Date</h6>
              <Col sm={4}>
                <DateOutput date={ticket.reminderDate} />
              </Col>
            </Row>
            <Row className="py-1">
              <h6 className="col-sm-2">Problem Subject</h6>
              <Col sm={4}>{ticket.problemSubject?.name}</Col>
              <h6 className="col-sm-2">Problem Type</h6>
              <Col sm={4}>
                {ticket.problemType?.name}
                {ticket.problemType1 && ", " + ticket.problemType1?.name}
                {ticket.problemType2 && ", " + ticket.problemType2?.name}
                {ticket.problemType3 && ", " + ticket.problemType3?.name}
                {ticket.problemType4 && ", " + ticket.problemType4?.name}
              </Col>
            </Row>
            <Row>
              {ticket.order && (
                <>
                  <h6 className="col-sm-2">Order Number</h6>
                  <Col sm={4}>
                    <h5>
                      {activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && (
                        <Link to={`/order/${ticket.order.id}/detail`} title="View order detail">
                          {ticket.order.orderId}
                        </Link>
                      )}
                      {!activeUser.hasPrivilege(Privileges.ORDER.DETAIL) && ticket.order.orderId}
                    </h5>
                  </Col>
                </>
              )}
              <h6 className="col-sm-2">Amount</h6>
              <Col sm={4}>
                <MoneyOutput value={ticket.amount} currency="GBP" />
              </Col>
            </Row>
            <Row className="py-1">
              <h6 className="col-sm-2">Resolved Date</h6>
              <Col sm={4}>
                <DateOutput date={ticket.resolvedDate} />
              </Col>
            </Row>
            {ticket.order?.id != null && (
              <Row className="py-1">
                <Col sm={12}>
                  <h5>Order Details</h5>
                  <hr />
                </Col>
                <Col sm={12}>
                  <OrderCard id={ticket.order?.id} showHeader={false} />
                </Col>
              </Row>
            )}
            {ticket.order?.id == null && (
              <Row className="py-1">
                <Col sm={12}>
                  <h5>Customer Details</h5>
                  <hr />
                </Col>
                <Col sm={12}>
                  <CustomerCard id={ticket.customer?.id} />
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default TicketCard;
