import { useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Input, Label, Row, Spinner } from "reactstrap";

import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import ValidationForm from "components/ValidationForm";
import { showInfo } from "helpers/utils";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import {
  getFinanceCompany,
  getFinanceCompanyRates,
  getFinancePaymentStatuses,
  getUserListContent,
} from "store/definitions/services";
import { createFinance, editFinance, getFinance } from "store/finance/services";
import FinanceNotes from "./FinanceNotes";
import financeUtils from "./finance_utils";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";
import { EnumValues } from "common/enums";

const initialLoadState = { loading: false, error: null, loaded: false };

const Finance = ({ financeId = null, onSave, onUpdate, orderId }) => {
  const activeUser = useActiveUser();
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [finance, setFinance] = useState(financeUtils.initialValues(orderId));

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFinance((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setFinance((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setFinance((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const loadFinance = async (financeId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getFinance(financeId);
      const prd = financeUtils.mapToFormDataModel(mdl);
      setFinance(prd);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  //handle finance
  const handleSaveFinance = async () => {
    setSaving(true);
    try {
      const saveModel = financeUtils.mapToSaveModel(finance);
      const mdl = await createFinance(saveModel);
      showInfo("Finance created successfully");
      onSave && onSave(mdl);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateFinance = async () => {
    setSaving(true);
    try {
      const saveModel = financeUtils.mapToSaveModel(finance);
      const mdl = await editFinance(financeId, saveModel);
      setFinance(financeUtils.mapToFormDataModel(mdl));
      showInfo("Finance updated successfully");
      onUpdate && onUpdate(mdl);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (financeId) {
      loadFinance(financeId);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <Row>
      <Col>
        {loadState.loading && <Spinner size="md">Loading...</Spinner>}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Finance could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <ValidationForm>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="date" className="col-sm-3 col-form-label">
                      Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd HH:mm"
                        isClearable={false}
                        selected={finance.date}
                        showTimeInput
                        placeholderText="Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("date", date);
                        }}
                        disabled
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="orderStatus" className="col-sm-3 col-form-label">
                      Finance Status
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        isClearable
                        service={getFinancePaymentStatuses}
                        name="paymentStatus"
                        value={finance.paymentStatus}
                        isMulti={false}
                        id="paymentStatus"
                        setValue={handleSetValue}
                        isDisabled={!activeUser.hasPrivilege(Privileges.FINANCE.UPDATE_STATUS)}
                        validations={["required"]}
                        validationeventname="setValue"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="financeCompany" className="col-sm-3 col-form-label">
                      Finance Company
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getFinanceCompany}
                        name="financeCompany"
                        value={finance.financeCompany.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        labelField="companyName"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="financeCompanyRate" className="col-sm-3 col-form-label">
                      Time period in month (Rate)
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={() => {
                          if (finance.financeCompany.value == null) {
                            return Promise.resolve([]);
                          }
                          return getFinanceCompanyRates(finance.financeCompany.value, { page: 0 });
                        }}
                        name="financeCompanyRate"
                        value={finance.financeCompanyRate.value}
                        isMulti={false}
                        setValue={(name, value, valueObject) => {
                          handleSetValueObj(name, value, valueObject);
                          handleSetValue("financeCommission", (+finance.totalAmount * valueObject.option?.rate) / 100);
                        }}
                        labelField="label"
                        reloadOptionsKey={finance.financeCompany.value}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="totalAmount" className="col-sm-3 col-form-label">
                      Total Amount
                    </Label>
                    <Col sm={9}>
                      <NumberFormat
                        value={finance.totalAmount || ""}
                        className="form-control"
                        placeholder="Total Amount.."
                        allowNegative={false}
                        name="totalAmount"
                        onValueChange={({ value }) => {
                          handleSetValue("totalAmount", value);
                          handleSetValue(
                            "financeCommission",
                            Number((+value * finance.financeCompanyRate.option?.rate) / 100).toFixed(2)
                          );
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="totalAmount" className="col-sm-3 col-form-label">
                      Commission
                    </Label>
                    <Col sm={9}>
                      <NumberFormat
                        type="number"
                        value={finance.financeCommission}
                        name="financeCommission"
                        placeholder="Comission.."
                        className="form-control"
                        disabled
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="authBy" className="col-sm-3 col-form-label">
                      Auth By
                    </Label>
                    <Col sm={9}>
                      <MultiselectWithService
                        service={getUserListContent}
                        name="authBy"
                        value={finance.authBy.value}
                        isMulti={false}
                        setValue={handleSetValueObj}
                        labelField="fullName"
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="authCode" className="col-sm-3 col-form-label">
                      Auth Code
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        value={finance.authCode}
                        name="authCode"
                        placeholder="Auth Code.."
                        className="form-control"
                        onChange={handleChange}
                      />
                    </Col>
                  </div>
                  <Row>
                    {financeId && (
                      <Col>
                        <Button color="success" size="md" onClick={handleUpdateFinance} validate disabled={saving}>
                          <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                        </Button>
                      </Col>
                    )}
                    {!financeId && (
                      <Col>
                        <Button color="success" size="md" onClick={handleSaveFinance} validate disabled={saving}>
                          <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              </CardBody>
            </Card>

            {financeId && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <FinanceNotes financeId={financeId} />
                  </CardBody>
                </Card>
                <ActionLogsCard
                  title="Finance History"
                  recordId={financeId}
                  actionModels={[EnumValues.ACTION_MODEL.ORDER_FINANCE]}
                  parentActions={[EnumValues.PARENT_ACTION_TYPE.ORDER_FINANCE]}
                />
              </>
            )}
          </ValidationForm>
        )}
      </Col>
    </Row>
  );
};

export default Finance;
