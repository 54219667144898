import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";

//i18n
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const activeUser = useActiveUser();

  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const definitionMenu = [];

  if (activeUser.hasPrivilege(Privileges.COLOR.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/colors">Colors</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.CURRENCY.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/currencies">Currency</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.CURRENCYRATE.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/currency-rate">Currency Rate</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.FINANCECOMPANY.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/finance-company">Finance Company</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.MANUFACTURER.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/manufacturers">Manufacturers</Link>
      </li>
    );
  }
  // if (activeUser.hasPrivilege(Privileges.MENU.MENU)) {
  //   definitionMenu.push(
  //     <li>
  //       <Link to="/menu">Menu</Link>
  //     </li>
  //   );
  // }
  if (activeUser.hasPrivilege(Privileges.MATERIAL.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/materials">Material</Link>
      </li>
    );
  }

  if (activeUser.hasPrivilege(Privileges.PRODUCT_ATTRIBUTE.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/product-attributes">Product Attributes</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.PRODUCT_TYPE.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/product-types">Product Types</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.PRODUCT_CATEGORY.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/product-categories">Product Category</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.TAXGROUP.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/tax-groups">Tax Groups</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.WEB_CATEGORY.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/web-categories">Web Categories</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.WAREHOUSE.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/warehouse">Warehouse</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.ASS_PROBLEM_TYPE.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/ass-problem-types">ASS Problem Types</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.ASS_PROBLEM_SUBJECT.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/ass-problem-subjects">ASS Problem Subjects</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.PRIVILEGE.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/privileges">Privileges</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.ROLE.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/roles">Roles</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.PRICETAG.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/price-tags">Price Tags</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.REPORT_DEFINITION.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/report-definitions">Report Definitions</Link>
      </li>
    );
  }
  if (activeUser.hasPrivilege(Privileges.COLOR_CHART.MENU)) {
    definitionMenu.push(
      <li>
        <Link to="/color-charts">Color Charts</Link>
      </li>
    );
  }
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>Dashboards</span>
              </Link>
            </li>
            {activeUser.hasPrivilege(Privileges.USER.MENU) && (
              <li>
                <Link to="/users" className="">
                  <i className="bx bxs-user"></i> <span>Users</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.CUSTOMER.MENU) && (
              <li>
                <Link to="/customers" className="">
                  <i className="bx bxs-group"></i> <span>Customers</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.STORE.MENU) && (
              <li>
                <Link to="/stores" className="">
                  <i className="bx bx-store"></i>
                  <span>Stores</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.PRODUCT.MENU) && (
              <li>
                <Link to="/products" className=" ">
                  <i className="bx bx-package"></i>
                  <span>Products</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.PROFORMA.MENU) && (
              <li>
                <Link to="/proformas" className=" ">
                  <i className="bx bx-list-minus"></i>
                  <span>Proformas</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.ORDER.MENU) && (
              <li>
                <Link to="/orders" className=" ">
                  <i className="bx bx-list-check"></i>
                  <span>Sales</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.PAYMENT.MENU) && (
              <li>
                <Link to="/payments" className=" ">
                  <i className="bx bx-money"></i>
                  <span>Payments</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.FINANCE.MENU) && (
              <li>
                <Link to="/finances" className=" ">
                  <img src="/icons/budget.png" className="pe-3" />
                  <span>Finances</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.INVOICE.MENU) && (
              <li>
                <Link to="/invoices" className=" ">
                  <i className="bx bx-receipt"></i>
                  <span>Invoices & Receipts</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.INVENTORY.MENU) && (
              <li>
                <Link to="/inventory" className=" ">
                  <i className="bx bx-data"></i>
                  <span>Inventory</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.PRODUCTREPORT.MENU) && (
              <li>
                <Link to="/product-report" className=" ">
                  <i className="bx bxs-report"></i>
                  <span>Product Report</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.PRODUCTION.MENU) && (
              <li>
                <Link to="/production-orders">
                  <img src="/icons/production.png" className="pe-3" />
                  <span>Productions</span>
                </Link>
              </li>
            )}

            {activeUser.hasPrivilege(Privileges.SHIPMENT.MENU) && (
              <li>
                <Link to="/shipments" className=" ">
                  <img src="/icons/cargo-boat.png" className="pe-2" />
                  <span>Shipments</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.TRANSFER.MENU) && (
              <li>
                <Link to="/transfers" className=" ">
                  <img src="/icons/transfer.png" className="pe-3" />
                  <span>Transfers</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.DELIVERY.MENU) && (
              <li>
                <Link to="/deliveries" className=" ">
                  <img src="/icons/delivery.png" className="pe-3" />
                  <span>Deliveries</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.OPERATION_CENTER.MENU) && (
              <li>
                <Link to="/operation-center" className=" ">
                  <i className="bx bx-task"></i>
                  <span>Operation Center</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.ASS.MENU) && (
              <li>
                <Link to="/tickets" className=" ">
                  <i className="bx bx-support"></i>
                  <span>After Sales Service</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.TASK.MENU) && (
              <li>
                <Link to="/tasks" className=" ">
                  <i className="bx bx-task"></i>
                  <span>Tasks</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.ACTION_LOGS.MENU) && (
              <li>
                <Link to="/action-logs" className=" ">
                  <i className="bx bx-history"></i>
                  <span>Logs</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.REPORT.MENU) && (
              <li>
                <Link to="/reports" className=" ">
                  <i className="bx bxs-report"></i>
                  <span>Reports</span>
                </Link>
              </li>
            )}
            {activeUser.hasPrivilege(Privileges.SALES_TARGET.MENU) && (
              <li>
                <Link to="/sales-targets" className=" ">
                  <i className="bx bx-target-lock"></i>
                  <span>Sales Targets</span>
                </Link>
              </li>
            )}
            {definitionMenu.length > 0 && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-aperture"></i>
                  <span>Definitions</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {definitionMenu.map((item, i) => (
                    <Fragment key={i}>{item}</Fragment>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
