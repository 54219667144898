import { PropTypes } from "prop-types";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import { hasAnyValue } from "helpers/utils";
import { useActiveUser, useEffectUpdate } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { downloadService } from "services/DownloadService";
import {
  addProductToBranch,
  exportInventoryToCsv,
  exportInventoryToPdf,
  getInventoryProducts,
} from "store/order/ordered-product-services";
import InventoryFilterForm from "./InventoryFilterForm";
import InventoryProductForm from "./InventoryProductForm";
import ProductsInStock from "./ProductsInStock";
import { inventoryAppliedFilterState, inventoryAppliedSortState, inventoryInStockOnly } from "./atoms";
import inventoryUtil from "./inventory_utils";

const initialProductState = { open: false, error: null, loading: false };
const initialProductsInStockState = { open: false, sku: null };
const Inventory = ({ history }) => {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [inventoryProducts, setInventoryProducts] = useState([]);

  const [appliedFilter, setAppliedFilter] = useRecoilState(inventoryAppliedFilterState);
  const [appliedSort, setAppliedSort] = useRecoilState(inventoryAppliedSortState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Inventory");

  const [productState, setProductState] = useState(initialProductState);
  const [productsInStockState, setProductsInStockState] = useState(initialProductsInStockState);
  const [inStockOnly, setInStockOnly] = useRecoilState(inventoryInStockOnly);

  const [loadingExportToPdf, setLoadingExportToPdf] = useState(false);
  const [loadingExportToCsv, setLoadingExportToCsv] = useState(false);

  const loadInventoryProducts = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getInventoryProducts(page, inventoryUtil.mapToInventorySearch(filter, inStockOnly), sort);
      setTotalSize(totalElements);
      setInventoryProducts(content);
    } finally {
      setLoading(false);
    }
  };

  useEffectUpdate(() => {
    loadInventoryProducts(pageOptions, appliedFilter, appliedSort);
  }, [inStockOnly]);

  const handlePageChange = (type, details) => {
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadInventoryProducts(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField: sortField, sortOrder };
      setAppliedSort(newSort);
      loadInventoryProducts(pageOptions, appliedFilter, newSort);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadInventoryProducts(newPage, appliedFilter, appliedSort);
    setHasFilter(hasAnyValue(appliedFilter));
    // setShowFilter(false);
  };

  const handleExportToPdf = async () => {
    setLoadingExportToPdf(true);
    try {
      const data = await exportInventoryToPdf(
        pageOptions,
        inventoryUtil.mapToInventorySearch(appliedFilter, inStockOnly),
        appliedSort
      );
      downloadService.openBufferedData({
        data,
        contentType: "application/pdf",
      });
    } finally {
      setLoadingExportToPdf(false);
    }
  };

  const handleExportToCsv = async () => {
    setLoadingExportToCsv(true);
    try {
      const data = await exportInventoryToCsv(
        pageOptions,
        inventoryUtil.mapToInventorySearch(appliedFilter, inStockOnly),
        appliedSort
      );
      downloadService.downloadBufferedData({
        data,
        contentType: "text/csv",
        fileName: "inventory.csv",
      });
    } finally {
      setLoadingExportToCsv(false);
    }
  };

  const openProductAddPopup = () => {
    setProductState((prev) => ({ ...prev, open: true }));
  };

  const handleAddProduct = async (product) => {
    await addProductToBranch(product.branchId, product);
    toast.info("Product added successfully");
    loadInventoryProducts(pageOptions, appliedFilter, appliedSort);
    setProductState((prev) => ({ ...prev, open: false }));
  };

  const handleCloseProduct = () => {
    setProductState((prev) => ({ ...prev, open: false }));
  };

  const columns = [
    {
      text: "Product",
      dataField: "name",
      sort: true,
    },
    {
      text: "Variant",
      dataField: "variantName",
      sort: false,
    },
    {
      text: "SKU",
      dataField: "sku",
      sort: true,
    },
    {
      text: "New Order In Proforma",
      dataField: "neworderinproforma",
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      text: "Stock Candidate In Proforma",
      dataField: "stockcandidateinproforma",
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      text: "New Order Waiting For Production",
      dataField: "neworderwaitingforproductioninorder",
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      text: "Sold",
      dataField: "sold",
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      text: "In Production (For Order)",
      dataField: "inproductionfororder",
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      text: "In Production (For Stock)",
      dataField: "inproductionforstock",
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      text: "In Stock (For Customer Order)",
      dataField: "stockfororder",
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      text: "Stock",
      dataField: "stock",
      sort: true,
      align: "right",
      headerAlign: "right",
      formatter: (cell, row) => {
        if (+cell > 0) {
          return (
            <Button
              className="btn btn-success btn-rounded"
              title="Show branch details"
              onClick={() => {
                setProductsInStockState({ open: true, sku: row.sku });
              }}
            >
              {cell}
            </Button>
          );
        }
        return cell;
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inventory</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <InventoryFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex flex-wrap justify-content-between">
                      <h4>Inventory</h4>
                      <div className="d-flex flex-wrap justify-content-end gap-2 pb-2">
                        {activeUser.hasPrivilege(Privileges.INVENTORY.EXPORT_TO_PDF) && (
                          <>
                            <Button
                              outline={!loadingExportToPdf}
                              size="sm"
                              onClick={handleExportToPdf}
                              disabled={loadingExportToPdf}
                            >
                              {loadingExportToPdf ? <Spinner color="white" size="sm" /> : "Export to PDF"}
                            </Button>
                            <Button
                              outline={!loadingExportToCsv}
                              size="sm"
                              onClick={handleExportToCsv}
                              disabled={loadingExportToCsv}
                            >
                              {loadingExportToCsv ? <Spinner color="white" size="sm" /> : "Export to CSV"}
                            </Button>
                          </>
                        )}
                        {activeUser.hasPrivilege(Privileges.INVENTORY.ADD_PRODUCT) && (
                          <Button color="primary" size="sm" onClick={openProductAddPopup}>
                            <i className="fa fa-plus"></i>Add Product
                          </Button>
                        )}
                      </div>
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <div className="form-check pb-2" style={{ float: "right" }}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="inStockOnly"
                          defaultChecked={inStockOnly}
                          onChange={(e) => {
                            setInStockOnly(e.target.checked);
                          }}
                        />
                        <label className="form-check-label" htmlFor="inStockOnly">
                          In Stock Only
                        </label>
                      </div>
                    </Col>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
                        keyField="sku"
                        responsive
                        bordered={true}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={inventoryProducts}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {productState.open && <InventoryProductModal onClose={handleCloseProduct} onSubmit={handleAddProduct} />}
      {productsInStockState.open && (
        <ProductsInStockModal
          onClose={() => {
            setProductsInStockState(initialProductsInStockState);
          }}
          sku={productsInStockState.sku}
          afterStockUpdate={() => loadInventoryProducts(pageOptions, appliedFilter, appliedSort)}
        />
      )}
    </React.Fragment>
  );
};

Inventory.propTypes = {
  history: PropTypes.object,
};

const InventoryProductModal = ({ onClose, onSubmit }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Add product to Inventory</ModalHeader>
      <ModalBody>
        <InventoryProductForm onSubmit={onSubmit} />
      </ModalBody>
    </Modal>
  );
};

const ProductsInStockModal = ({ onClose, sku, afterStockUpdate }) => {
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>Stock Details for {sku}</ModalHeader>
      <ModalBody>
        <ProductsInStock sku={sku} afterStockUpdate={afterStockUpdate} />
      </ModalBody>
    </Modal>
  );
};

export default withRouter(Inventory);
