import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Col, Label, Row, Spinner } from "reactstrap";

import { Link, useLocation, withRouter } from "react-router-dom";

import enums, { EnumValues, getEnumLabel } from "common/enums";
import CustomSpinner from "components/Common/CustomSpinner";
import DateTimeOutput from "components/Common/DateTimeOutput";
import CreatableSelectBoxWithFilterService from "components/Selectbox/CreatableSelectBoxWithFilterService";
import { showInfo } from "helpers/utils";
import OrderCard from "pages/Order/OrderCard";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import {
  cancelDelivery,
  completeDelivery,
  createDelivery,
  editDelivery,
  getDelivery,
  startDelivery,
} from "store/delivery/services";
import { searchOrdersByOrderNumber } from "store/order/services";
import DeliveryDocuments from "./DeliveryDocuments";
import DeliveryNotes from "./DeliveryNotes";
import DeliveryProducts from "./DeliveryProducts";
import deliveryUtils from "./delivery_utils";

const initialLoadState = { loading: false, error: null, loaded: false };

const Delivery = ({ id, history }) => {
  const [loadState, setLoadState] = useState(initialLoadState);
  const [saving, setSaving] = useState(false);
  const [deliveryView, setDeliveryView] = useState();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [confirmState, setConfirmState] = useState({
    open: false,
    onConfirm: null,
    title: "",
  });
  const { search } = useLocation();
  const queryOrderId = new URLSearchParams(search).get("orderId");
  const [delivery, setDelivery] = useState(deliveryUtils.initialValues({ orderId: queryOrderId }));

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setDelivery((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetValueObj = useCallback((name, value, valueObject) => {
    setDelivery((prevValues) => ({
      ...prevValues,
      [name]: valueObject,
    }));
  }, []);
  const handleSetValue = useCallback((name, value) => {
    setDelivery((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const loadDelivery = async (deliveryId) => {
    setLoadState({ loading: true, error: null, loaded: false });
    try {
      const mdl = await getDelivery(deliveryId);
      const prd = deliveryUtils.mapToFormDataModel(mdl);
      setDelivery(prd);
      setDeliveryView(mdl);
      setLoadState({ loading: false, error: null, loaded: true });
    } catch (error) {
      console.error(error);
      setLoadState({ loading: false, error, loaded: false });
    }
  };

  const validateDelivery = () => {
    if (!delivery.orderId) {
      toast.error("Order is required");
      return false;
    }
    if (!delivery.plannedDate) {
      toast.error("Planned date is required");
      return false;
    }
    return true;
  };

  //handle delivery
  const handleSaveDelivery = async () => {
    if (!validateDelivery()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = deliveryUtils.mapToSaveModel(delivery);
      const mdl = await createDelivery(saveModel);
      showInfo("Delivery created successfully");
      history.push(`/delivery/${mdl.id}`);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdateDelivery = async () => {
    if (!validateDelivery()) {
      return;
    }
    setSaving(true);
    try {
      const saveModel = deliveryUtils.mapToSaveModel(delivery);
      const mdl = await editDelivery(id, saveModel);
      setDelivery(deliveryUtils.mapToFormDataModel(mdl));
      setDeliveryView(mdl);
      showInfo("Delivery updated successfully");
    } finally {
      setSaving(false);
    }
  };

  const handleCompleteDelivery = async () => {
    async function confirmCompleteDelivery() {
      setLoadingComplete(true);
      try {
        const mdl = await completeDelivery(id);
        setDelivery(deliveryUtils.mapToFormDataModel(mdl));
        setDeliveryView(mdl);
        showInfo("Delivery completed successfully");
      } finally {
        setLoadingComplete(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to COMPLETE the delivery?",
      onConfirm: confirmCompleteDelivery,
    });
  };
  const handleStartDelivery = async () => {
    /*update start */
    if (!validateDelivery()) {
      return;
    }
    setLoadingPlan(true);
    try {
      const saveModel = deliveryUtils.mapToSaveModel(delivery);
      const mdl = await editDelivery(id, saveModel);
      setDelivery(deliveryUtils.mapToFormDataModel(mdl));
      setDeliveryView(mdl);
      // showInfo("Delivery updated successfully");
    } finally {
      setLoadingPlan(false);
    }
    /**update end */

    async function confirmStartDelivery() {
      setLoadingPlan(true);
      try {
        const mdl = await startDelivery(id);
        setDelivery(deliveryUtils.mapToFormDataModel(mdl));
        setDeliveryView(mdl);
        showInfo("Delivery planned successfully");
      } finally {
        setLoadingPlan(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to PLAN the delivery?",
      onConfirm: confirmStartDelivery,
    });
  };
  const handleCancelDelivery = async () => {
    async function confirmCancelDelivery() {
      setLoadingCancel(true);
      try {
        const mdl = await cancelDelivery(id);
        setDelivery(deliveryUtils.mapToFormDataModel(mdl));
        setDeliveryView(mdl);
        showInfo("Delivery cancelled successfully");
      } finally {
        setLoadingCancel(false);
      }
    }
    setConfirmState({
      open: true,
      title: "Are your sure you want to CANCEL the delivery?",
      onConfirm: confirmCancelDelivery,
    });
  };

  useEffect(() => {
    if (id) {
      loadDelivery(id);
    } else {
      setLoadState({ loading: false, error: null, loaded: true });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="my-5 px-4 pt-sm-5">
        {loadState.loading && <CustomSpinner />}
        {loadState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Delivery could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {loadState.loaded && (
          <h4 className="text-primary">
            <Breadcrumb listClassName="p-0">
              <BreadcrumbItem>
                <Link to="/deliveries">Deliveries</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {id == null && <b>New Delivery</b>}
                {id != null && <b>Edit Delivery</b>}
              </BreadcrumbItem>
            </Breadcrumb>
          </h4>
        )}
        {deliveryView && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Delivery Number</h6>
                <Col sm={4}>
                  <h5>{deliveryView.no}</h5>
                </Col>
                <h6 className="col-sm-2">Delivery Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.deliveryStatus, deliveryView.deliveryStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Created By</h6>
                <Col sm={4}>{deliveryView.createUser}</Col>
                <h6 className="col-sm-2">Last Modified At</h6>
                <Col sm={4}>
                  <DateTimeOutput date={deliveryView.updateDate} />
                </Col>
                <h6 className="col-sm-2">Created At</h6>
                <Col sm={4}>
                  <DateTimeOutput date={deliveryView.createdDate} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {loadState.loaded && (
          <>
            <Card className="shadow-lg">
              <CardBody>
                <div className="px-2 py-2">
                  <div className="row mb-4">
                    <Label htmlFor="plannedDate" className="col-sm-3 col-form-label">
                      Planned Date
                    </Label>
                    <Col sm={9}>
                      <ReactDatePicker
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        selected={delivery.plannedDate}
                        placeholderText="Planned Date.."
                        className="form-control"
                        onChange={(date) => {
                          handleSetValue("plannedDate", date);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="row mb-4">
                    <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                      Order Number
                    </Label>
                    <Col sm={9}>
                      <CreatableSelectBoxWithFilterService
                        loadService={searchOrdersByOrderNumber}
                        name="orderId"
                        inputMode="numeric"
                        optionLabelField="orderId"
                        value={delivery.orderId}
                        handleChange={handleSetValue}
                        createTable={false}
                      />
                    </Col>
                  </div>
                  {delivery.orderId && (
                    <div className="row mb-4">
                      <Label htmlFor="orderId" className="col-sm-3 col-form-label"></Label>
                      <Col sm={9}>
                        <OrderCard id={delivery.orderId} />
                      </Col>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>

            {id && (
              <>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <DeliveryProducts deliveryId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <DeliveryNotes deliveryId={id} />
                  </CardBody>
                </Card>
                <Card className="shadow-lg">
                  <CardBody className="">
                    <DeliveryDocuments deliveryId={id} />
                  </CardBody>
                </Card>
              </>
            )}
            <br />
            <br />
            {(!id ||
              deliveryView.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNING ||
              deliveryView.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNED) && (
              <Card className="shadow-lg nv-sticky-card mt-2">
                <CardBody className="">
                  <Row>
                    {id && (
                      <>
                        <Col>
                          {deliveryView.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNING && (
                            <>
                              {/* NOV-627 Delivery Planing Status ü pasifize etmek. */}
                              {/* <Button color="success" size="md" onClick={handleUpdateDelivery}>
                                <i className="fa fa-edit"></i> {saving ? <Spinner color="white" size="sm" /> : "Update"}
                              </Button>
                              &nbsp;&nbsp; */}
                              <Button color="success" size="md" onClick={handleStartDelivery} disabled={loadingPlan}>
                                {loadingPlan ? <Spinner color="white" size="sm" /> : "Plan Delivery"}
                              </Button>
                            </>
                          )}
                          {deliveryView.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNED && (
                            <>
                              <Button
                                color="success"
                                size="md"
                                onClick={handleCompleteDelivery}
                                disabled={loadingComplete}
                              >
                                {loadingComplete ? <Spinner color="white" size="sm" /> : "Complete Delivery"}
                              </Button>
                            </>
                          )}
                          {(deliveryView.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNING ||
                            deliveryView.deliveryStatus == EnumValues.DELIVERY_STATUS.PLANNED) && (
                            <>
                              &nbsp;&nbsp;
                              <Button color="danger" size="md" onClick={handleCancelDelivery} disabled={loadingCancel}>
                                {loadingCancel ? <Spinner color="white" size="sm" /> : "Cancel"}
                              </Button>
                            </>
                          )}
                        </Col>
                      </>
                    )}
                    {!id && (
                      <Col>
                        <Button color="success" size="md" onClick={handleSaveDelivery}>
                          <i className="fa fa-save"></i> {saving ? <Spinner color="white" size="sm" /> : "Save"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            )}
          </>
        )}
        {confirmState.open && (
          <SweetAlert
            title={confirmState.title}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setConfirmState({ open: false });
              confirmState.onConfirm();
            }}
            onCancel={() => {
              setConfirmState({ open: false });
            }}
          ></SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

Delivery.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Delivery);
