import ConfirmButton from "components/Common/ConfirmButton";
import CustomSpinner from "components/Common/CustomSpinner";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { addNoteToOrder, deleteOrderNote, getOrderNotes } from "../../store/order/services";
import orderUtils from "./order_utils";
import DateTimeOutput from "components/Common/DateTimeOutput";

const initialNoteState = { open: false, error: null, loading: false, note: {} };
const OrderNotes = ({ orderId, type }) => {
  const activeUser = useActiveUser();
  const [noteState, setNoteState] = useState(initialNoteState);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const openNoteAddPopup = () => {
    setNoteState((prev) => ({ ...prev, note: {}, open: true }));
  };
  const handleAddNote = async (note) => {
    return addNoteToOrder(orderId, note).then((data) => {
      loadNotes(orderId);
      setNoteState((prev) => ({ ...prev, note: {}, open: false }));
    });
  };
  const handleCloseNote = () => {
    setNoteState((prev) => ({ ...prev, open: false }));
  };
  const loadNotes = async (orderId) => {
    setLoading(true);
    try {
      const notes = await getOrderNotes(orderId);
      setNotes(notes);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteNote = async ({ id }) => {
    await deleteOrderNote(orderId, id);
    loadNotes(orderId);
  };
  useEffect(() => {
    loadNotes(orderId);
  }, [orderId]);

  return (
    <>
      <h4 className="text-secondary">
        <b>Notes</b>
        {type != "view" && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" onClick={openNoteAddPopup}>
              <i className="fa fa-plus"></i>Add Note
            </Button>
          </span>
        )}
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && notes.length > 0 && (
            <ul>
              {notes.map((n) => (
                <li key={n.id}>
                  <div className="py-2">
                    <b>{n.createUser}</b> (<DateTimeOutput date={n.createdDate} />)<br />
                    {n.notes && <span dangerouslySetInnerHTML={{ __html: n.notes }}></span>}
                    {type != "view" && activeUser.hasPrivilege(Privileges.ORDER.NOTE_DELETE) && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <ConfirmButton
                          size="sm"
                          color="danger"
                          onConfirm={() => handleDeleteNote(n)}
                          title="Are you sure you want to delete the note?"
                        >
                          <i className="fa fa-trash"></i>
                        </ConfirmButton>
                      </>
                    )}
                    {n.showInPdf && <div className="text-warning">This note is shown in pdf</div>}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </Col>
      </Row>
      {noteState.open && <OrderNoteModal onClose={handleCloseNote} onSubmit={handleAddNote} note={noteState.note} />}
    </>
  );
};

const OrderNoteModal = ({ note, onClose, onSubmit }) => {
  const [noteForm, setNoteForm] = useState({ ...note });
  const [saving, setSaving] = useState(false);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNoteForm((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleCheck = useCallback((e) => {
    const value = e.target.checked;
    const name = e.target.name;
    setNoteForm((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit(orderUtils.mapToNoteAddModel(noteForm));
    } finally {
      setSaving(false);
    }
  };
  return (
    <Modal isOpen centered={true} size="lg">
      <ModalHeader toggle={onClose}>{!note?.id ? "Add new note" : "Edit note"}</ModalHeader>
      <ModalBody>
        <div className="row mb-4">
          <Label htmlFor="notes" className="col-sm-3 col-form-label">
            Notes
          </Label>
          <Col sm={9}>
            <Input
              type="textarea"
              value={noteForm.notes}
              name="notes"
              placeholder="notes.."
              className="form-control"
              onChange={handleChange}
            />
          </Col>
        </div>
        <div className="row mb-4">
          <Label htmlFor="showInPdf" className="col-sm-3 col-form-label">
            Show In Pdf
          </Label>
          <Col sm={9}>
            <Input
              type="checkbox"
              name="showInPdf"
              placeholder="Show in pdf.."
              className="form-control"
              defaultChecked={noteForm.showInPdf || false}
              onClick={handleCheck}
            />
          </Col>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSubmit} disabled={saving}>
          {!note.id && "Add to order "}
          {note.id && "Update "}
          {saving && <Spinner size="sm" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrderNotes;
