import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import TicketCard from "./TicketCard";
import TicketNotes from "./TicketNotes";
import TicketProductsView from "./TicketProductsView";
import TicketDocuments from "./TicketDocuments";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";
import { EnumValues } from "common/enums";

const TicketDetailCard = ({ id }) => {
  return (
    <Row>
      <Col sm={12}>
        <Card className="shadow-lg">
          <CardBody className="">
            <TicketCard id={id} />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <TicketNotes ticketId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <TicketDocuments ticketId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <TicketProductsView ticketId={id} />
          </CardBody>
        </Card>
        <ActionLogsCard
          title="Ticket History"
          recordId={id}
          actionModels={[EnumValues.ACTION_MODEL.SSH]}
          parentActions={[EnumValues.PARENT_ACTION_TYPE.SSH]}
        />
      </Col>
    </Row>
  );
};

export default TicketDetailCard;
