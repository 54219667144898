import CustomSpinner from "components/Common/CustomSpinner";
import { useActiveUser } from "hooks";
import { useEffect, useState } from "react";
import { Card, CardTitle, Col, Row } from "reactstrap";
import { getOrder } from "store/order/services";
import OrderCardView from "./OrderCardView";

const OrderCard = ({ id, showHeader = true }) => {
  const [orderState, setOrderState] = useState({
    error: null,
    loading: false,
    order: null,
  });
  const loadOrder = async () => {
    setOrderState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const ord = await getOrder(id);
      setOrderState((state) => ({
        ...state,
        order: ord,
        loading: false,
      }));
    } catch (error) {
      setOrderState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadOrder();
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        {orderState.loading && (
          <Card>
            <div className="p-5">
              <CustomSpinner />
            </div>
          </Card>
        )}
        {!orderState.loading && orderState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Order could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {!orderState.loading && orderState.order && <OrderCardView order={orderState.order} showHeader={showHeader} />}
      </Col>
    </Row>
  );
};

export default OrderCard;
