import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createMaterials, deleteMaterials, editMaterials, getMaterials } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

const MaterialsPage = () => {
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "shortCode",
      text: "Shor Code",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Materials</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getMaterials,
            deleteapi: deleteMaterials,
            createapi: createMaterials,
            updateapi: editMaterials,
          }}
          tableColumns={columns}
          formValues={{
            values: [
              { name: "name", label: "Name", size: 12, type: "text" },
              {
                name: "shortCode",
                label: "Short Code",
                size: 12,
                type: "text",
              },
              {
                name: "description",
                label: "Description",
                size: 12,
                type: "textarea",
              },
              { name: "status", label: "status", size: 12, type: "checkbox" },
            ],
            validationSchema: {
              name: Yup.string().required("Name is required"),
              description: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{ definitionName: "Material", title: "Material List" }}
          privilege={{
            create: Privileges.MATERIAL.CREATE,
            update: Privileges.MATERIAL.UPDATE,
            delete: Privileges.MATERIAL.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(MaterialsPage);
