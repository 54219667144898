import enums, { getEnumLabel } from "common/enums";
import ImagesModal from "components/Common/ImagesModal";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import NumberFormat from "react-number-format";
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { getVariantOrProductImages } from "store/product/services";

let filteredProducts = [];

const OrderedProductModal = ({
  onClose,
  onSelect,
  service,
  multiple = false,
  showProductName = false,
  showProductStatus = false,
  showVariantName = false,
  showProductManufacturer = false,
  showSku = true,
  selectButtonLabel = "Select",
  showQuantitySelection = false,
  defaultUserEntryQuantity = "1",
}) => {
  const [imageState, setImageState] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [applying, setApplying] = useState(-1);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const loadProducts = async () => {
    setLoading(true);
    try {
      const prds = (await service())
        .map((p) => ({
          ...p,
          userEntryQuantity: defaultUserEntryQuantity == "q" ? p.quantity : +defaultUserEntryQuantity,
        }))
        .sort((p1, p2) => {
          const c1 = p2.order?.orderId - p1.order?.orderId;
          const c2 = p1.productVariant?.productName.localeCompare(p2.productVariant?.productName);
          const c3 = p1.productVariant?.name.localeCompare(p2.productVariant?.name);
          return c1 || c2 || c3;
        });
      setProducts(prds);
      filteredProducts = [...prds];
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadProducts();
  }, []);

  const handleApplyOne = async (item) => {
    setApplying(item.id);
    try {
      await onSelect(item);
    } finally {
      setApplying(-1);
    }
  };
  const handleApplyAll = async () => {
    setApplying(0);
    try {
      await onSelect(selectedProducts);
    } finally {
      setApplying(-1);
    }
  };

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        formatter: (productVariant, row) => (
          <VariantDefaultImage
            onClick={(e) => {
              e.stopPropagation();
              setImageState(row);
            }}
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
        filter: products.length == 0 ? undefined : textFilter(),
      },
      {
        text: "Product",
        dataField: "productVariant.productName",
        filter: products.length == 0 ? undefined : textFilter(),
        hidden: !showProductName,
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
        filter: products.length == 0 ? undefined : textFilter(),
        hidden: !showVariantName,
      },
      {
        text: "Manufacturer",
        dataField: "productVariant.productManufacturerName",
        filter: products.length == 0 ? undefined : textFilter(),
        hidden: !showProductManufacturer,
      },
      {
        text: "Status",
        dataField: "productStatus",
        filter: products.length == 0 ? undefined : textFilter(),
        formatter: (cell) => getEnumLabel(enums.orderedProductStatus, cell),
        hidden: !showProductStatus,
      },
      {
        text: "SKU",
        dataField: "sku",
        filter: products.length == 0 ? undefined : textFilter(),
        hidden: !showSku,
      },
      {
        text: "Color",
        dataField: "productVariant.color.nameWithMaterial",
        filter: products.length == 0 ? undefined : textFilter(),
      },
      {
        text: "Quantity",
        dataField: "userEntryCount",
        hidden: !showQuantitySelection,
        align: "center",
        style: { verticalAlign: "middle" },
        formatExtraData: selectedProducts,
        formatter: (cell, row, rowIndex, selectedProducts) => {
          return (
            <div>
              <NumberFormat
                value={row.userEntryQuantity}
                className="form-control w-80px py-1"
                allowNegative={false}
                disabled={selectedProducts == null || selectedProducts.find((p) => p.id == row.id) == null}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onValueChange={({ value }) => {
                  row.userEntryQuantity = value;
                }}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue == null || (floatValue > 0 && floatValue <= row.quantity);
                }}
              />
              <div className="text-info text-end">Max: {row.quantity}</div>
            </div>
          );
        },
      },
      {
        text: "Select",
        dataField: "select",
        hidden: multiple,
        formatter: (cell, row) => {
          return (
            <Button color="success" size="sm" onClick={() => onSelect(row)} disabled={applying == row.id}>
              {applying == row.id ? <Spinner color="white" size="sm" /> : <>{selectButtonLabel}</>}
            </Button>
          );
        },
      },
    ],
    [products, applying, selectedProducts]
  );
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedProducts((prev) => [...prev, row]);
    } else {
      setSelectedProducts((prev) => prev.filter((p) => p.id != row.id));
    }
    // return true;
  };
  // const handleOnSelectAll = (isSelect, rows, e) => {
  //   console.log(123);
  //   rows.forEach((row) => (row.selected = isSelect));
  //   return true;
  // };
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    hideSelectAll: false,
    // onSelectAll: handleOnSelectAll,//calismiyor!!!
    onSelect: handleOnSelect,
    selected: selectedProducts.map((p) => p.id),
    hideSelectAll: true,
  };
  const handleSelectUnselectAll = () => {
    if (selectedProducts.length > 0) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts([...filteredProducts]);
    }
  };
  return (
    <>
      <Modal isOpen centered={true} size="lg">
        <ModalHeader toggle={onClose}>Select Ordered Product</ModalHeader>
        <ModalBody>
          <Card className="shadow-lg">
            <CardBody>
              <div className="table-responsive">
                {multiple && products.length > 0 && (
                  <div className="pb-2">
                    <Button outline color="primary" onClick={handleSelectUnselectAll}>
                      {selectedProducts.length > 0 ? "Unselect All" : "Select All"}
                    </Button>
                  </div>
                )}
                <BootstrapTable
                  keyField="id"
                  data={products}
                  columns={productColumns}
                  filter={filterFactory({
                    afterFilter: (newResult) => {
                      filteredProducts = newResult;
                    },
                  })}
                  filterPosition="inline"
                  hover
                  responsive
                  bordered={true}
                  striped={false}
                  wrapperClasses="table-responsive"
                  classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                  headerWrapperClasses={"thead-light"}
                  selectRow={multiple ? selectRow : undefined}
                  noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                  loading={loading}
                />
              </div>
            </CardBody>
          </Card>
        </ModalBody>
        {multiple && (
          <ModalFooter>
            <Button color="success" onClick={handleApplyAll} disabled={applying == 0 || selectedProducts.length == 0}>
              {selectButtonLabel} {applying == 0 && <Spinner color="white" size="sm" />}
            </Button>
          </ModalFooter>
        )}
      </Modal>
      {imageState && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productVariant.productId, imageState.productVariant.id)}
          onClose={() => setImageState(null)}
        />
      )}
    </>
  );
};

export default OrderedProductModal;
