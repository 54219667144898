import enums, { EnumValues, getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import DateTimeOutput from "components/Common/DateTimeOutput";
import MoneyOutput from "components/Common/MoneyOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, DropdownItem, DropdownMenu, Row } from "reactstrap";
import { downloadService } from "services/DownloadService";
import { exportPackagePdf, getShipment } from "store/shipment/services";

const { getCustomer } = require("store/customer/services");

const ShipmentCard = ({ id }) => {
  const activeUser = useActiveUser();
  const [shipmentState, setShipmentState] = useState({
    error: null,
    loading: false,
    shipment: null,
  });
  const { shipment } = shipmentState;
  const loadShipment = async () => {
    setShipmentState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const ord = await getShipment(id);
      setShipmentState((state) => ({
        ...state,
        shipment: ord,
        loading: false,
      }));
    } catch (error) {
      setShipmentState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadShipment();
  }, [id]);

  const handleExportPackagePdf = async () => {
    const data = await exportPackagePdf(id);
    downloadService.openBufferedData({
      data,
      contentType: "application/pdf",
    });
  };

  return (
    <Row>
      <Col md={12}>
        {shipmentState.loading && <CustomSpinner />}
        {shipmentState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Shipment could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {shipmentState.shipment && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Shipment Number</h6>
                <Col sm={4}>
                  <h5>
                    {activeUser.hasPrivilege(Privileges.SHIPMENT.DETAIL) && (
                      <Link to={`/shipment/${shipment.id}/detail`} title="View detail">
                        {shipment.no}
                      </Link>
                    )}
                    {!activeUser.hasPrivilege(Privileges.SHIPMENT.DETAIL) && shipment.no}
                    {activeUser.hasPrivilege(Privileges.SHIPMENT.UPDATE) && (
                      <Link to={`/shipment/${shipment.id}`} className="p-2" title="Edit">
                        <i className="fa fa-edit"></i>
                      </Link>
                    )}
                    {[1].map((i) => {
                      const menuItems = [];
                      // if (
                      //   activeUser.hasPrivilege(Privileges.SHIPMENT.DETAIL) &&
                      //   [EnumValues.SHIPMENT_STATUS.ON_THE_WAY, EnumValues.SHIPMENT_STATUS.COMPLETED].includes(
                      //     shipment.shipmentStatus
                      //   )
                      // ) {
                      //   menuItems.push(
                      //     <DropdownItem key="print" onClick={() => null}>
                      //       Print
                      //     </DropdownItem>
                      //   );
                      // }
                      if (activeUser.hasPrivilege(Privileges.PACKAGE.EXPORT_PACKAGE_PDF)) {
                        menuItems.push(
                          <DropdownItem key="export-package" onClick={handleExportPackagePdf}>
                            Export Package Pdf
                          </DropdownItem>
                        );
                      }
                      if (menuItems.length > 0) {
                        return (
                          <ActionMenu toggleClassName="py-0" horizontal key="action-menu">
                            <DropdownMenu>{menuItems}</DropdownMenu>
                          </ActionMenu>
                        );
                      }
                      return null;
                    })}
                  </h5>
                </Col>
                <h6 className="col-sm-2">Shipment Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.shipmentStatus, shipment.shipmentStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by </b>
                  {shipment.createUser}
                  <b> at </b>
                  <DateTimeOutput date={shipment.createdDate} />
                </Col>
                <h6 className="col-sm-2">Goods In Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.shipmentStatus, shipment.goodsinStatus)}</h5>
                </Col>
                {shipment.deliveryBranch && (
                  <>
                    <h6 className="col-sm-2">Delivery Branch</h6>
                    <Col sm={4}>{shipment.deliveryBranch?.name}</Col>
                  </>
                )}
                {shipment.exitDate && (
                  <>
                    <h6 className="col-sm-2">Exit Date</h6>
                    <Col sm={4}>
                      <DateOutput date={shipment.exitDate} />
                    </Col>
                  </>
                )}
                {shipment.arrivalDate && (
                  <>
                    <h6 className="col-sm-2">Arrival Date</h6>
                    <Col sm={4}>
                      <DateOutput date={shipment.arrivalDate} />
                    </Col>
                  </>
                )}
                {shipment.vehicleType && (
                  <>
                    <h6 className="col-sm-2">Vehicle Type</h6>
                    <Col sm={4}>{getEnumLabel(enums.vehicleTypes, shipment.vehicleType)}</Col>
                  </>
                )}
                {shipment.containerNo && (
                  <>
                    <h6 className="col-sm-2">Container No</h6>
                    <Col sm={4}>{shipment.containerNo}</Col>
                  </>
                )}
                {shipment.plateNo && (
                  <>
                    <h6 className="col-sm-2">Plate No</h6>
                    <Col sm={4}>{shipment.plateNo}</Col>
                  </>
                )}
                {shipment.totalVehicleCapacity && (
                  <>
                    <h6 className="col-sm-2">Vehicle Capacity</h6>
                    <Col sm={4}>
                      {shipment.totalVehicleCapacity} {getEnumLabel(enums.volumeTypes, shipment.totalVolumeType)}
                    </Col>
                  </>
                )}
                {shipment.productsVolume && (
                  <>
                    <h6 className="col-sm-2">Products Volume</h6>
                    <Col sm={4}>
                      {shipment.productsVolume} {getEnumLabel(enums.volumeTypes, shipment.productsVolumeType)}
                    </Col>
                  </>
                )}
                {shipment.cost != null && (
                  <>
                    <h6 className="col-sm-2">Shipment Cost</h6>
                    <Col sm={4}>
                      <MoneyOutput value={shipment.cost} currency={shipment.currency} />
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default ShipmentCard;
