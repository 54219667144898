import moment from "moment";

class PaymentUtils {
  initialValues = (orderId) => ({
    id: null,
    status: "y",
    authBy: {},
    orderId,
    date: new Date(),
    paymentType: {},
    paidType: {},
    amount: "",
    authCode: "",
    gateway: "",
  });
  mapToSaveModel = ({
    id,
    status = "y",
    orderId,
    date,
    authBy = {},
    paymentType = {},
    paidType = {},
    amount,
    authCode,
    gateway,
  }) => ({
    id,
    status,
    authById: authBy?.value,
    orderId,
    date: date && moment(date).format("YYYY-MM-DD HH:mm:ss"),
    paidType: paidType?.value,
    paymentType: paymentType?.value,
    amount,
    authCode,
    gateway: !gateway ? null : gateway,
  });

  mapToFormDataModel = ({ id, status, authBy, order, date, paidType, paymentType, amount, authCode, gateway }) => ({
    id,
    status,
    authBy: {
      label: authBy?.fullName,
      value: authBy?.id,
    },
    date: date && moment(date, "YYYY-MM-DD HH:mm:ss").toDate(),
    orderId: order?.id,
    paidType: {
      label: paidType,
      value: paidType,
    },
    paymentType: {
      label: paymentType,
      value: paymentType,
    },
    amount,
    authCode,
    gateway,
  });

  mapToNoteAddModel = ({ id, status = "y", notes }) => ({
    id,
    status,
    notes,
  });

  initialFilterValues = () => ({
    orderId: null,
    customerId: null,
    customerFullName: "",
    paymentType: null,
    paidType: null,
    startAmount: null,
    endAmount: null,
    authCode: "",
    startDate: null,
    endDate: null,
    branchId: null,
  });

  mapToPaymentSearch = (
    filter = {
      orderId: null,
      customerId: null,
      customerFullName: "",
      paymentType: null,
      paidType: null,
      startAmount: null,
      endAmount: null,
      authCode: "",
      startDate: null,
      endDate: null,
      branchId: null,
    }
  ) => {
    return {
      branchId: filter.branchId,
      orderId: filter.orderId,
      customerId: filter.customerId,
      customerFullName: filter.customerFullName,
      paymentType: filter.paymentType,
      paidType: filter.paidType,
      startAmount: filter.startAmount,
      endAmount: filter.endAmount,
      authCode: filter.authCode,
      startDate: filter.startDate == null ? null : moment(filter.startDate).format("YYYY-MM-DD"),
      endDate: filter.endDate == null ? null : moment(filter.endDate).format("YYYY-MM-DD"),
    };
  };
}
const paymentUtils = new PaymentUtils();
export default paymentUtils;
