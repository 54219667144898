import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import { getTransferStatus, getUserListContent } from "store/definitions/services";
import { getStores } from "store/store/services";

const TransferFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="transferNumber" className="col-sm-3 col-form-label">
                Transfer Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.transferNumber || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="transferNumber"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("transferNumber", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Order Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.orderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="orderId"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("orderId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          {/* <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="vehiclePlateNo" className="col-sm-3 col-form-label">
                Vehicle Plate No
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.vehiclePlateNo || ""}
                  name="vehiclePlateNo"
                  placeholder="vehicle plate no.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("vehiclePlateNo", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col> */}
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="transferStatus" className="col-sm-3 col-form-label">
                Transfer Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getTransferStatus}
                  name="transferStatus"
                  value={filter.transferStatus}
                  isMulti={false}
                  id="transferStatus"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="senderLocationId" className="col-sm-3 col-form-label">
                Sender Location
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="senderLocationId"
                  value={filter.senderLocationId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="arrivalLocationId" className="col-sm-3 col-form-label">
                Arrival Location
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="arrivalLocationId"
                  value={filter.arrivalLocationId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="senderUserId" className="col-sm-3 col-form-label">
                Sender User
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getUserListContent}
                  name="senderUserId"
                  value={filter.senderUserId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                  labelField="fullName"
                />
              </Col>
            </div>
          </Col>
          {/* <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="receiverUserId" className="col-sm-3 col-form-label">
                Receiver User
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getUserListContent}
                  name="receiverUserId"
                  value={filter.receiverUserId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                  labelField="fullName"
                />
              </Col>
            </div>
          </Col> */}
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default TransferFilterForm;
