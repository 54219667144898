import moment from "moment";

class InventoryUtils {
  initialFilterValues = () => ({
    warehouseIds: [],
    storeId: null,
    sku: "",
    name: "",
    productCategoryIds: [],
  });

  mapToInventorySearch = (
    filter = {
      warehouseIds: [],
      storeId: null,
      sku: "",
      name: "",
      productCategoryIds: [],
    },
    inStockOnly = true
  ) => ({
    name: filter.name,
    sku: filter.sku,
    storeIds: filter.storeId != null ? [filter.storeId] : [],
    warehouseIds: filter.warehouseIds != null ? [...filter.warehouseIds] : [],
    productCategoryIds: filter.productCategoryIds != null ? [...filter.productCategoryIds] : [],
    minStock: inStockOnly ? 1 : null,
  });

  mapToProductAddModel = ({ quantity, variant, notes, branch }) => ({
    variantId: variant?.id,
    quantity,
    notes,
    branchId: branch?.value,
  });
}
const inventoryUtils = new InventoryUtils();
export default inventoryUtils;
