class ActionLogUtils {
  initialFilterValues = () => ({
    recordId: null,
    parentRecordId: null,
    process: null,
    actionTypes: null,
    actionModel: null,
    parentAction: null,
    userId: null,
    orderId: null,
    proformaId: null,
  });

  mapToActionLogSearch = (
    filter = {
      recordId: null,
      parentRecordId: null,
      process: null,
      actionTypes: null,
      actionModel: null,
      parentAction: null,
      userId: null,
      orderId: null,
      proformaId: null,
    }
  ) => {
    return {
      recordId: filter.recordId,
      parentRecordId: filter.parentRecordId,
      process: filter.process,
      actionTypes: filter.actionTypes ?? [],
      actionModel: filter.actionModel,
      parentAction: filter.parentAction,
      userId: filter.userId,
      orderId: filter.orderId,
      proformaId: filter.proformaId,
    };
  };
}
const actionLogUtils = new ActionLogUtils();
export default actionLogUtils;
