import { Card, CardBody, Col, Row } from "reactstrap";
import TaskCard from "./TaskCard";
import TaskDocuments from "./TaskDocuments";
import TaskNotes from "./TaskNotes";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";
import { EnumValues } from "common/enums";

const TaskDetailCard = ({ id }) => {
  return (
    <Row>
      <Col>
        <TaskCard id={id} />
        <Card className="shadow-lg">
          <CardBody className="">
            <TaskNotes taskId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <TaskDocuments taskId={id} type="view" />
          </CardBody>
        </Card>
        <ActionLogsCard
          title="Task History"
          recordId={id}
          actionModels={[EnumValues.ACTION_MODEL.TASK]}
          parentActions={[EnumValues.PARENT_ACTION_TYPE.TASK]}
        />
      </Col>
    </Row>
  );
};

export default TaskDetailCard;
