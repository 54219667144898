import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import * as Yup from "yup";
import {
  createFinanceCompany,
  deleteFinanceCompany,
  editFinanceCompany,
  getFinanceCompany,
} from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import Privileges from "models/Privileges";

const FinanceCompanyPage = () => {
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "companyCode",
      text: "Company Code",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "info",
      text: "Info",
      sort: true,
    },
    {
      dataField: "rates",
      text: "Rates",
      formatter: (cell, row) => (
        <Link
          to={{ pathname: `/finance-company/${row.id}/rate`, state: `${row.companyName}` }}
          className="btn btn-secondary"
        >
          Rates
        </Link>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Finance Company</title> {/* degistir */}
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getFinanceCompany,
            deleteapi: deleteFinanceCompany,
            createapi: createFinanceCompany,
            updateapi: editFinanceCompany,
          }}
          tableColumns={columns}
          formValues={{
            // "companyName": "string",
            // "companyCode": "string",
            // "phone": "string",
            // "info": "string"
            values: [
              { name: "companyName", label: "Name", size: 12, type: "text" },
              {
                name: "companyCode",
                label: "Code",
                size: 12,
                type: "text",
              },
              {
                name: "phone",
                label: "Phone",
                size: 12,
                type: "text",
              },
              {
                name: "info",
                label: "Info",
                size: 12,
                type: "text",
              },
              { name: "status", label: "status", size: 12, type: "checkbox" },
            ],
            validationSchema: {
              companyName: Yup.string().required("Name is required"),
              companyCode: Yup.string(),
              phone: Yup.string(),
              info: Yup.string(),
              status: Yup.string(),
            },
          }}
          pageDetails={{ definitionName: "Finance Company", title: "Finance Company List" }}
          privilege={{
            create: Privileges.FINANCECOMPANY.CREATE,
            update: Privileges.FINANCECOMPANY.UPDATE,
            delete: Privileges.FINANCECOMPANY.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(FinanceCompanyPage);
