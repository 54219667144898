import { post, get, del, put } from "helpers/axios_helper";
import { toQueryParams } from "helpers/utils";

//task
export const getTask = (id) => get(`/task/${id}`);
export const editTask = (id, data) => put(`/task/${id}`, data);
export const startTask = (id) => put(`/task/${id}/start`);
export const cancelTask = (id) => put(`/task/${id}/cancel`);
export const completeTask = (id) => put(`/task/${id}/complete`);
export const createTask = (data) => post(`/task`, data);
export const deleteTask = (id) => del(`/task/${id}`);
export const getTasks = (pageOpt = { page: 0, size: 1000 }, filter, sort) =>
  get(
    `/task/search?${sort ? `sort=${sort.sortField},${sort.sortOrder ?? "asc"}&` : ""}page=${pageOpt.page}&size=${
      pageOpt.size
    }&&${toQueryParams(filter)}`
  );

export const addNoteToTask = (taskId, data) => put(`/task/${taskId}/note`, data);
export const getTaskNotes = (taskId) => get(`/task/${taskId}/note`);
export const deleteTaskNote = (taskId, noteId) => del(`/task/${taskId}/note/${noteId}`);

export const addDocumentToTask = (taskId, data, type) => put(`/task/${taskId}/document?type=${type}`, data);
export const getTaskDocuments = (taskId) => get(`/task/${taskId}/document`);
export const deleteTaskDocument = (taskId, docId) => del(`/task/${taskId}/document/${docId}`);
