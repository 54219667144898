import { StatusCheckBox } from "components/Tables/DefinitionTable";
import { useFormik } from "formik";
import React, { Component, Fragment, useCallback, useEffect, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import debounce from "lodash.debounce";
import moment from "moment";
import * as Yup from "yup";

import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
} from "reactstrap";
import ReactDatePicker from "react-datepicker";
import SelectWithOptions from "./SelectWithOptions";
import MultiselectWithService from "./MultiselectWithService";

const CreatableSelectBoxWithFilterService = ({
  isMulti,
  name,
  value,
  handleChange,
  optionValueField = "id",
  optionLabelField = "name",
  inputMode,
  newItemDetails = {
    formValues: {
      values: [{ name: "", label: "", type: "", size: "" }],
      validationSchema: {},
      itemName: "",
    },
    createapi: () => Promise(),
  },
  loadService = () => Promise.resolve(),
  getLabel,
  createTable = true,
}) => {
  const [canvasDetails, setCanvasDetails] = useState({ open: false });

  const toggleCanvas = () => {
    setCanvasDetails((prev) => ({ ...prev, open: !prev.open }));
  };
  const handleSelect = (newValue, actionMeta) => {
    // new
    if (newValue && newValue.__isNew__) {
      toggleCanvas();
    } else {
      handleChange(name, newValue?.value, newValue);
    }
  };

  const handleNewSuccess = (obj) => {
    handleChange(name, obj[optionValueField], obj);
    toggleCanvas();
  };

  const _handleLoadOptions = (inputValue, callback) => {
    loadService(inputValue)
      .then((items) =>
        items.map((item) => ({
          label: getLabel ? getLabel(item) : item[optionLabelField],
          value: item[optionValueField],
        }))
      )
      .then((resp) => callback(resp));
  };
  const handleKeyDown = (e) => {
    if (e.keyCode == 8 || e.keyCode == 13) {
      return true;
    }
    if (inputMode == "numeric" && !((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105))) {
      e.preventDefault();
      return false;
    }
    return true;
  };
  const handleLoadOptions = debounce(_handleLoadOptions, 300);

  return (
    <Fragment>
      <AsyncCreatableSelect
        loadOptions={handleLoadOptions}
        onChange={handleSelect}
        isMulti={isMulti}
        isClearable
        placeholder="search.."
        openMenuOnClick={false}
        onKeyDown={handleKeyDown}
        isValidNewOption={() => createTable}
      />
      {newItemDetails !== false && createTable && (
        <Offcanvas fade isOpen={canvasDetails.open} direction="end" toggle={toggleCanvas}>
          <OffcanvasHeader toggle={toggleCanvas}>Create New {newItemDetails.formValues.itemName}</OffcanvasHeader>
          <OffcanvasBody>
            <CreateForm
              formValues={newItemDetails.formValues}
              createapi={newItemDetails.createapi}
              callback={handleNewSuccess}
            />
          </OffcanvasBody>
        </Offcanvas>
      )}
    </Fragment>
  );
};

const CreateForm = ({
  callback,
  createapi,
  formValues = {
    values: [{ name: "", label: "", type: "", size: "" }],
    validationSchema: {},
    itemName: "",
  },
}) => {
  const [formData, setFormData] = useState({ loading: false, success: null, error: null });
  const initialValues = formValues.values.reduce((acc, curr) => {
    const defaultVal = curr.isMulti ? curr.initialValue || [] : curr.initialValue || "";
    if (curr.name === "status") {
      acc[curr.name] = "y";
    } else {
      if (curr.getValue == null) {
        acc[curr.name] = defaultVal;
      } else {
        acc[curr.name] = defaultVal;
      }
    }
    return acc;
  }, {});
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object(formValues.validationSchema),
    onSubmit: (values) => {
      setFormData({ loading: true, success: null, error: null });
      createapi(values) // service
        .then((res) => {
          setFormData({ loading: false, success: true, error: null });
          validation.resetForm();
          callback && callback(res);
        })
        .catch((err) => {
          setFormData({ loading: false, success: false, error: err.response.data.message });
        });
    },
  });

  const handleSubmit = () => {
    validation.handleSubmit();
  };
  return (
    <Fragment>
      <div className="p-2">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          {formData.error ? <Alert color="danger">{formData.error}</Alert> : null}

          <Row>
            {formValues.values.map((i) => {
              const visible = i.visible == null || i.visible(validation.values);
              return !visible ? (
                <Col sm={i.size} key={i.name}></Col>
              ) : i.name === "status" ? (
                <Col sm={12} key={i.name}>
                  <StatusCheckBox validation={validation} />
                </Col>
              ) : i.type === "datetime" ? (
                <Col sm={i.size} key={i.name}>
                  <div className="mb-3">
                    <Label className="form-label">{i.label}</Label>
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      dropdownMode="select"
                      selected={
                        validation.values[i.name]
                          ? moment(validation.values[i.name], "YYYY-MM-DD").isValid() &&
                            moment(validation.values[i.name], "YYYY-MM-DD").toDate()
                          : validation.values[i.name]
                      }
                      placeholderText={`Select ${i.label}`}
                      className="form-control"
                      onChange={(date) => {
                        validation.setFieldValue &&
                          validation.setFieldValue(i.name, date ? moment(date).format("YYYY-MM-DD") : null);
                      }}
                    />
                  </div>
                </Col>
              ) : i.type === "select" ? (
                <Col sm={i.size} key={i.name}>
                  <div className="mb-3">
                    <Label className="form-label">{i.label}</Label>
                    <MultiselectWithService
                      service={i.options ? () => Promise.resolve(i.options) : i.getService}
                      name={i.name}
                      value={validation.values[i.name]}
                      isMulti={i.isMulti}
                      setValue={validation.setFieldValue}
                    />
                  </div>
                  {validation.touched[i.name] && validation.errors[i.name] && (
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      {validation.errors[i.name]}
                    </div>
                  )}
                </Col>
              ) : (
                <Col sm={i.size} key={i.name}>
                  <div className="mb-3">
                    <Label className="form-label">{i.label}</Label>
                    <Input
                      name={i.name}
                      className="form-control"
                      placeholder={`Enter ${i.label}`}
                      type={i.type}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values[i.name] || ""}
                      invalid={validation.touched[i.name] && validation.errors[i.name] ? true : false}
                    />
                    {validation.touched[i.name] && validation.errors[i.name] ? (
                      <FormFeedback type="invalid">{validation.errors[i.name]}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
              );
            })}
          </Row>
          <Button onClick={handleSubmit} color="success" disabled={formData.loading}>
            {formData.loading ? <Spinner color="white" size="sm" /> : "Save"}
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

export default CreatableSelectBoxWithFilterService;
