import enums, { getEnumLabel } from "common/enums";
import DateTimeOutput from "components/Common/DateTimeOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap";
import { getTransfer } from "store/transfer/services";

const TransferCard = ({ id }) => {
  const activeUser = useActiveUser();
  const [transferState, setTransferState] = useState({
    error: null,
    loading: false,
    transfer: null,
  });
  const { transfer } = transferState;
  const loadTransfer = async () => {
    setTransferState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const ord = await getTransfer(id);
      setTransferState((state) => ({
        ...state,
        transfer: ord,
        loading: false,
      }));
    } catch (error) {
      setTransferState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadTransfer();
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        {transferState.loading && (
          <Spinner size="md" style={{ marginLeft: 20 }}>
            Loading...
          </Spinner>
        )}
        {transferState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Transfer could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {transferState.transfer && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Transfer Number</h6>
                <Col sm={4}>
                  <h5>
                    {activeUser.hasPrivilege(Privileges.TRANSFER.DETAIL) && (
                      <Link to={`/transfer/${transfer.id}/detail`} title="View detail">
                        {transfer.transferNumber}
                      </Link>
                    )}
                    {!activeUser.hasPrivilege(Privileges.TRANSFER.DETAIL) && transfer.transferNumber}
                    {activeUser.hasPrivilege(Privileges.TRANSFER.UPDATE) && (
                      <Link to={`/transfer/${transfer.id}`} className="p-2" title="Edit">
                        <i className="fa fa-edit"></i>
                      </Link>
                    )}
                  </h5>
                </Col>
                <h6 className="col-sm-2">Transfer Status</h6>
                <Col sm={4}>
                  <h5>{getEnumLabel(enums.transferStatus, transfer.transferStatus)}</h5>
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by </b>
                  {transfer.createUser}
                  <b> at </b>
                  <DateTimeOutput date={transfer.createdDate} />
                </Col>
                <h6 className="col-sm-2">Last Modified At</h6>
                <Col sm={4}>
                  <DateTimeOutput date={transfer.updateDate} />
                </Col>
                <h6 className="col-sm-2">Sender Location</h6>
                <Col sm={4}>{transfer.senderLocation?.name}</Col>
                <h6 className="col-sm-2">Arrival Location</h6>
                <Col sm={4}>{transfer.arrivalLocation?.name}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default TransferCard;
