import CustomSpinner from "components/Common/CustomSpinner";
import DateOutput from "components/Common/DateOutput";
import DateTimeOutput from "components/Common/DateTimeOutput";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getSalesTarget } from "store/salesTarget/services";

const { getCustomer } = require("store/customer/services");

const SalesTargetCard = ({ id }) => {
  const activeUser = useActiveUser();
  const [salesTargeState, setSalesTargetState] = useState({
    error: null,
    loading: false,
    salesTarget: null,
  });
  const { salesTarget } = salesTargeState;
  const loadSalesTarget = async () => {
    setSalesTargetState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const ord = await getSalesTarget(id);
      setSalesTargetState((state) => ({
        ...state,
        salesTarget: ord,
        loading: false,
      }));
    } catch (error) {
      setSalesTargetState((state) => ({
        ...state,
        error,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    loadSalesTarget();
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        {salesTargeState.loading && <CustomSpinner />}
        {salesTargeState.error && (
          <Card body color="danger">
            <CardTitle tag="h5">Sales Target could not be loaded, please try again</CardTitle>
          </Card>
        )}
        {salesTargeState.salesTarget && (
          <Card className="shadow-lg">
            <CardBody>
              <Row className="py-1">
                <h6 className="col-sm-2">Period</h6>
                <Col sm={4}>
                  <>
                    <DateOutput date={salesTarget.startDate} />
                    {" - "}
                    <DateOutput date={salesTarget.endDate} />
                  </>
                  {activeUser.hasPrivilege(Privileges.ORDER.UPDATE) && (
                    <Link to={`/sales-target/${salesTarget.id}`} className="p-2" title="Edit">
                      <i className="fa fa-edit"></i>
                    </Link>
                  )}
                </Col>
                <h6 className="col-sm-2">Created</h6>
                <Col sm={4}>
                  <b>by </b>
                  {salesTarget.createUser}
                  <b> at </b>
                  <DateTimeOutput date={salesTarget.createdDate} />
                </Col>
                <h6 className="col-sm-2">Store</h6>
                <Col sm={4}>{salesTarget.store?.name}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default SalesTargetCard;
