const Privileges = {
  USER: {
    VIEW: "USER_VIEW",
    MENU: "USER_MENU",
    CREATE: "USER_CREATE",
    UPDATE: "USER_UPDATE",
    DELETE: "USER_DELETE",
    DETAIL: "USER_DETAIL",
  },
  CUSTOMER: {
    MENU: "CUSTOMER_VIEW",
    CREATE: "CUSTOMER_CREATE",
    UPDATE: "CUSTOMER_UPDATE",
    DELETE: "CUSTOMER_DELETE",
    DETAIL: "CUSTOMER_DETAIL",
    BALANCE_VIEW: "CUSTOMER_BALANCE_VIEW",
  },
  CUSTOMER_ADDRESS: {
    MENU: "CUSTOMER_ADDRESS_VIEW",
    CREATE: "CUSTOMER_ADDRESS_CREATE",
    UPDATE: "CUSTOMER_ADDRESS_UPDATE",
    DELETE: "CUSTOMER_ADDRESS_DELETE",
    DETAIL: "CUSTOMER_ADDRESS_DETAIL",
  },
  STORE: {
    MENU: "STORE_VIEW",
    CREATE: "STORE_CREATE",
    UPDATE: "STORE_UPDATE",
    DELETE: "STORE_DELETE",
  },
  PRICETAG: {
    MENU: "PRICETAG_VIEW",
    CREATE: "PRICETAG_CREATE",
    UPDATE: "PRICETAG_UPDATE",
    DELETE: "PRICETAG_DELETE",
  },
  REPORT_DEFINITION: {
    MENU: "REPORT_DEFINITION_VIEW",
    CREATE: "REPORT_DEFINITION_CREATE",
    UPDATE: "REPORT_DEFINITION_UPDATE",
    DELETE: "REPORT_DEFINITION_DELETE",
  },
  INVOICE: {
    MENU: "INVOICE_VIEW",
    DOWNLOAD: "INVOICE_DOWNLOAD",
    RECEIPT_TO_INVOICE: "RECEIPT_TO_INVOICE",
  },
  INVENTORY: {
    MENU: "INVENTORY_VIEW",
    ADD_PRODUCT: "INVENTORY_ADD_PRODUCT",
    DELETE_PRODUCT: "INVENTORY_DELETE_PRODUCT",
    TRANSFER_PRODUCT: "INVENTORY_TRANSFER_PRODUCT",
    EXPORT_TO_PDF: "INVENTORY_EXPORT_TO_PDF",
  },
  PRODUCTREPORT: {
    MENU: "PRODUCTREPORT_VIEW",
  },
  PRODUCT: {
    MENU: "PRODUCT_VIEW",
    CREATE: "PRODUCT_CREATE",
    UPDATE: "PRODUCT_UPDATE",
    DELETE: "PRODUCT_DELETE",
    DETAIL: "PRODUCT_DETAIL",
    COPY_VARIANT: "PRODUCT_COPY_VARIANT",
    DELETE_VARIANT: "PRODUCT_VARIANT_DELETE",
    DOWNLOAD_PRICETAG: "PRODUCT_DOWNLOAD_PRICETAG",
    VIEW_COST: "PRODUCT_VIEW_COST",
    EDIT_COST: "PRODUCT_EDIT_COST",
    PRICE_HISTORY: "PRODUCT_PRICE_HISTORY",
    VARIANT_PRICE_HISTORY: "PRODUCT_VARIANT_PRICE_HISTORY",
  },
  ORDER: {
    MENU: "ORDER_VIEW",
    CREATE: "ORDER_CREATE",
    UPDATE: "ORDER_UPDATE",
    UPDATE_PF: "ORDER_UPDATE_PAYMENT_AND_FINANCE",
    DELETE: "ORDER_DELETE",
    CANCEL: "ORDER_CANCEL",
    DETAIL: "ORDER_DETAIL",
    NOTE_DELETE: "ORDER_NOTE_DELETE",
    COEFFICIENT_VIEW: "ORDER_COEFFICIENT_VIEW",
    IN_PROGRESS_UPDATE: "ORDER_IN_PROGRESS_UPDATE",
    UPDATE_CUSTOMER: "ORDER_UPDATE_CUSTOMER",
    CANCELLED_ORDER_TO_APPROVAL: "CANCELLED_ORDER_TO_APPROVAL",
    EXPORT_PRODUCT_INFO_PDF: "ORDER_EXPORT_PRODUCT_INFO_PDF",
  },
  PROFORMA: {
    MENU: "PROFORMA_VIEW",
    CREATE: "PROFORMA_CREATE",
    UPDATE: "PROFORMA_UPDATE",
    UPDATE_PF: "PROFORMA_UPDATE_PAYMENT_AND_FINANCE",
    DELETE: "PROFORMA_DELETE",
    DETAIL: "PROFORMA_DETAIL",
    APPROVE: "APPROVE_PROFORMA",
  },
  PAYMENT: {
    MENU: "PAYMENT_VIEW",
    CREATE: "PAYMENT_CREATE",
    UPDATE: "PAYMENT_UPDATE",
    DELETE: "PAYMENT_DELETE",
    DETAIL: "PAYMENT_DETAIL",
  },
  FINANCE: {
    MENU: "FINANCE_VIEW",
    CREATE: "FINANCE_CREATE",
    UPDATE: "FINANCE_UPDATE",
    DELETE: "FINANCE_DELETE",
    DETAIL: "FINANCE_DETAIL",
    UPDATE_STATUS: "FINANCE_PAYMENT_STATUS_UPDATE",
  },
  PRODUCTION: {
    MENU: "PRODUCTION_VIEW",
    CREATE: "PRODUCTION_CREATE",
    UPDATE: "PRODUCTION_UPDATE",
    DELETE: "PRODUCTION_DELETE",
    DETAIL: "PRODUCTION_DETAIL",
    PRODUCTION_DELETE_PRODUCT: "PRODUCTION_DELETE_PRODUCT",
    IN_PRODUCTION_DELETE_PRODUCT: "IN_PRODUCTION_DELETE_PRODUCT",
  },
  SHIPMENT: {
    MENU: "SHIPMENT_VIEW",
    CREATE: "SHIPMENT_CREATE",
    UPDATE: "SHIPMENT_UPDATE",
    DELETE: "SHIPMENT_DELETE",
    DETAIL: "SHIPMENT_DETAIL",
    GOODS_IN: "GOODS_IN",
  },
  TRANSFER: {
    MENU: "TRANSFER_VIEW",
    CREATE: "TRANSFER_CREATE",
    UPDATE: "TRANSFER_UPDATE",
    DELETE: "TRANSFER_DELETE",
    DETAIL: "TRANSFER_DETAIL",
  },
  DELIVERY: {
    MENU: "DELIVERY_VIEW",
    CREATE: "DELIVERY_CREATE",
    UPDATE: "DELIVERY_UPDATE",
    DELETE: "DELIVERY_DELETE",
    DETAIL: "DELIVERY_DETAIL",
  },
  ASS: {
    MENU: "ASS_VIEW",
    CREATE: "ASS_CREATE",
    UPDATE: "ASS_UPDATE",
    DELETE: "ASS_DELETE",
    DETAIL: "ASS_DETAIL",
  },
  TASK: {
    MENU: "TASK_VIEW",
    CREATE: "TASK_CREATE",
    UPDATE: "TASK_UPDATE",
    DELETE: "TASK_DELETE",
    DETAIL: "TASK_DETAIL",
  },
  OPERATION_CENTER: {
    MENU: "OPERATION_CENTER_VIEW",
  },
  COLOR: {
    MENU: "COLOR_VIEW",
    CREATE: "COLOR_CREATE",
    UPDATE: "COLOR_UPDATE",
    DELETE: "COLOR_DELETE",
  },
  CURRENCY: {
    MENU: "CURRENCY_VIEW",
    CREATE: "CURRENCY_CREATE",
    UPDATE: "CURRENCY_UPDATE",
    DELETE: "CURRENCY_DELETE",
  },
  CURRENCYRATE: {
    MENU: "CURRENCYRATE_VIEW",
    CREATE: "CURRENCYRATE_CREATE",
    UPDATE: "CURRENCYRATE_UPDATE",
    DELETE: "CURRENCYRATE_DELETE",
  },
  FINANCECOMPANY: {
    MENU: "FINANCECOMPANY_VIEW",
    CREATE: "FINANCECOMPANY_CREATE",
    UPDATE: "FINANCECOMPANY_UPDATE",
    DELETE: "FINANCECOMPANY_DELETE",
  },
  MANUFACTURER: {
    MENU: "MANUFACTURER_VIEW",
    CREATE: "MANUFACTURER_CREATE",
    UPDATE: "MANUFACTURER_UPDATE",
    DELETE: "MANUFACTURER_DELETE",
  },
  MANUFACTURER_ADDRESS: {
    CREATE: "MANUFACTURER_ADDRESS_CREATE",
    UPDATE: "MANUFACTURER_ADDRESS_UPDATE",
    DELETE: "MANUFACTURER_ADDRESS_DELETE",
  },
  MENU: {
    MENU: "MENU_VIEW",
    CREATE: "MENU_CREATE",
    UPDATE: "MENU_UPDATE",
    DELETE: "MENU_DELETE",
  },
  MATERIAL: {
    MENU: "MATERIAL_VIEW",
    CREATE: "MATERIAL_CREATE",
    UPDATE: "MATERIAL_UPDATE",
    DELETE: "MATERIAL_DELETE",
  },
  PRODUCT_ATTRIBUTE: {
    MENU: "PRODUCT_ATTRIBUTE_VIEW",
    CREATE: "PRODUCT_ATTRIBUTE_CREATE",
    UPDATE: "PRODUCT_ATTRIBUTE_UPDATE",
    DELETE: "PRODUCT_ATTRIBUTE_DELETE",
  },
  PRODUCT_TYPE: {
    MENU: "PRODUCT_TYPE_VIEW",
    CREATE: "PRODUCT_TYPE_CREATE",
    UPDATE: "PRODUCT_TYPE_UPDATE",
    DELETE: "PRODUCT_TYPE_DELETE",
  },
  PRODUCT_CATEGORY: {
    MENU: "PRODUCT_CATEGORY_VIEW",
    CREATE: "PRODUCT_CATEGORY_CREATE",
    UPDATE: "PRODUCT_CATEGORY_UPDATE",
    DELETE: "PRODUCT_CATEGORY_DELETE",
  },
  TAXGROUP: {
    MENU: "TAXGROUP_VIEW",
    CREATE: "TAXGROUP_CREATE",
    UPDATE: "TAXGROUP_UPDATE",
    DELETE: "TAXGROUP_DELETE",
  },
  WEB_CATEGORY: {
    MENU: "WEB_CATEGORY_VIEW",
    CREATE: "WEB_CATEGORY_CREATE",
    UPDATE: "WEB_CATEGORY_UPDATE",
    DELETE: "WEB_CATEGORY_DELETE",
  },
  WAREHOUSE: {
    MENU: "WAREHOUSE_VIEW",
    CREATE: "WAREHOUSE_CREATE",
    UPDATE: "WAREHOUSE_UPDATE",
    DELETE: "WAREHOUSE_DELETE",
  },
  ASS_PROBLEM_TYPE: {
    MENU: "ASS_PROBLEM_TYPE_VIEW",
    CREATE: "ASS_PROBLEM_TYPE_CREATE",
    UPDATE: "ASS_PROBLEM_TYPE_UPDATE",
    DELETE: "ASS_PROBLEM_TYPE_DELETE",
  },
  REPORT: {
    MENU: "REPORT_VIEW",
  },
  ASS_PROBLEM_SUBJECT: {
    MENU: "ASS_PROBLEM_SUBJECT_VIEW",
    CREATE: "ASS_PROBLEM_SUBJECT_CREATE",
    UPDATE: "ASS_PROBLEM_SUBJECT_UPDATE",
    DELETE: "ASS_PROBLEM_SUBJECT_DELETE",
  },
  PRIVILEGE: {
    MENU: "PRIVILEGE_VIEW",
    CREATE: "PRIVILEGE_CREATE",
    UPDATE: "PRIVILEGE_UPDATE",
    DELETE: "PRIVILEGE_DELETE",
  },
  ROLE: {
    MENU: "ROLE_VIEW",
    CREATE: "ROLE_CREATE",
    UPDATE: "ROLE_UPDATE",
    DELETE: "ROLE_DELETE",
  },
  COLOR_CHART: {
    MENU: "COLOR_CHART_VIEW",
    CREATE: "COLOR_CHART_CREATE",
    UPDATE: "COLOR_CHART_UPDATE",
    DELETE: "COLOR_CHART_DELETE",
  },
  ACTION_LOGS: {
    MENU: "ACTION_LOGS",
  },
  DASHBOARD: {
    MENU: "DASHBOARD_VIEW",
  },
  PACKAGE: {
    EXPORT_PACKAGE_PDF: "EXPORT_PACKAGE_PDF",
  },
  SALES_TARGET: {
    MENU: "SALES_TARGET_VIEW",
    CREATE: "SALES_TARGET_CREATE",
    UPDATE: "SALES_TARGET_UPDATE",
    DELETE: "SALES_TARGET_DELETE",
    DETAIL: "SALES_TARGET_DETAIL",
  },
};

export default Privileges;
