import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import { getOrderStatusExcludeProforma, getUserListContent } from "store/definitions/services";
import { getStores } from "store/store/services";

const OrderFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Order Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.orderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="orderId"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("orderId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="customerFullName" className="col-sm-3 col-form-label">
                Customer
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.customerFullName || ""}
                  name="customerFullName"
                  placeholder="customer.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("customerFullName", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="postCode" className="col-sm-3 col-form-label">
                Post Code
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.postCode || ""}
                  name="postCode"
                  placeholder="post code.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("postCode", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="phone" className="col-sm-3 col-form-label">
                Phone
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.phone || ""}
                  name="phone"
                  placeholder="phone.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("phone", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="email" className="col-sm-3 col-form-label">
                Email
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.email || ""}
                  name="email"
                  placeholder="email.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("email", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderStatus" className="col-sm-3 col-form-label">
                Order Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getOrderStatusExcludeProforma}
                  name="orderStatus"
                  value={filter.orderStatus}
                  isMulti={false}
                  id="orderStatus"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="deliveryBranchId" className="col-sm-3 col-form-label">
                Branch
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="deliveryBranchId"
                  value={filter.deliveryBranchId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="salesPersonId" className="col-sm-3 col-form-label">
                Sales Person
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getUserListContent}
                  name="salesPersonId"
                  value={filter.salesPersonId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                  labelField="fullName"
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="deliveryDateStart" className="col-sm-3 col-form-label">
                Delivery Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.deliveryDateStart}
                      placeholderText="Delivery Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("deliveryDateStart", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.deliveryDateEnd}
                      placeholderText="Delivery End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("deliveryDateEnd", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderDateStart" className="col-sm-3 col-form-label">
                Order Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.orderDateStart}
                      placeholderText="Order Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("orderDateStart", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.orderDateEnd}
                      placeholderText="Order End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("orderDateEnd", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default OrderFilterForm;
