import enums, { getEnumLabel } from "common/enums";
import ActionMenu from "components/Common/ActionMenu";
import { hasAnyValue } from "helpers/utils";
import { useActiveUser } from "hooks";
import { useRemotePaging } from "hooks/usePaging";
import Privileges from "models/Privileges";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Row,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { deleteTask, getTasks } from "store/task/services";
import TaskFilterForm from "./TaskFilterForm";
import { taskiesAppliedFilterState, taskiesAppliedSortState } from "./atoms";
import taskUtils from "./task_utils";
import DateTimeOutput from "components/Common/DateTimeOutput";
const Tasks = ({ history }) => {
  const activeUser = useActiveUser();
  const [deleteConfirmState, setDeleteConfirmState] = useState({
    open: false,
    id: null,
  });
  const [taskies, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appliedFilter, setAppliedFilter] = useRecoilState(taskiesAppliedFilterState);
  const [appliedSort, setAppliedSort] = useRecoilState(taskiesAppliedSortState);
  const [hasFilter, setHasFilter] = useState(hasAnyValue(appliedFilter));
  const [showFilter, setShowFilter] = useState(hasFilter);
  const [pageOptions, setPageNumber, setTotalSize] = useRemotePaging("Tasks");
  const loadTasks = async (page, filter, sort) => {
    try {
      setLoading(true);
      const {
        content,
        page: { totalElements },
      } = await getTasks(page, taskUtils.mapToTaskSearch(filter), sort);
      setTotalSize(totalElements);
      setTasks(content);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // loadTasks(pageOptions, appliedFilter, appliedSort);
  }, []);

  const handlePageChange = (type, details) => {
    // console.log("handlePageChange", details, type);
    const { page, sizePerPage, sortField, sortOrder } = details;
    if (type == "pagination") {
      loadTasks(setPageNumber(page, sizePerPage), appliedFilter, appliedSort);
    } else if (type == "sort") {
      const newSort = { sortField, sortOrder };
      // console.log(appliedSort, newSort);
      setAppliedSort(newSort);
      loadTasks(pageOptions, appliedFilter, newSort);
    }
  };

  const handleToggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const handleSubmitFilterForm = (appliedFilter) => {
    setAppliedFilter(appliedFilter);
    const newPage = setPageNumber(0);
    loadTasks(newPage, appliedFilter, appliedSort);
    setHasFilter(hasAnyValue(appliedFilter));
  };

  const handleDelete = async (id) => {
    try {
      await deleteTask(id);
      toast.info("Task deleted successfully");
      loadTasks(pageOptions, appliedFilter, appliedSort);
    } finally {
      setDeleteConfirmState({ open: false });
    }
  };
  const columns = [
    {
      dataField: "actions",
      text: "Actions",
      align: "center",
      headerAlign: "center",
      hidden:
        !activeUser.hasPrivilege(Privileges.TASK.DETAIL) &&
        !activeUser.hasPrivilege(Privileges.TASK.UPDATE) &&
        !activeUser.hasPrivilege(Privileges.TASK.DELETE),
      formatter: (cell, row) => {
        return (
          <ActionMenu>
            <DropdownMenu>
              {activeUser.hasPrivilege(Privileges.TASK.UPDATE) && (
                <DropdownItem onClick={() => history.push(`/task/${row.id}`)}>Edit Task</DropdownItem>
              )}
              {activeUser.hasPrivilege(Privileges.TASK.DELETE) && (
                <DropdownItem
                  onClick={() => {
                    setDeleteConfirmState({ open: true, id: row.id });
                  }}
                >
                  Delete Task
                </DropdownItem>
              )}
            </DropdownMenu>
          </ActionMenu>
        );
      },
    },
    {
      text: "Task Number",
      dataField: "no",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {activeUser.hasPrivilege(Privileges.TASK.DETAIL) && (
              <Link to={`/task/${row.id}/detail`} title="View task detail">
                {cell}
              </Link>
            )}
            {!activeUser.hasPrivilege(Privileges.TASK.DETAIL) && cell}
          </>
        );
      },
    },
    {
      dataField: "taskStatus",
      text: "Task Status",
      sort: true,
      formatter: (cell) => getEnumLabel(enums.taskStatus, cell),
    },
    {
      dataField: "createUser",
      text: "Created by",
      sort: false,
    },
    {
      dataField: "assignee",
      text: "Assignee",
      sort: false,
      formatter: (cell) => cell?.fullName,
    },
    {
      dataField: "createdDate",
      text: "Created at",
      sort: true,
      formatter: (cell) => <DateTimeOutput date={cell} />,
    },
    {
      dataField: "summary",
      text: "Summary",
      sort: false,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tasks</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Row>
              <Col className="col-12">
                <Accordion open={showFilter ? "1" : undefined} toggle={handleToggleFilter}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <i className={hasFilter ? "bx bxs-filter-alt" : "bx bx-filter-alt"}></i>
                      <div className="px-2">Filter Form</div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <TaskFilterForm onSubmit={handleSubmitFilterForm} initialValue={appliedFilter} />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                      <h4>Tasks</h4>
                      {activeUser.hasPrivilege(Privileges.TASK.CREATE) && (
                        <div style={{ float: "right" }}>
                          <Button color="primary" size="sm" onClick={() => history.push(`/create-task`)}>
                            <i className="fa fa-plus"></i> New Task
                          </Button>
                        </div>
                      )}
                    </div>
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <BootstrapTable
                        hover
                        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
                        loading={loading}
                        defaultSorted={[{ dataField: appliedSort.sortField, order: appliedSort.sortOrder }]}
                        keyField="id"
                        responsive
                        bordered={true}
                        striped={false}
                        remote
                        pagination={paginationFactory(pageOptions)}
                        columns={columns}
                        data={taskies}
                        onTableChange={handlePageChange}
                        wrapperClasses="table-responsive"
                        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
                        headerWrapperClasses={"thead-light"}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {deleteConfirmState.open && (
          <SweetAlert
            title="Are you sure you want to delete the task"
            warning
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleDelete(deleteConfirmState.id)}
            onCancel={() => {
              setDeleteConfirmState({ open: false });
            }}
          ></SweetAlert>
        )}
      </div>
    </React.Fragment>
  );
};

Tasks.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Tasks);
