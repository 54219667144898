import React, { useCallback, useState } from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import NumberFormat from "react-number-format";
import CreatableSelectBoxWithFilterService from "components/Selectbox/CreatableSelectBoxWithFilterService";
import { searchCustomersByFilter } from "store/customer/services";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import {
  getManufacturers,
  getOrderedProductStatus,
  getOrderStatus,
  getOrderTypes,
  getShipmentStatus,
  getUserListContent,
  getWarehouse,
} from "store/definitions/services";
import ReactDatePicker from "react-datepicker";
import { getStores } from "store/store/services";
import Select from "react-select";

const ProductReportFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Order Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.orderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="orderId"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("orderId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="productionOrderId" className="col-sm-3 col-form-label">
                Production Order Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.productionOrderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="productionOrderId"
                  onValueChange={({ value }) => {
                    handleSetFilterValue("productionOrderId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="productionNumber" className="col-sm-3 col-form-label">
                Production Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.productionNumber || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="productionNumber"
                  onValueChange={({ value }) => {
                    handleSetFilterValue("productionNumber", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="sku" className="col-sm-3 col-form-label">
                SKU
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.sku || ""}
                  name="sku"
                  placeholder="sku.."
                  className="form-control"
                  onChange={handleChange}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="customerId" className="col-sm-3 col-form-label">
                Customer
              </Label>
              <Col sm={9}>
                {!filter.customer && (
                  <CreatableSelectBoxWithFilterService
                    loadService={searchCustomersByFilter}
                    name="customerId"
                    value={filter.customerId}
                    handleChange={(name, value, val) => {
                      handleSetFilterValue(name, value);
                      handleSetFilterValue("customer", val);
                    }}
                    createTable={false}
                  />
                )}
                {filter.customer && (
                  <Select
                    onChange={(val) => {
                      if (!val) {
                        handleSetFilterValue("customerId", null);
                        handleSetFilterValue("customer", null);
                      }
                    }}
                    isClearable
                    isSearchable={false}
                    value={customerOpt[0]}
                    options={customerOpt}
                  />
                )}
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="warehouseId" className="col-sm-3 col-form-label">
                Warehouse
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getWarehouse}
                  name="warehouseId"
                  value={filter.warehouseId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="storeId" className="col-sm-3 col-form-label">
                Store
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="storeId"
                  value={filter.storeId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="manufacturerId" className="col-sm-3 col-form-label">
                Manufacturer
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getManufacturers}
                  name="manufacturerId"
                  value={filter.manufacturerId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderStatus" className="col-sm-3 col-form-label">
                Order Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getOrderStatus}
                  name="orderStatus"
                  value={filter.orderStatus}
                  isMulti={false}
                  id="orderStatus"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="productionStatus" className="col-sm-3 col-form-label">
                Production Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getOrderStatus}
                  name="productionStatus"
                  value={filter.productionStatus}
                  isMulti={false}
                  id="productionStatus"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderType" className="col-sm-3 col-form-label">
                Order Type
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getOrderTypes}
                  name="orderType"
                  value={filter.orderType}
                  isMulti={false}
                  id="orderType"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="shipmentStatus" className="col-sm-3 col-form-label">
                Shipment Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getShipmentStatus}
                  name="shipmentStatus"
                  value={filter.shipmentStatus}
                  isMulti={false}
                  id="shipmentStatus"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderedProductStatus" className="col-sm-3 col-form-label">
                Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getOrderedProductStatus}
                  name="orderedProductStatus"
                  value={filter.orderedProductStatus}
                  isMulti={false}
                  id="orderedProductStatus"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="createDateStart" className="col-sm-3 col-form-label">
                Create Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.createDateStart}
                      placeholderText="Create Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("createDateStart", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.createDateEnd}
                      placeholderText="Create End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("createDateEnd", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default ProductReportFilterForm;
