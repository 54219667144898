import { StatusCheckBox } from "components/Tables/DefinitionTable";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import MultiselectWithService from "./MultiselectWithService";
import AddressSelectInput from "components/Common/AddressSelectInput";

const CreatableSelectBoxWithService = ({
  service,
  isMulti,
  name,
  handleChange,
  newItemDetails = {
    formValues: {
      values: [{ name: "", label: "", type: "", size: "" }],
      validationSchema: {},
      itemName: "",
    },
    createapi: () => Promise(),
  },
  placeholder,
  value,
  optionValueField = "id",
  optionLabelField = "name",
  ownerId,
  reloadOptionsKey,
  addressCallback,
}) => {
  const [options, setOptions] = useState([]);
  const [canvasDetails, setCanvasDetails] = useState({ open: false });

  const toggleCanvas = () => {
    setCanvasDetails((prev) => ({ ...prev, open: !prev.open }));
  };
  const handleSelect = (newValue, actionMeta) => {
    // new
    if (newValue && newValue.__isNew__) {
      toggleCanvas();
    } else {
      handleChange(name, newValue?.value, newValue);
    }
  };
  const getOptions = () => {
    service({ page: 0 }).then((res) => {
      setOptions(res.map((i) => ({ value: i[optionValueField], label: i[optionLabelField] })));
    });
  };
  const handleNewSuccess = (obj) => {
    getOptions();
    handleChange(name, obj[optionValueField], obj, true);
    toggleCanvas();
  };
  useEffect(() => {
    getOptions();
  }, [ownerId, reloadOptionsKey]);
  return (
    <Fragment>
      <CreatableSelect
        isMulti={isMulti}
        isClearable
        onChange={handleSelect}
        options={options}
        value={value ? { value: value, label: options.find((i) => i.value == value)?.label } : null}
        placeholder={placeholder}
      />
      {newItemDetails !== false && (
        <Offcanvas fade isOpen={canvasDetails.open} direction="end" toggle={toggleCanvas}>
          <OffcanvasHeader toggle={toggleCanvas}>Create New {newItemDetails.formValues.itemName}</OffcanvasHeader>
          <OffcanvasBody>
            <CreateForm
              formValues={newItemDetails.formValues}
              createapi={newItemDetails.createapi}
              callback={handleNewSuccess}
              addressCallback={addressCallback}
            />
          </OffcanvasBody>
        </Offcanvas>
      )}
    </Fragment>
  );
};

const CreateForm = ({
  callback,
  createapi,
  formValues = {
    values: [{ name: "", label: "", type: "", size: "" }],
    validationSchema: {},
    itemName: "",
  },
  addressCallback,
}) => {
  const [formData, setFormData] = useState({ loading: false, success: null, error: null });
  const initialValues = formValues.values.reduce((acc, curr) => {
    const defaultVal = curr.isMulti ? curr.initialValue || [] : curr.initialValue || "";
    if (curr.name === "status") {
      acc[curr.name] = "y";
    } else {
      if (curr.getValue == null) {
        acc[curr.name] = defaultVal;
      } else {
        acc[curr.name] = defaultVal;
      }
    }
    return acc;
  }, {});
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object(formValues.validationSchema),
    onSubmit: (values) => {
      setFormData({ loading: true, success: null, error: null });
      createapi(values) // service
        .then((res) => {
          setFormData({ loading: false, success: true, error: null });
          validation.resetForm();
          callback && callback(res);
        })
        .catch((err) => {
          setFormData({ loading: false, success: false, error: err.response.data.message });
        });
    },
  });

  const handleSubmit = () => {
    validation.handleSubmit();
  };
  return (
    <Fragment>
      <div className="p-2">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          {formData.error ? <Alert color="danger">{formData.error}</Alert> : null}
          <Row>
            {addressCallback && (
              <Col sm={12}>
                <div className="mb-3">
                  <Label className="form-label">Search Address</Label>
                  <AddressSelectInput
                    onChange={(address) => {
                      addressCallback({ setFieldValue: validation.setFieldValue, address });
                    }}
                  ></AddressSelectInput>
                </div>
              </Col>
            )}
            {formValues.values.map((i) => {
              return i.name === "status" ? (
                <Col sm={12} key={i.name}>
                  <StatusCheckBox validation={validation} />
                </Col>
              ) : (
                <Col sm={i.size} key={i.name}>
                  <div className="mb-3">
                    <Label className="form-label">{i.label}</Label>
                    {i.type == "select" && (
                      <>
                        <MultiselectWithService
                          service={i.options ? () => Promise.resolve(i.options) : i.getService}
                          name={i.name}
                          value={validation.values[i.name]}
                          isMulti={i.isMulti}
                          setValue={validation.setFieldValue}
                          invalid={validation.touched[i.name] && validation.errors[i.name] ? true : false}
                        />
                        {validation.touched[i.name] && validation.errors[i.name] && (
                          <div className="invalid-feedback" style={{ display: "block" }}>
                            {validation.errors[i.name]}
                          </div>
                        )}
                      </>
                    )}
                    {i.type != "select" && (
                      <>
                        <Input
                          name={i.name}
                          className="form-control"
                          placeholder={`Enter ${i.label}`}
                          type={i.type}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values[i.name] || ""}
                          invalid={validation.touched[i.name] && validation.errors[i.name] ? true : false}
                        />
                        {validation.touched[i.name] && validation.errors[i.name] ? (
                          <FormFeedback type="invalid">{validation.errors[i.name]}</FormFeedback>
                        ) : null}
                      </>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
          <Button onClick={handleSubmit} color="success" disabled={formData.loading}>
            {formData.loading ? <Spinner color="white" size="sm" /> : "Save"}
          </Button>
        </Form>
      </div>
    </Fragment>
  );
};

export default CreatableSelectBoxWithService;
