import CreatableSelectBoxWithFilterService from "components/Selectbox/CreatableSelectBoxWithFilterService";
import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { Button, Col, Label, Row } from "reactstrap";
import { searchCustomersByFilter } from "store/customer/services";
import {
  getAssProblemSubjects,
  getAssProblemTypes,
  getDeliveryStatus,
  getTicketStatus,
} from "store/definitions/services";
import { getStores } from "store/store/services";

const TicketFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col xl="12" xxl="4">
            <div className="row mb-4">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Order Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.orderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="orderId"
                  onValueChange={({ value }) => {
                    handleSetFilterValue("orderId", value);
                  }}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="salesPerson" className="col-sm-3 col-form-label">
                Customer
              </Label>
              <Col sm={9}>
                {!filter.customer && (
                  <CreatableSelectBoxWithFilterService
                    loadService={searchCustomersByFilter}
                    name="customerId"
                    value={filter.customerId}
                    handleChange={(name, value, val) => {
                      handleSetFilterValue(name, value);
                      handleSetFilterValue("customer", val);
                    }}
                    createTable={false}
                  />
                )}
                {filter.customer && (
                  <Select
                    onChange={(val) => {
                      if (!val) {
                        handleSetFilterValue("customerId", null);
                        handleSetFilterValue("customer", null);
                      }
                    }}
                    isClearable
                    isSearchable={false}
                    value={customerOpt[0]}
                    options={customerOpt}
                  />
                )}
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="sshStatus" className="col-sm-3 col-form-label">
                Ticket Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getTicketStatus}
                  name="sshStatus"
                  value={filter.sshStatus}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col xl="12" xxl="4">
            <div className="row mb-4">
              <Label htmlFor="storeId" className="col-sm-3 col-form-label">
                Branch
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getStores}
                  name="storeId"
                  value={filter.storeId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="subjectId" className="col-sm-3 col-form-label">
                Subject
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getAssProblemSubjects}
                  name="subjectId"
                  value={filter.subjectId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="problemId" className="col-sm-3 col-form-label">
                Problem Type
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getAssProblemTypes}
                  name="problemId"
                  value={filter.problemId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col xl="12" xxl="4">
            <div className="row mb-4">
              <Label htmlFor="serviceDateStart" className="col-sm-3 col-form-label">
                Service Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.serviceDateStart}
                      placeholderText="Service Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("serviceDateStart", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.serviceDateEnd}
                      placeholderText="Service End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("serviceDateEnd", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
            <div className="row mb-4">
              <Label htmlFor="reminderDateStart" className="col-sm-3 col-form-label">
                Reminder Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.reminderDateStart}
                      placeholderText="Service Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("reminderDateStart", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.reminderDateEnd}
                      placeholderText="Service End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("reminderDateEnd", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp; &nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </form>
    </React.Fragment>
  );
};

export default TicketFilterForm;
