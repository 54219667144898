import { atom } from "recoil";
import salesTargetUtils from "./salesTarget_utils";

export const salesTargetsAppliedFilterState = atom({
  key: "SalesTargets.appliedFilter",
  default: salesTargetUtils.initialFilterValues(),
});
export const salesTargetsAppliedSortState = atom({
  key: "SalesTargets.appliedSort",
  default: { sortField: "startDate", sortOrder: "desc" },
});
