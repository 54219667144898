import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import { getShipmentStatus, getVehicleTypes } from "store/definitions/services";
import { getBranches } from "store/store/services";

const ShipmentFilterForm = ({ onSubmit, initialValue }) => {
  const [filter, setFilter] = useState({ ...initialValue });

  const handleSetFilterValue = useCallback((name, value) => {
    setFilter((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }, []);
  const handleSubmitFilterForm = (e) => {
    e.preventDefault();
    onSubmit({ ...filter });
  };

  const handleClear = (e) => {
    const empty = {};
    setFilter(empty);
    onSubmit(empty);
  };

  useEffect(() => {
    setFilter(initialValue);
  }, [initialValue]);

  let customerOpt = null;
  if (filter.customer != null) {
    customerOpt = [
      {
        value: filter.customer.label,
        label: filter.customer.label,
      },
    ];
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmitFilterForm}>
        <Row>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="shipmentNo" className="col-sm-3 col-form-label">
                Shipment Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.shipmentNo || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="shipmentNo"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("shipmentNo", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="orderId" className="col-sm-3 col-form-label">
                Order Number
              </Label>
              <Col sm={9}>
                <NumberFormat
                  value={filter.orderId || ""}
                  className="form-control"
                  placeholder="only numbers.."
                  allowNegative={false}
                  name="orderId"
                  autoFocus
                  onValueChange={({ value }) => {
                    handleSetFilterValue("orderId", value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="containerNo" className="col-sm-3 col-form-label">
                Container No
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.containerNo || ""}
                  name="containerNo"
                  placeholder="container no.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("containerNo", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-4">
              <Label htmlFor="plateNo" className="col-sm-3 col-form-label">
                Plate No
              </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  value={filter.plateNo || ""}
                  name="plateNo"
                  placeholder="plate no.."
                  className="form-control"
                  onChange={(e) => {
                    handleSetFilterValue("plateNo", e.target.value);
                  }}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="shipmentStatus" className="col-sm-3 col-form-label">
                Shipment Status
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getShipmentStatus}
                  name="shipmentStatus"
                  value={filter.shipmentStatus}
                  isMulti={false}
                  id="shipmentStatus"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="vehicleType" className="col-sm-3 col-form-label">
                Vehicle Type
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getVehicleTypes}
                  name="vehicleType"
                  value={filter.vehicleType}
                  isMulti={false}
                  id="vehicleType"
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="branchId" className="col-sm-3 col-form-label">
                Branch
              </Label>
              <Col sm={9}>
                <MultiselectWithService
                  isClearable
                  service={getBranches}
                  name="branchId"
                  value={filter.branchId}
                  isMulti={false}
                  setValue={handleSetFilterValue}
                />
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="exitDateStart" className="col-sm-3 col-form-label">
                Exit Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.exitDateStart}
                      placeholderText="Exit Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("exitDateStart", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.exitDateEnd}
                      placeholderText="Exit End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("exitDateEnd", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
          <Col sm={4}>
            <div className="row mb-2">
              <Label htmlFor="arrivalDateStart" className="col-sm-3 col-form-label">
                Arrival Date
              </Label>
              <Col sm={9}>
                <div className="nv-grid">
                  <div className="nv-row nv-row-p5">
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.arrivalDateStart}
                      placeholderText="Arrival Start Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("arrivalDateStart", date);
                      }}
                    />
                    <ReactDatePicker
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="yyyy-MM-dd"
                      isClearable
                      selected={filter.arrivalDateEnd}
                      placeholderText="Arrival End Date.."
                      className="form-control"
                      onChange={(date) => {
                        handleSetFilterValue("arrivalDateEnd", date);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          <i className="fa fa-search"></i> Search
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button type="button" color="secondary" onClick={handleClear}>
          <i className="fa fa-eraser"></i> Clear
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default ShipmentFilterForm;
