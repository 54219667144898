import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col } from "reactstrap";
import ImagesModal from "components/Common/ImagesModal";
import { getVariantOrProductImages } from "store/product/services";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import CustomSpinner from "components/Common/CustomSpinner";
import { getTicket, getTicketProducts } from "store/ass/services";

const TicketProductsView = ({ ticketId }) => {
  const [products, setProducts] = useState([]);
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const [loading, setLoading] = useState(false);
  const loadProducts = async () => {
    setLoading(true);
    try {
      const { products } = await getTicket(ticketId);
      setProducts(products);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadProducts();
  }, [ticketId]);

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        headerAlign: "center",
        align: "center",
        formatter: (productVariant) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: productVariant.productId,
                variantId: productVariant.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "Product",
        dataField: "productVariant.productName",
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
      },
      {
        text: "SKU",
        dataField: "sku",
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
      },
      {
        text: "Quantity",
        dataField: "quantity",
        headerAlign: "right",
        align: "right",
      },
    ],
    []
  );
  const expandRow = useMemo(
    () => ({
      showExpandColumn: false,
      expandByColumnOnly: true,
      expanded: products.filter((i) => i.ticketNotes || i.description).map((item, i) => item.id),
      renderer: (row) => (
        <>
          <div className="w-100 row">
            <div className="col-2 col-xs-1 d-flex justify-content-end">
              <b>Note:</b>
            </div>
            <span className="col-10 col-xs-11 d-flex justify-content-start text-nowrap">
              {row.ticketNotes || row.description}
            </span>
          </div>
        </>
      ),
    }),
    [products]
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
      </h4>
      <Row className="pt-2">
        <Col xl="12">
          {loading && <CustomSpinner />}
          {!loading && products.length > 0 && (
            <BootstrapTable
              keyField="id"
              data={products}
              columns={productColumns}
              wrapperClasses="table-responsive"
              classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
              headerWrapperClasses={"thead-light"}
              expandRow={expandRow}
            />
          )}
        </Col>
      </Row>
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

export default TicketProductsView;
