import PropTypes from "prop-types";
import React from "react";
import MetaTags from "react-meta-tags";
import "./Login.css";

import { Alert, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, withRouter } from "react-router-dom";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

// actions
import { loginUser } from "../../store/actions";

// import images
import bg from "assets/images/login/bg.jpg";
import loginbg from "assets/images/login/loginbg.png";

//Import config

const LoginNew = (props) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
      // login({ username: values.email, password: values.password }).then(res => {
      //   console.log(res, "res")
      // })
    },
  });

  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history));
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Novia Furniture CRM</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div
        className="account-pages my-5 pt-sm-5"
        style={{
          backgroundImage: "url(" + bg + ")",
          height: "100vh",
          position: "absolute",
          top: -50,
          left: 0,
          width: "100%",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <div
              style={{
                width: "450px",
                height: "650px",
                paddingTop: "100px",
                // backgroundSize: "cover",
                // color: "white",
                // border: "1px solid white",
              }}
            >
              <div
                style={{
                  backgroundImage: "url(" + loginbg + ")",
                  color: "white",
                  // border: "1px solid white",
                  width: "100%",
                  height: "100%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="p-2">
                  <Form
                    className="form-horizontal login-form"
                    style={{ margin: "45% 50px 50px 50px" }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {error ? <Alert color="danger">{error?.message}</Alert> : null}

                    <div className="mb-3">
                      <Label className="form-label">Username</Label>
                      <Input
                        name="email"
                        className="form-control emailInput"
                        placeholder="Enter email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={validation.touched.email && validation.errors.email ? true : false}
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        value={validation.values.password || ""}
                        type="password"
                        placeholder="Enter Password"
                        className="form-control passwordInput"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={validation.touched.password && validation.errors.password ? true : false}
                      />
                      {validation.touched.password && validation.errors.password ? (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-check">
                      <input type="checkbox" className="form-check-input rememberMeCheck" id="customControlInline" />
                      <label className="form-check-label" htmlFor="customControlInline">
                        Remember me
                      </label>
                    </div>

                    <div className="mt-3 d-grid">
                      <button className="btn btn-primary btn-block" type="submit">
                        Log In
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1" />
                        Forgot your password?
                      </Link>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(LoginNew);

LoginNew.propTypes = {
  history: PropTypes.object,
};
