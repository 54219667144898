import ConfirmButton from "components/Common/ConfirmButton";
import ImagesModal from "components/Common/ImagesModal";
import VariantDefaultImage from "pages/Product/VariantDefaultImage";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getVariantOrProductImages } from "store/product/services";
import {
  addProductToTransfer,
  deleteTransferProduct,
  getAvailableProducts,
  getTransfer,
  getTransferProducts,
} from "../../store/transfer/services";
import TransferOrderedProductForm from "./TransferOrderedProductForm";
import OrderedProductModal from "pages/Order/OrderedProductModal";
import { EnumValues } from "common/enums";

const initialOrderedProductState = { open: false, error: null, loading: false, product: {} };
const TransferProducts = ({ transferId, reloadKey }) => {
  const [orderedProductState, setOrderedProductState] = useState(initialOrderedProductState);
  const [products, setProducts] = useState([]);
  const [transfer, setTransfer] = useState({
    senderLocation: {
      id: null,
    },
  });
  const [imageState, setImageState] = useState({
    productId: null,
    variantId: null,
  });
  const loadTransfer = async (transferId) => {
    const transfer = await getTransfer(transferId);
    setTransfer(transfer);
  };
  const openOrderedProductAddPopup = () => {
    setOrderedProductState((prev) => ({ ...prev, product: {}, open: true }));
  };

  const handleOrderedAddProduct = async (products) => {
    return addProductToTransfer(
      transferId,
      products.map((p) => ({ orderedProductId: p.id, amount: p.userEntryQuantity }))
    ).then(() => {
      loadProducts(transferId);
      setOrderedProductState((prev) => ({ ...prev, product: {}, open: false }));
    });
  };
  const handleCloseOrderedProduct = () => {
    setOrderedProductState((prev) => ({ ...prev, open: false }));
  };
  const loadProducts = async (transferId) => {
    setProducts([]);
    const transferProducts = await getTransferProducts(transferId);
    setProducts(transferProducts);
  };
  const handleDeleteProduct = async ({ id }) => {
    await deleteTransferProduct(transferId, { ids: [id] });
    loadProducts(transferId);
  };
  useEffect(() => {
    loadTransfer(transferId);
    loadProducts(transferId);
  }, [transferId, reloadKey]);

  const productColumns = useMemo(
    () => [
      {
        text: "Images",
        dataField: "productVariant",
        align: "center",
        formatter: (productVariant, row) => (
          <VariantDefaultImage
            onClick={() =>
              setImageState({
                productId: row.productVariant?.productId,
                variantId: row.productVariant?.id,
              })
            }
            variant={productVariant}
          ></VariantDefaultImage>
        ),
      },
      {
        text: "Order Number",
        dataField: "order.orderId",
      },
      {
        text: "Product",
        dataField: "productName",
        footer: "",
      },
      {
        text: "Variant",
        dataField: "productVariant.name",
        footer: "",
      },
      {
        text: "SKU",
        dataField: "sku",
      },
      {
        text: "Quantity",
        dataField: "quantity",
      },
      {
        text: "Delete",
        dataField: "delete",
        align: "center",
        hidden: transfer?.transferStatus != EnumValues.TRANSFER_STATUS.ACTIVE,
        formatter: (cell, row) => {
          return (
            <ConfirmButton
              color="danger"
              size="sm"
              onConfirm={() => handleDeleteProduct(row)}
              title="Are you sure you want to delete the product?"
            >
              <i className="fas fa-trash"></i>
            </ConfirmButton>
          );
        },
      },
    ],
    [transfer]
  );
  return (
    <>
      <h4 className="text-secondary">
        <b>Products</b>
        {transfer.transferStatus == EnumValues.TRANSFER_STATUS.ACTIVE && (
          <span style={{ float: "right" }}>
            <Button color="primary" size="sm" outline onClick={openOrderedProductAddPopup}>
              <i className="fa fa-plus"></i>Add Product
            </Button>
          </span>
        )}
      </h4>
      <br />
      {products.length > 0 && (
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <BootstrapTable keyField="id" data={products} columns={productColumns} />
            </div>
          </Col>
        </Row>
      )}
      {orderedProductState.open && (
        <TransferOrderedProductModal
          onClose={handleCloseOrderedProduct}
          onSubmit={handleOrderedAddProduct}
          branchId={transfer.senderLocation.id}
        />
      )}
      {imageState.variantId && (
        <ImagesModal
          editable={false}
          onList={() => getVariantOrProductImages(imageState.productId, imageState.variantId)}
          onClose={() => setImageState({})}
          title="Product Images"
        />
      )}
    </>
  );
};

const TransferOrderedProductModal = ({ branchId, product, onClose, onSubmit }) => {
  return (
    <>
      {product?.id && (
        <Modal isOpen centered={true} size="lg">
          <ModalHeader toggle={onClose}>{!product?.id ? "Add new product" : "Edit product"}</ModalHeader>
          <ModalBody>
            <TransferOrderedProductForm initialProduct={product} onSubmit={onSubmit} branchId={branchId} />
          </ModalBody>
        </Modal>
      )}
      {!product?.id && (
        <OrderedProductModal
          onClose={onClose}
          onSelect={onSubmit}
          service={() => getAvailableProducts(branchId)}
          showSku
          showProductName
          showVariantName
          showProductManufacturer
          showQuantitySelection
          defaultUserEntryQuantity="q"
          multiple
          selectButtonLabel="Add products to transef"
        />
      )}
    </>
  );
};

export default TransferProducts;
