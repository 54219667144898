import { useState } from "react";
import Select from "react-select";
import { addressService } from "services/AddressService";

function AddressSelectInput({ onSelect, onChange }) {
  const [allOptions, setAllOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [lastSearchedInputValue, setLastSearchedInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLoadOptions = (inputValue) => {
    setLoading(true);
    addressService
      .addressSuggestion(inputValue)
      .then((addressList) =>
        addressList.map((address) => ({
          ...address,
          label: address.summaryline,
          value: address.summaryline,
        }))
      )
      .then((options) => {
        setAllOptions(options);
        setOptions(options);
        setLastSearchedInputValue(inputValue);
      })
      .finally(() => setLoading(false));
  };
  const handleInputChange = (txt) => {
    setInputValue(txt);
    setOptions(allOptions.filter((o) => o.summaryline.toLowerCase().includes(txt.toLowerCase())));
  };
  return (
    <Select
      cacheOptions
      isLoading={loading}
      options={options}
      onSelect={onSelect}
      onChange={onChange}
      inputValue={inputValue}
      blurInputOnSelect
      onInputChange={setInputValue}
      openMenuOnClick={options.length > 0}
      openMenuOnFocus={options.length > 0}
      placeholder="Type at least 3 characters and press enter to search.."
      onKeyDown={(e) => {
        if (e.key == "Enter" && inputValue.length >= 3 && inputValue != lastSearchedInputValue) {
          handleLoadOptions(inputValue);
        }
      }}
    />
  );
}

export default AddressSelectInput;
