import { Card, CardBody, Col, Row } from "reactstrap";
import ProductionCard from "./ProductionCard";
import ProductionDocuments from "./ProductionDocuments";
import ProductionNotes from "./ProductionNotes";
import ProductionProducts from "./ProductionProducts";
import { EnumValues } from "common/enums";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";

const ProductionDetailCard = ({ id }) => {
  return (
    <Row>
      <Col>
        <ProductionCard id={id} />
        <Card className="shadow-lg">
          <CardBody className="">
            <ProductionProducts productionId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <ProductionNotes productionId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <ProductionDocuments productionId={id} type="view" />
          </CardBody>
        </Card>
        <ActionLogsCard
          title="Production History"
          recordId={id}
          actionModels={[EnumValues.ACTION_MODEL.PRODUCTION]}
          parentActions={[EnumValues.PARENT_ACTION_TYPE.PRODUCTION]}
        />
      </Col>
    </Row>
  );
};

export default ProductionDetailCard;
