import React, { useEffect, useState } from "react";

import MultiselectWithService from "components/Selectbox/MultiselectWithService";
import ValidationForm from "components/ValidationForm";
import { showInfo } from "helpers/utils";
import { useActiveUser } from "hooks";
import Privileges from "models/Privileges";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { getActiveProductDeleteTypes } from "store/definitions/services";
import { deleteProductFromStock, getProductsInStockForUpdate } from "store/order/ordered-product-services";
import { getBranches } from "store/store/services";
import { createTransferAsCompleted } from "store/transfer/services";

const ProductsInStock = ({ sku, afterStockUpdate }) => {
  const activeUser = useActiveUser();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [deleteProductPopupState, setDeleteProductPopupState] = useState({
    product: null,
    open: false,
  });
  const [transferProductPopupState, setTransferProductPopupState] = useState({
    product: null,
    open: false,
  });

  const loadProducts = async () => {
    setLoading(true);
    try {
      const response = await getProductsInStockForUpdate(sku);
      setProducts(response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const handleDeleteProduct = async (data) => {
    setLoading(true);
    try {
      await deleteProductFromStock(data);
      setDeleteProductPopupState({
        product: null,
        open: false,
      });
      setLoading(false);
      await loadProducts();
      afterStockUpdate && afterStockUpdate(data);
      showInfo("Stock updated successfully");
    } finally {
      setLoading(false);
    }
  };
  const handleTransferProduct = async (data) => {
    setLoading(true);
    try {
      const transfer = await createTransferAsCompleted(data);
      setTransferProductPopupState({
        product: null,
        open: false,
      });
      setLoading(false);
      await loadProducts();
      afterStockUpdate && afterStockUpdate(data);
      showInfo(`Products transferred successfully with transfer number ${transfer.transferNumber}`);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      text: "Branch",
      dataField: "branch.name",
      sort: true,
      editable: false,
    },
    {
      text: "Quantity",
      dataField: "quantity",
      sort: true,
      align: "right",
      headerAlign: "right",
    },
    {
      text: "Transfer",
      dataField: "transfer",
      align: "center",
      headerAlign: "center",
      hidden: !activeUser.hasPrivilege(Privileges.INVENTORY.TRANSFER_PRODUCT),
      formatter: (cell, row) => (
        <Button
          color="none"
          title="Transfer Product"
          onClick={() => {
            setTransferProductPopupState({
              product: row,
              open: true,
            });
          }}
        >
          <i className="fa fa-truck"></i>
        </Button>
      ),
    },
    {
      text: "Delete",
      dataField: "delete",
      align: "center",
      headerAlign: "center",
      hidden: !activeUser.hasPrivilege(Privileges.INVENTORY.DELETE_PRODUCT),
      formatter: (cell, row) => (
        <Button
          color="danger"
          title="Delete Product"
          onClick={() => {
            setDeleteProductPopupState({
              product: row,
              open: true,
            });
          }}
        >
          <i className="fa fa-trash"></i>
        </Button>
      ),
    },
  ];
  return (
    <React.Fragment>
      <BootstrapTable
        hover
        noDataIndication={() => (loading ? <div>&nbsp;</div> : "No data found")}
        loading={loading}
        keyField="id"
        responsive
        bordered={true}
        striped={false}
        remote={false}
        columns={columns}
        data={products}
        wrapperClasses="table-responsive"
        classes={`table align-middle table-nowrap${loading ? " loading" : ""}`}
        headerWrapperClasses={"thead-light"}
      />
      {activeUser.hasPrivilege(Privileges.INVENTORY.DELETE_PRODUCT) && deleteProductPopupState.open && (
        <DeleteProductModal
          product={deleteProductPopupState.product}
          onClose={() => {
            setDeleteProductPopupState({ product: null, open: false });
          }}
          onSubmit={handleDeleteProduct}
        />
      )}
      {activeUser.hasPrivilege(Privileges.INVENTORY.TRANSFER_PRODUCT) && transferProductPopupState.open && (
        <TransferProductModal
          product={transferProductPopupState.product}
          onClose={() => {
            setTransferProductPopupState({ product: null, open: false });
          }}
          onSubmit={handleTransferProduct}
        />
      )}
    </React.Fragment>
  );
};

export default ProductsInStock;

const TransferProductModal = ({ onClose, product, onSubmit }) => {
  const [quantity, setQuantity] = useState("");
  const [arrivalLocationId, setArrivalLocationId] = useState("");
  const [saving, setSaving] = useState(false);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit({
        quantity,
        arrivalLocationId,
        senderLocationId: product.branch.id,
        amount: quantity,
        orderedProductId: product.id,
      });
    } finally {
      setSaving(false);
    }
  };
  return (
    <ValidationForm>
      <Modal isOpen centered={true} size="lg">
        <ModalHeader toggle={onClose}>Transfer product</ModalHeader>
        <ModalBody>
          <div className="row mb-2">
            <Label htmlFor="senderLocation" className="col-sm-3 col-form-label">
              Product
            </Label>
            <Col sm={9} className="align-self-center">
              {product.productVariant.productName}
              {product.productVariant.name && <> / {product.productVariant.name}</>}
            </Col>
          </div>
          <div className="row mb-2">
            <Label htmlFor="senderLocation" className="col-sm-3 col-form-label">
              Sender Location
            </Label>
            <Col sm={9} className="align-self-center">
              {product.branch.name}
            </Col>
          </div>
          <div className="row mb-4">
            <Label htmlFor="arrivalLocation" className="col-sm-3 col-form-label">
              Arrival Location
            </Label>
            <Col sm={9}>
              <MultiselectWithService
                isClearable
                service={getBranches}
                name="arrivalLocation"
                value={arrivalLocationId}
                isMulti={false}
                setValue={(name, value) => {
                  setArrivalLocationId(value);
                }}
                validations={["required"]}
              />
            </Col>
          </div>
          <div className="row mb-2">
            <Label htmlFor="quantity" className="col-sm-3 col-form-label">
              Quantity to be transferred
            </Label>
            <Col sm={9}>
              <NumberFormat
                value={quantity || ""}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                decimalScale={0}
                validationeventname="onValueChange"
                name="quantity"
                validations={["required", "min|1", `max|${product.quantity}`]}
                onValueChange={({ value }) => {
                  setQuantity(value);
                }}
              />
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleSubmit} disabled={saving} validate>
            Transfer
            {saving && <Spinner size="sm" />}
          </Button>
        </ModalFooter>
      </Modal>
    </ValidationForm>
  );
};

function DeleteProductModal({ product, onSubmit, onClose }) {
  const [quantity, setQuantity] = useState(1);
  const [productDeleteTypeId, setProductDeleteTypeId] = useState("");
  const [saving, setSaving] = useState(false);
  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit({
        quantity,
        productDeleteTypeId,
        branchId: product.branch.id,
        sku: product.sku,
      });
    } finally {
      setSaving(false);
    }
  };
  return (
    <ValidationForm>
      <Modal isOpen centered={true}>
        <ModalHeader toggle={onClose}>Delete Product from Stock</ModalHeader>
        <ModalBody>
          <div className="row mb-4">
            <Label htmlFor="quantity" className="col-sm-3 col-form-label">
              Quantity to be deleted
            </Label>
            <Col sm={9}>
              <NumberFormat
                value={quantity || ""}
                className="form-control"
                placeholder="only numbers.."
                allowNegative={false}
                decimalScale={0}
                validationeventname="onValueChange"
                name="quantity"
                validations={["required", "min|1", `max|${product.quantity}`]}
                onValueChange={({ value }) => {
                  setQuantity(value);
                }}
              />
            </Col>
          </div>
          <div className="row mb-2">
            <Label htmlFor="productDeleteTypeId" className="col-sm-3 col-form-label">
              Delete Reason
            </Label>
            <Col sm={9}>
              <MultiselectWithService
                isClearable
                service={getActiveProductDeleteTypes}
                name="productDeleteTypeId"
                value={productDeleteTypeId}
                isMulti={false}
                setValue={(name, value) => {
                  setProductDeleteTypeId(value);
                }}
                validations={["required"]}
              />
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleSubmit} disabled={saving} validate>
            Delete
            {saving && <Spinner size="sm" />}
          </Button>
        </ModalFooter>
      </Modal>
    </ValidationForm>
  );
}
