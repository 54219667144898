import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import TaskDetailCard from "./TaskDetailCard";

const TaskDetail = () => {
  const { id } = useParams();

  return (
    <>
      <MetaTags>
        <title>Novia - Task Detail</title>
      </MetaTags>
      <div className="my-5 px-4 pt-sm-5">
        <h4 className="text-primary">
          <Breadcrumb listClassName="p-0">
            <BreadcrumbItem>
              <Link to="/tasks">Tasks</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              <b>Task Detail</b>
            </BreadcrumbItem>
          </Breadcrumb>
        </h4>
        <TaskDetailCard id={id} />
      </div>
    </>
  );
};

TaskDetail.propTypes = {
  history: PropTypes.object,
};

export default withRouter(TaskDetail);
