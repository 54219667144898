import { Card, CardBody, Col, Row } from "reactstrap";
import ShipmentCard from "./ShipmentCard";
import ShipmentDocuments from "./ShipmentDocuments";
import ShipmentNotes from "./ShipmentNotes";
import ShipmentProducts from "./ShipmentProducts";
import ActionLogsCard from "pages/ActionLog/ActionLogsCard";
import { EnumValues } from "common/enums";

const ShipmentDetailCard = ({ id }) => {
  return (
    <Row>
      <Col>
        <ShipmentCard id={id} />
        <Card className="shadow-lg">
          <CardBody className="">
            <ShipmentProducts shipmentId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <ShipmentNotes shipmentId={id} type="view" />
          </CardBody>
        </Card>
        <Card className="shadow-lg">
          <CardBody className="">
            <ShipmentDocuments shipmentId={id} type="view" />
          </CardBody>
        </Card>
        <ActionLogsCard
          title="Shipment History"
          recordId={id}
          actionModels={[EnumValues.ACTION_MODEL.SHIPMENT]}
          parentActions={[EnumValues.PARENT_ACTION_TYPE.SHIPMENT]}
        />
      </Col>
    </Row>
  );
};

export default ShipmentDetailCard;
