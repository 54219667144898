import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { createRole, deleteRole, editRole, getPrivileges, getRoles } from "store/definitions/services";
import DefinitionTable from "components/Tables/DefinitionTable";
import { UncontrolledTooltip } from "reactstrap";
import Privileges from "models/Privileges";

const Roles = () => {
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "privileges",
      text: "Privileges",
      sort: true,
      formatter: (cellContent, row, index) => {
        if (row.privileges.length == 0) {
          return null;
        }
        if (row.privileges.length == 1) {
          return row.privileges[0].name;
        }
        const targetId = `targetProduct${index}`;
        return (
          <div>
            <span
              href="#"
              id={targetId}
              style={{
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {row.privileges[0].name} ...
            </span>
            <UncontrolledTooltip placement="top" target={targetId}>
              {row.privileges.map((item) => item.name).join(", ")}
            </UncontrolledTooltip>
          </div>
        );
      },
    },
    {
      dataField: "createdDate",
      text: "Creation Date",
      sort: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Roles</title>
        </MetaTags>
        <DefinitionTable
          services={{
            getapi: getRoles,
            deleteapi: deleteRole,
            createapi: createRole,
            updateapi: editRole,
          }}
          tableColumns={columns}
          formValues={{
            values: [
              { name: "name", label: "Name", size: 12, type: "text" },
              { name: "description", label: "Description", size: 12, type: "textarea" },
              { name: "status", label: "status", size: 12, type: "checkbox" },
              {
                name: "privilegeIds",
                label: "Privileges",
                size: 12,
                type: "select",
                isMulti: true,
                getService: getPrivileges,
                getValue: (value) => {
                  return value.privileges.map((item) => item.id);
                },
              },
            ],
            validationSchema: {
              name: Yup.string().required("Name is required"),
              description: Yup.string().nullable(),
              status: Yup.string(),
              privilegeIds: Yup.array().default([]),
            },
          }}
          pageDetails={{
            definitionName: "Role",
            title: "Roles",
          }}
          privilege={{
            create: Privileges.ROLE.CREATE,
            update: Privileges.ROLE.UPDATE,
            delete: Privileges.ROLE.DELETE,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Roles);
